import 'firebase/firestore';
import firebase from "../firebase_config";
import { config } from "../firebase_config";
import { getMyPackagesData, getRecentPackages } from './explore';
import { getMyPickupsData, getRecentPickups } from './pickup';
import { getUserPickups, getUsersData, searchUsers } from "./user";

export const USER_LOGIN = "USER_LOGIN";
export const CHANGE_TAB = "CHANGE_TAB";
export const CHANGE_ROW_PER_PAGE = "CHANGE_ROW_PER_PAGE";
export const GET_SETTING = "GET_SETTING";
export const GET_USER = "GET_USER";
export const SET_IS_UPDATING = "SET_IS_UPDATING";
export const SET_SHOW_ALERT = "SET_SHOW_ALERT";
export const SET_PRIVILEGES = "SET_PRIVILEGES";
export const GET_PICKUP_DATES = "GET_PICKUP_DATES";
export const GET_ZONES = "GET_ZONES";
export const GET_EMAILS = "GET_EMAILS";
export const USER_STATUS = "USER_STATUS";
export const GET_DELIVERY_ADDRESSES = "GET_DELIVERY_ADDRESSES";

var _message = '';
var _color = '';

export const userLogin = (user) => (dispatch, getState) => {
    var isLogin = false;
    if (user && !user.isAnonymous) {
        isLogin = true;

        firebase.auth().currentUser.getIdTokenResult(true)
            .then((idTokenResult) => {
                console.log("Claims>>>>>", idTokenResult.claims);
                if (!!idTokenResult.claims.cid) {
                    getUser(dispatch, idTokenResult.claims.cid);
                    getRecentPackages(dispatch, idTokenResult.claims.cid);
                    getRecentPickups(dispatch, idTokenResult.claims.cid);
                    getPickupDates();
                    getPickupZones();
                    getEmails();
                    var privileges = idTokenResult.claims.pr.split(':');
                    dispatch({
                        type: SET_PRIVILEGES,
                        privileges
                    });
                    dispatch({
                        type: USER_STATUS,
                        status: idTokenResult.claims.st
                    })

                    var term = getState().user.term;
                    if (term && term !== "") {
                        dispatch(searchUsers(term));
                    } else {
                        dispatch(getUsersData());
                    }
                } else {
                    hasInvite(dispatch);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    dispatch({
        type: USER_LOGIN,
        payload: {
            isLogin
        }
    });
}

export const changeTab = (currentPageId) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_TAB,
        payload: {
            currentPageId
        }
    });
}

export const changeRowPerPage = (rowsPerPage) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_ROW_PER_PAGE,
        rowsPerPage
    });
}


export const setIsUpdating = (isUpdating) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating
    });
}

export const setShowAlert = (showAlert) => (dispatch, getState) => {
    dispatch({
        type: SET_SHOW_ALERT,
        showAlert
    });
}

export const showErr = (msg) => (dispatch, getState) => {
    dispatch({
        type: SET_SHOW_ALERT,
        showAlert: { show: true, msg: msg, color: "red" }
    });
}


export const getSetting = (dispatch, user) => {
    firebase.firestore().collection('configs').doc('setting').get().then(function (doc) {
        var setting = doc.data();
        dispatch({
            type: GET_SETTING,
            payload: {
                setting
            }
        });
    });
}


export const getUser = (dispatch, cid) => {
    firebase.firestore().collection('users').doc(cid).get().then(function (doc) {
        var data = doc.data();
        var user = {
            "fcs_id": data['fcs_id'],
            "user_name": data['user_name'],
            "id": cid,
            "phone_number": data['phone_number'],
            "status": data['status'],
            "email": data['email']
        };
        dispatch(getLoginUserAddresses(cid));
        dispatch({
            type: GET_USER,
            payload: {
                user
            }
        });
        dispatch(getMyPackagesData());
        dispatch(getUserPickups());
    });
}

export const getLoginUserAddresses = (userId) => (dispatch, getState) => {
    dispatch({
        type: GET_DELIVERY_ADDRESSES,
        payload: []
    });

    firebase.firestore().collection("users").doc(userId).collection('delivery_addresses').orderBy('update_time', 'desc')
        .get()
        .then(function (querySnapshot) {
            var _addresses = [];
            querySnapshot.forEach(function (doc) {
                var _address = doc.data();
                _address['id'] = doc.id;
                _addresses.push(_address);
            });

            dispatch({
                type: GET_DELIVERY_ADDRESSES,
                payload: _addresses
            });
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });

}

export const hasInvite = (dispatch) => {
    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/check_invitation", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {

                if (result['status'] === 'Ok') {
                    var invited = result['data']['invited'];
                    console.log("Result:", invited);
                    if (invited) {
                        dispatch({
                            type: USER_STATUS,
                            status: "invited"
                        })
                    } else {
                        dispatch({
                            type: USER_STATUS,
                            status: "signup"
                        })
                    }

                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {

            });
    });
}

export const getPickupDates = () => (dispatch, getState) => {
    dispatch({
        type: GET_PICKUP_DATES,
        dates: []
    });

    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.firestore().collection("pickup_dates").where("delete_time", "==", 0)
                .get()
                .then(function (querySnapshot) {
                    var dates = [];
                    querySnapshot.forEach(function (doc) {
                        var _date = doc.data();
                        _date['id'] = doc.id;
                        dates.push(_date);
                    });
                    dispatch({
                        type: GET_PICKUP_DATES,
                        dates
                    });
                }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });
        }
    });
}

export const getPickupZones = () => (dispatch, getState) => {
    dispatch({
        type: GET_ZONES,
        zones: []
    });

    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.firestore().collection("zones").where("delete_time", "==", 0)
                .get()
                .then(function (querySnapshot) {
                    var zones = [];
                    querySnapshot.forEach(function (doc) {
                        var _zone = doc.data();
                        _zone['id'] = doc.id;
                        zones.push(_zone);
                    });
                    dispatch({
                        type: GET_ZONES,
                        zones
                    });
                }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });
        }
    });
}

export const addPickupZone = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/zones", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Make pickup zone successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch(getPickupZones());
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const updatePickupZone = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/zones", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update pickup zone successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch(getPickupZones());
                dispatch(getPickupDates());
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const deletePickupZone = (data) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/zones", {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify({ "id": data['id'] })
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Make pickup zone successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch(getPickupZones());
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}


export const addPickupDate = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/pickup_dates", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Make pickup date successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch(getPickupDates());
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const updatePickupDate = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/pickup_dates", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update pickup date successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch(getPickupDates());
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const deletePickupDate = (data) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/pickup_dates", {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify({ "id": data['id'] })
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Delete pickup date successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch(getPickupDates());
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}

export const getEmails = () => (dispatch, getState) => {
    dispatch({
        type: GET_EMAILS,
        emails: []
    });

    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.firestore().collection("configs").doc("setting").collection("emails")
                .get()
                .then(function (querySnapshot) {
                    var emails = [];
                    querySnapshot.forEach(function (doc) {
                        var _email = doc.data();
                        _email['id'] = doc.id;
                        emails.push(_email);
                    });
                    dispatch({
                        type: GET_EMAILS,
                        emails
                    });
                }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });
        }
    });
}

export const updateEmailFormats = (data) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/email_formats", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update email formats successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getEmails());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}