import { Button, Grid, Paper,  Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getAddresses, joinInvite } from '../../actions/user';
// import ConfirmDialog from '../confirm_dialog';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '30px'
  },
  avatar: {
    width: 60,
    height: 60,
    textAlign: 'left'
  },
  radio: {
    '&$checked': {
      color: '#0f47a1'
    }
  },
  checked: {},
  row: {
    borderBottom: '1px solid grey'
  },
  headerText: {
    fontSize: 24,
    margin: 0,
  },
}));

const JoinPage = props => {
  const { dispatch, className } = props;
  const user = useSelector(state => state.app.user);
  const classes = useStyles();
  // const [data, setName] = React.useState({});
  // const [isEmpty, setIsEmpty] = React.useState(false);

  useEffect(() => {
    if (user.id != null) {
      dispatch(getAddresses(user['id']));
    }

  }, [user, dispatch]);

  // const handleUserName = (e) => {
  //   setName({ ...data, 'user_name': e.target.value });
  // }

  const handleJoinInvited = (v) => {
    // var d = data.user_name; console.log("result:", hasWhiteSpace(d));
    // if (hasWhiteSpace(d)) {
    //   setIsEmpty(true);
    // } else if (d === undefined) {
    //   setIsEmpty(true);
    // } else {
      // setIsEmpty(false);
      dispatch(joinInvite(() => {
        window.location.href = '/';
      }));
    // }
  }

  // const hasWhiteSpace = (s) => {
  //   return /\s/g.test(s);
  // }

  // const handleCancel = () => {
  //   setIsEmpty(false);
  // }

  // const handelContinue = () => {
  //   setIsEmpty(false);
  // }

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Paper sx={{ p: 2, margin: 'auto', maxWidth: 500, flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>

                <Typography color="primary" variant="h1" className={classes.headerText}>
                  Welcome to FCS
                </Typography>

              </Grid>
              {/* <Grid item xs>
                <div style={{ display: 'inline-flex' }}>
                  <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Please enter your name'}</Typography>
                </div>
              </Grid>
              <Grid item xs>
                <div style={{ display: 'inline-flex' }}>
                  <TextField
                    autoFocus
                    error={data.user === " " ? true : false}
                    variant="outlined"
                    autoComplete="off"
                    size={"small"}
                    // helperText="Please enter at least one character"
                    value={data.user_name !== undefined ? data.user_name : "" || ''}
                    style={{ width: '250px' }}
                    onChange={handleUserName}
                  />
                </div>
              </Grid>
              */}
              {/* <Grid item xs>
                <Button variant="contained" color="primary" style={{ textTransform: "none" }} onClick={handleJoinInvited}>Join</Button>
              </Grid> */}
              <Grid item xs>
                <Button variant="contained" color="primary" style={{ textTransform: "none" }} onClick={handleJoinInvited}>Join</Button>
              </Grid>
            </Grid>

          </Grid>

        </Grid>
        {/* {isEmpty ? <ConfirmDialog
          msg={'Please enter your name at least one character?'}
          openDialog={isEmpty}
          confirmBtnLabel={"OK"}
          onCancel={(v) => handleCancel(v)}
          onContinue={(v) => handelContinue()}></ConfirmDialog> : <div />} */}
      </Paper>
    </div>
  );
};

JoinPage.propTypes = {
  className: PropTypes.string
};

export default connect()(JoinPage);
