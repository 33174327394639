import {
    GET_PICKUP_DETAIL,
    GET_PICKUPS,
    GET_PICKUP_ROW_COUNT,
    CHANGE_PICKUP_ORDER,
    CHANGE_PICKUP_PAGE,
    CHANGE_PICKUP_ADDRESS,
    SET_PICKUP,
    SET_PICKUP_SEARCH_TERM,
    SET_PICKUP_STATUS_FILTER,
    SET_ASSIGNED_PICKUP_STATUS_FILTER,
    GET_ASSIGN_PICKUPS,
    GET_ASSIGN_PICKUP_ROW_COUNT,
    GET_MAP_PICKUPS,
    CHANGE_ASSIGN_PICKUP_ORDER,
    CHANGE_ASSIGN_PICKUP_PAGE,
    GET_MYPICKUPS,
    GET_MYPICKUP_ROW_COUNT,
    CHANGE_MYPICKUP_ORDER,
    CHANGE_MYPICKUP_PAGE,
    GET_RECENT_PICKUPS,
    SET_MYPICKUP_SEARCH_TERM,
    CHANGE_CUSTOEMR_PICKUP_ADDRESS,
} from "../actions/pickup";

const statusListNoCancel = [
    'ACTIVES',
    'requested',
    'confirmed',
    'rescheduled',
];
const statusListActive = [
    'ACTIVES',
    'confirmed',
    'rescheduled',
];
const initialState = {
    pickups: [],
    pickupDetail: {},
    count: 0,
    page: 0,
    order: { "field_name": "updated_date", "direction": "desc" },
    pickupStatusFilter: statusListNoCancel,

    assignCount: 0,
    assignPage: 0,
    assignOrder: { "field_name": "updated_date", "direction": "desc" },
    assignPickups: [],
    assignPickupStatusFilter: statusListActive,

    myPickupCount: 0,
    myPickupPage: 0,
    myPickupOrder: { "field_name": "updated_date", "direction": "desc" },
    myPickups: [],

    pickupAddress: {},
    customerPickupAddress: {},


    mapPickups: [],
    recentPickups: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PICKUPS: {
            return {
                ...state,
                pickups: action.pickups,
            };
        }
        case GET_PICKUP_ROW_COUNT: {
            return {
                ...state,
                count: action.count,
            };
        }
        case GET_PICKUP_DETAIL: {
            return {
                ...state,
                pickupDetail: action.pickup,
            };
        }
        case CHANGE_PICKUP_ORDER: {
            return {
                ...state,
                order: action.order,
            };
        }
        case CHANGE_PICKUP_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        case CHANGE_PICKUP_ADDRESS: {
            return {
                ...state,
                pickupAddress: action.address
            }
        }
        case SET_PICKUP: {
            return {
                ...state,
                pickupDetail: action.pickup,
            };
        }
        case SET_PICKUP_SEARCH_TERM: {
            return {
                ...state,
                term: action.term,
            };
        }
        case GET_ASSIGN_PICKUPS: {
            return {
                ...state,
                assignPickups: action.pickups,
            };
        }
        case CHANGE_ASSIGN_PICKUP_ORDER: {
            return {
                ...state,
                assignOrder: action.order,
            };
        }
        case CHANGE_ASSIGN_PICKUP_PAGE: {
            return {
                ...state,
                assignPage: action.page
            }
        }
        case GET_ASSIGN_PICKUP_ROW_COUNT: {
            return {
                ...state,
                assignCount: action.count,
            };
        }

        case GET_MYPICKUPS: {
            return {
                ...state,
                myPickups: action.pickups,
            };
        }
        case CHANGE_MYPICKUP_ORDER: {
            return {
                ...state,
                myPickupOrder: action.order,
            };
        }
        case CHANGE_MYPICKUP_PAGE: {
            return {
                ...state,
                myPickupPage: action.page
            }
        }
        case GET_MYPICKUP_ROW_COUNT: {
            return {
                ...state,
                myPickupCount: action.count,
            };
        }
        case GET_MAP_PICKUPS: {
            return {
                ...state,
                mapPickups: action.mapPickups,
            };
        }
        case GET_RECENT_PICKUPS: {
            return {
                ...state,
                recentPickups: action.payload,
            };
        }
        case SET_MYPICKUP_SEARCH_TERM: {
            return {
                ...state,
                term: action.term,
            };
        }
        case CHANGE_CUSTOEMR_PICKUP_ADDRESS: {
            return {
                ...state,
                customerPickupAddress: action.address,
            };
        }
        case SET_PICKUP_STATUS_FILTER: {
            return {
                ...state,
                pickupStatusFilter: action.filter,
            };
        }
        case SET_ASSIGNED_PICKUP_STATUS_FILTER: {
            return {
                ...state,
                assignPickupStatusFilter: action.filter,
            };
        }
        default:
            return state;
    }
}
