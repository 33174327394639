import { Box, IconButton, Button, Typography, Grid, Paper } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getPickupDetail } from '../../actions/pickup';
import theme from '../../theme';
import { convertISOToDate } from '../../util';
import AddressCard from '../address_card';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));

const MyPickupDetail = (props) => {
    const { title, history, dispatch, smallScreen } = props;
    const classes = useStyles();
    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const selector = useSelector(state => state.pickup);
    const [pickupDetail, setPickupDetail] = React.useState(selector.pickupDetail);

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var pickupId = pathArr[2];

    useEffect(() => {
        dispatch(getPickupDetail(pickupId));
    }, [dispatch, pickupId]);

    useEffect(() => {
        setPickupDetail(selector.pickupDetail);
    }, [selector]);

    const handleBack = () => {
        history.goBack();
    }

    const handleCancel = () => {
        history.push("/pickup-cancel/" + pickupId);
    }

    const handleEdit = () => {
        history.push('/my-pickup-edit/' + pickupId);
    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? "5px" : '30px' }}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{pickupDetail.pickup_number !== undefined ? pickupDetail.pickup_number : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{pickupDetail.status !== undefined ? pickupDetail.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Zone'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.zone_name !== undefined ? pickupDetail.zone_name : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Date'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_date !== undefined ? convertISOToDate(pickupDetail.pickup_date) : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'My Remark'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ minWidth: '150px', padding: '10px', textAlign: 'left', whiteSpace: 'pre-line', border: '1px solid grey', borderRadius: '5px' }}>{pickupDetail.customer_remark ? pickupDetail.customer_remark : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Address'}</Typography>
                                {pickupDetail.pickup_address !== undefined ? <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                    <Paper
                                        style={{ marginRight: '5px', marginBottom: '5px', width: '280px', height: '165px', border: '1px solid lightgray' }}
                                    >
                                        <AddressCard address={pickupDetail.pickup_address}
                                            editIcon={false}
                                            deleteIcon={false}
                                            makeDefaultIcon={false}
                                        />
                                    </Paper>
                                </div> : <div />}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                {pickupDetail.status === "requested" ? <Box>
                                    <Button disabled={stateIsUpdating} variant="contained" color="primary" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        onClick={handleEdit}
                                        startIcon={<EditIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Edit Pickup"} </Button>
                                </Box> : <Box />}
                                {pickupDetail.status === "requested" ? <Box>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: '#d83d0f', color: 'white' }}
                                        onClick={handleCancel}
                                        startIcon={<CancelIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Cancel Pickup"} </Button>
                                </Box> : <Box />}
                            </Grid>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div >

    );
};

MyPickupDetail.propTypes = {
    pDetail: PropTypes.object,
    title: PropTypes.string
};

export default withRouter(connect()(MyPickupDetail));
