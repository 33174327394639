import firebase from "../firebase_config";
import { config } from "../firebase_config";

export const GET_REPORTS = "GET_REPORTS";
export const CLEAR_REPORTS = "CLEAR_REPORTS";
export const GET_FILTER_VALUES = "GET_FILTER_VALUES";
export const CLEAR_FILTER_VALUES = "CLEAR_FILTER_VALUES";

export const getFilterValues = (data, selectedField) => (dispatch, getState) => {

    var _filterValues = [];
    // if (filters.length > 0) {
    //     _filterValues = getState().report.filterValues;
    // }
    var f = getState().report.filterValues;
    var filterValue = null;
    f.forEach((e) => {
        if (e.field_name === selectedField) {
            filterValue = e;
        }
    });

    dispatch({
        type: GET_FILTER_VALUES,
        filterValues: []
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;

        fetch(config.fcsReportUrl + "/api/filter_values", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                if (result && result.data) {
                    result.data.fields.forEach(function (p, index) {
                        if (filterValue && p.field_name === selectedField) {
                            _filterValues.push(filterValue);
                        } else {
                            _filterValues.push(p);
                        }

                        // if (_filterValues.length > 0) {
                        //     // _filterValues.forEach((_f, i) => {
                        //     //     filters.forEach((filter, _i) => {
                        //     //         if(filter.field===_filterValues[i].field_name){

                        //     //         }

                        //     //     });
                        //     // });
                        //     if (selectedField) {
                        //         if (p.field_name !== selectedField) {
                        //             _filterValues[index] = p;
                        //         }
                        //     } else {
                        //         _filterValues.push(p);
                        //         console.log("_filterValues debug:", _filterValues)
                        //     }
                        // } else {
                        //     _filterValues.push(p);
                        // }

                    });
                }
                dispatch({
                    type: GET_FILTER_VALUES,
                    filterValues: _filterValues

                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const generateReports = (data, order, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: GET_REPORTS,
        reports: []
    });

    var object = data.object;
    var filters = data.filters;
    var _filters = [];
    var _sorts = [];

    filters.forEach((f) => {
        if (f.allow_filter && f.selected_value) {
            var value = '';
            var field = '';
            if (f.field_type === 'time') {
                field = "time(" + f.field_name + ")";
                var d = new Date(f.selected_value);
                value = ("0" + (d.getUTCHours())).slice(-2)
                    + ":" + ("0" + (d.getUTCMinutes())).slice(-2) + ":" + ("0" + (d.getUTCSeconds())).slice(-2);
            } else {
                field = f.field_name;
                value = f.selected_value;
            }
            _filters.push({ 'field': field, 'compare': f.filter_compare, 'value': value });
        }
    });

    if (order) {
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            _sorts = [order['field_name'] + " " + order['direction']];
        }
    }

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;

        var payload = { sorts: _sorts, filters: _filters };

        fetch(config.fcsReportUrl + "/api/data/" + object, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(payload)
        }).then(response => response.json())
            .then((result) => {
                var reports = [];
                var index = 0;
                if (result && result.data) {
                    result.data.forEach(function (u) {
                        u["sn"] = ++index;
                        reports.push(u);
                    });
                }
                dispatch({
                    type: GET_REPORTS,
                    reports
                });
                return onSuccess();
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const clearReports = () => (dispatch, getState) => {
    dispatch({
        type: CLEAR_REPORTS,
        reports: []
    });
}

export const clearFilterValues = () => (dispatch, getState) => {
    dispatch({
        type: CLEAR_FILTER_VALUES,
        filterValues: []
    });
}

export const downloadReport = (fields, filters, obj, order) => (dispatch, getState) => {
    var _fields = [];
    var _headers = [];
    var _sorts = [];
    var _filters = [];

    fields.forEach((f) => {
        if (f.show) {
            _fields.push(f.field_name);
            _headers.push(f.display_name);
        }
    });

    filters.forEach((f) => {
        if (f.allow_filter && f.selected_value) {
            var value = '';
            var field = '';
            if (f.field_type === 'time') {
                field = "time(" + f.field_name + ")";
                var d = new Date(f.selected_value);
                value = ("0" + (d.getUTCHours())).slice(-2)
                    + ":" + ("0" + (d.getUTCMinutes())).slice(-2) + ":" + ("0" + (d.getUTCSeconds())).slice(-2);
            } else {
                field = f.field_name;
                value = f.selected_value;
            }
            _filters.push({ 'field': field, 'compare': f.filter_compare, 'value': value });
        }
    });

    if (order) {
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            _sorts.push(order['field_name'] + " " + order['direction']);
        }
    }

    var data = {
        "fields": _fields,
        "headers": _headers,
        "sorts": _sorts,
        "filters": _filters,
        "download_file_type": "CSV"
    }

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/download/" + obj, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/csv',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.blob())
            .then((blob) => {

                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                var fileName = obj + '_report.csv';
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).catch((e) => {
                console.log("error:", e);
            });

    });
}