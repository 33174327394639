import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';
import ClearIcon from '@material-ui/icons/Clear';

const MyInvoiceToolbar = (props) => {

    return (
        <ThemeProvider theme={theme}>
            <Toolbar>

                <div style={{ height: '40px', alignItems: 'left' }}>
                    <OutlinedInput style={{ height: '40px', marginBottom: '10px', }}
                        id="standard-adornment-password"
                        variant="outlined"
                        placeholder={props.placeHolder}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility"
                                // onClick={handleSearch}
                                >
                                    <SearchIcon />
                                </IconButton>
                                <IconButton aria-label="toggle password visibility"
                                // onClick={handleClear}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    ></OutlinedInput>
                </div>
            </Toolbar>
        </ThemeProvider>
    );
};

MyInvoiceToolbar.propTypes = {
    dispatch: PropTypes.func.isRequired
};


export default MyInvoiceToolbar;
