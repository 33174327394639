import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import theme from '../../theme';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import PickupSearchBar from './pickup_search_bar';
import EnhancedTableHeader from '../enhanced_table_header';
import { changeRowPerPage, changeTab } from '../../actions/app';
import { changePickupPage, getPickupDetail, getPickupsData, setPickupData, setPickupStatusFilter } from '../../actions/pickup';
import { formatDateToLocal, removeFCSString } from '../../util';
import { Typography, MenuItem, FormControl, ListItemText, Select, Checkbox, Input, InputLabel, Box } from '@material-ui/core';
import PrintPickupQrcode from './print_pickup_qrcode';
import { QrcodeIcon } from '../../icons';
import PickupStatusFilter from './pickup_status_filter';

const useStyles = makeStyles({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px'
    },
    table: {
        minWidth: 650,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    },
    paper: {
        display: "inline-flex",
        width: "100%",
        flexWrap: "wrap"
    },
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const headCells = [
    { id: 'sn', numeric: true, label: 'No.', width: 30 },
    { id: 'pickup_number', numeric: false, label: 'Pickup Number', sortable: true, width: 200 },
    { id: 'pickup_date', numeric: false, label: 'Pickup Date', sortable: true, width: 100 },
    { id: 'zone_name', numeric: false, label: 'Zone', sortable: true, width: 100 },
    { id: 'pickup_staff_name', numeric: false, label: 'Staff Name', sortable: true, width: 200 },
    { id: 'customer_fcs_id', numeric: false, label: 'FCS ID', sortable: true, width: 200 },
    { id: 'customer_name', numeric: false, label: 'Customer Name', sortable: true, width: 200 },
    { id: 'updated_date', numeric: false, label: 'Updated Date', sortable: true, width: 150 },
    { id: 'status', numeric: false, label: 'Status', sortable: true, width: 70 },
];

function PickupList(props) {
    const classes = useStyles();
    const { dispatch, history } = props;
    const count = useSelector(state => state.pickup.count);
    const rowsPerPage = useSelector(state => state.app.rowsPerPage);
    const page = useSelector(state => state.pickup.page);
    const order = useSelector(state => state.pickup.order);
    const pickups = useSelector(state => state.pickup.pickups);
    const isLogin = useSelector(state => state.app.isLogin);
    const pickupStatusFilter = useSelector(state => state.pickup.pickupStatusFilter);

    const [selectedPickup, setSelectedPickup] = useState(null);
    const [showQrcode, setShowQrcode] = useState(false);
    const [status, setStatus] = React.useState(pickupStatusFilter);

    useEffect(() => {
        if (isLogin) {
            dispatch(changeTab("pickups"));
            dispatch(getPickupsData());
        }
    }, [dispatch, isLogin]);

    const handleRequestSort = (event, property) => {
        const isAsc = order['field_name'] === property && order['direction'] === 'asc';
        var _order = { "field_name": property, "direction": isAsc ? 'desc' : 'asc' };
        console.log('pickup order', _order);
        dispatch(changePickupPage(0));
        dispatch(getPickupsData(_order));
    };

    const handleClick = (event, value) => {
        event.preventDefault();
        dispatch(setPickupData({}));
        dispatch(changeTab(''));
        dispatch(getPickupDetail(value.id));
        var pathName = '/pickup-detail/' + value.id;
        history.push(pathName);
    };

    const handleChangePage = (event, newPage) => {
        dispatch(changePickupPage(newPage));
        dispatch(getPickupsData());
    };

    const handleChangeRowsPerPage = (event) => {
        var rowPerPage = parseInt(event.target.value, 10);
        dispatch(changePickupPage(0));
        dispatch(changeRowPerPage(rowPerPage));
        dispatch(getPickupsData());
    };

    const handlePrintQrcode = (e, pickup) => {
        e.stopPropagation();
        setSelectedPickup(pickup);
        console.log("selected pickup:", pickup);
        console.log("selected id:", e.target);
        // e.target.print();
        // PrintPickupQrcode.print();
        setShowQrcode(true);
    }
    const handleOnQrcodeDialogClose = () => {
        setShowQrcode(false);
    };
   
    const handleStatusFilterChange = (filter) => {
        dispatch(changePickupPage(0));
        setStatus(filter);
        dispatch(setPickupStatusFilter(filter));
        dispatch(getPickupsData());
    }

    return (
        <div className={classes.root}>
            <Paper>
                <PickupSearchBar {...props} placeHolder={'Search pickup number'}></PickupSearchBar>
                <Box display="flex" justifyContent="flex-end">
                    <Box>
                        <PickupStatusFilter filter={status} onChange={handleStatusFilterChange}></PickupStatusFilter>
                    </Box>
                </Box>
                <div>
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">

                            <EnhancedTableHeader
                                classes={classes}
                                order={order.direction}
                                orderBy={order.field_name}
                                onRequestSort={handleRequestSort}
                                rowCount={count}
                                headCells={headCells}
                            />
                            <TableBody>
                                {pickups.map((row) => (
                                    <StyledTableRow key={row['sn']} >
                                        {headCells.map((h, i) => {
                                            if (h.id === 'sn') {
                                                return (<TableCell key={h.id} style={{ width: h.width }} align="right">{row[h.id]}</TableCell>);
                                            } if (h.id === 'pickup_number') {
                                                return (<TableCell className={classes.trackingID} style={{ width: h.width }} key={h.id} align={h.numeric ? 'right' : 'left'} >
                                                    <div style={{ display: "flex" }}>
                                                        <Typography style={{ marginRight: 10 }} onClick={(event) => handleClick(event, row)}>{row[h.id]}</Typography>
                                                        <QrcodeIcon onClick={e => handlePrintQrcode(e, row)} style={{ width: "20px", height: "20px" }} />
                                                    </div>
                                                </TableCell>);
                                            } if (h.id === 'customer_fcs_id') {
                                                return (<TableCell style={{ width: h.width }} key={h.id} align={h.numeric ? 'right' : 'left'}>{removeFCSString(row[h.id])}</TableCell>);
                                            }
                                            if (h.id === 'pickup_date') {
                                                return (<TableCell style={{ width: h.width }} key={h.id} align={h.numeric ? 'right' : 'left'}>{formatDateToLocal(row[h.id], false)}</TableCell>);
                                            } else if (h.id === 'updated_date') {
                                                return (<TableCell style={{ width: h.width }} key={h.id} align={'left'} >{formatDateToLocal(row[h.id])}</TableCell>);
                                            } else {
                                                return (<TableCell key={h.id} style={{ width: h.width }} align={h.numeric ? 'right' : 'left'}>{row[h.id]}</TableCell>);
                                            }
                                        })}
                                        {/* <PrintPickupQrcode id={row.id} key={row.id} pickup={row} /> */}
                                    </StyledTableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`}
                    />
                </div>
                {selectedPickup && showQrcode ? <PrintPickupQrcode pickup={selectedPickup} open={showQrcode} onClose={handleOnQrcodeDialogClose} /> : <span />}
            </Paper>
        </div>
    );
}

PickupList.propTypes = {
    history: PropTypes.object,
};


export default connect()(PickupList);