import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Box, IconButton, makeStyles, NativeSelect, Grid, InputBase, withStyles, Paper, TextField, FormControl, FormHelperText } from '@material-ui/core';
import theme from '../../theme';
import { connect, useSelector } from 'react-redux';
import { getPickupDates, getPickupZones } from '../../actions/app';
import AddressCard from '../address_card';
import { addPickupRequest, changePickupAddress, setPickupData } from '../../actions/pickup';
import { convertStringToDate, formatDateToLocal, getDay } from '../../util';
import { getAddresses } from '../../actions/user';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    table: {
        padding: "10px 20px 10px 20px"
    },
}));

function RequestPickupForm(props) {
    const classes = useStyles();
    const { title, history, dispatch } = props;

    const selector = useSelector(state => state.app);
    const pickupSelector = useSelector(state => state.pickup);

    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const addresses = useSelector(state => state.user.addresses);
    const user = useSelector(state => state.app.user);

    const [zones, setZones] = useState(selector.zones);
    const [dates, setDates] = useState(selector.pickupDates);
    const [pickupAddress, setPickupAddress] = useState(pickupSelector.pickupAddress);
    const [zoneDates, setZoneDates] = useState([]);

    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        dispatch(getPickupZones());
        dispatch(getPickupDates());
    }, [dispatch]);

    useEffect(() => {
        if (user.id) {
            dispatch(getAddresses(user.id));
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (pickupAddress.id === undefined) {
            addresses.forEach(a => {
                if (a.is_default === true) {
                    setPickupAddress(a);
                }
            });
        }
    }, [addresses, pickupAddress]);


    useEffect(() => {
        if (data.zone_id !== undefined) {
            var _dates = [];
            dates.forEach(d => {
                for (var i = 0; i < d.zones.length; i++) {
                    if (data.zone_id === d.zones[i].id) {
                        var dbDate= convertStringToDate(d.id);
                        var compareDate = new Date(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate());

                        var today = new Date();
                        today.setHours(0, 0, 0, 0);

                        if (compareDate >= today) {
                            _dates.push(dbDate);
                        }

                    }
                }
            });
            setZoneDates(_dates);
        }

    }, [pickupAddress, addresses, data, dates]);

    useEffect(() => {
        setZones(selector.zones);
        setDates(selector.pickupDates);
        setData(pickupSelector.pickupDetail);
    }, [selector, pickupSelector]);

    const handleChangePickupZone = (e) => {
        var id = e.target.value;
        var _dates = [];
        dates.forEach(d => {
            for (var i = 0; i < d.zones.length; i++) {
                if (id === d.zones[i].id) {
                    _dates.push(d.id);
                }
            }
        });
        setData({ ...data, 'zone_id': id });
        setZoneDates(_dates);
    }

    const handleChangePickupDate = (e) => {
        var date = e.target.value;
        setData({ ...data, 'pickup_date': date });
    }

    const handleRemark = (e) => {
        setData({ ...data, 'customer_remark': e.target.value });
    }

    const handleBack = () => {
        dispatch(setPickupData({}));
        dispatch(changePickupAddress({}));
        history.goBack();
    }

    const handleRequestPickup = () => {
        let isError = false;
        var errorMsg = [...errorMessages];
        if (!data.zone_id) {
            isError = true;
            setError(true);
            errorMsg['zone'] = "Pickup zone is required";
            setErrorMessages(errorMsg);

        }
        if (!data.pickup_date) {
            isError = true;
            setError(true);
            errorMsg['pickupDate'] = "Pickup date is required";
            setErrorMessages(errorMsg);

        }
        if (!pickupAddress.id) {
            isError = true;
            setError(true);
            errorMsg['address'] = "Pickup address is required";
            setErrorMessages(errorMsg);

        }
        if (!isError) {
            setError(false);
            setErrorMessages([]);
            data['address_id'] = pickupAddress.id;
            data['pickup_date'] = new Date(data.pickup_date).toISOString();
            dispatch(addPickupRequest(data, () => {
                history.goBack();
            }));
        }
    }

    const handleChangeAddress = () => {
        dispatch(setPickupData(data));
        history.push("/addresses");
    }

    return (

        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table className={classes.table}>
                <tbody>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Zone'}</Typography>
                                    <NativeSelect
                                        id="pickup-zone-select"
                                        required
                                        value={data !== undefined ? data.zone_id : '' || ''}
                                        onChange={handleChangePickupZone}
                                        style={{ width: '200px', border: error ? !data.zone_id ? "1px solid red" : "1px solid #ced4da" : "1px solid #ced4da", borderRadius: error ? "4px" : "4px" }}
                                        inputProps={{
                                            id: 'name-native-error',
                                        }}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >Select</option>
                                        {zones.map((e) => {
                                            return <option key={e.id} value={e.id}>{e.name}</option>;
                                        })}
                                    </NativeSelect>
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['zone']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Date'}</Typography>
                                    <NativeSelect
                                        id="pickup-date-select"
                                        required
                                        value={data.pickup_date !== undefined ? data.pickup_date : '' || ''}
                                        onChange={handleChangePickupDate}
                                        style={{ width: '200px', border: error ? !data.pickup_date ? "1px solid red" : "1px solid #ced4da" : "1px solid #ced4da", borderRadius: error ? "4px" : "4px" }}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >Select</option>
                                        {zoneDates.map((d) => {
                                            return (<option name={d} value={d} key={d}>{formatDateToLocal(d, false) + '\t' + getDay(d)}</option>);
                                        })}
                                    </NativeSelect>
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['pickupDate']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <div>
                                    <Box style={{ display: 'inline-flex', marginTop: '5px' }}>
                                        <Typography style={{ color: 'grey', paddingRight: '30px' }}>{'Address'}</Typography>
                                        <Button size="small" variant="outlined" color="primary" style={{ float: 'left', textTransform: 'none' }}
                                            onClick={handleChangeAddress}
                                        >Select address</Button>
                                    </Box>
                                </div>
                                <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                    <FormControl error={error}>
                                        {pickupAddress.id ? <Paper key={pickupAddress.id} style={{ marginRight: '5px', marginBottom: '5px', width: '280px', height: '165px', border: pickupAddress.id ? '1px solid lightgray' : '1px solid red' }}>
                                            <AddressCard address={pickupAddress}
                                                editIcon={false}
                                                deleteIcon={false}
                                                makeDefaultIcon={false}
                                            />
                                        </Paper> : <span />}
                                        {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['address']}</FormHelperText> : <span />}
                                    </FormControl>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Remark'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={data.customer_remark !== undefined ? data.customer_remark : '' || ''}
                                    style={{ width: '400px' }}
                                    multiline
                                    rows={3}
                                    onChange={handleRemark}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleRequestPickup}
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Request Pickup"} </Button>
                                </Box>

                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

RequestPickupForm.propTypes = {
    history: PropTypes.object,
    open: PropTypes.bool,
    callback: PropTypes.func
};

export default connect()(RequestPickupForm);
