import { ThemeProvider } from "@material-ui/core";
import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSetting, userLogin } from './actions/app';
import './app.css';
import Drawer from './components/drawer_page';
import firebase from "./firebase_config";
import theme from './theme';
const browserHistory = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smallScreen: false,
      currentPageID: ''
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener("getCurrentPageID", this.getCurrentPageID.bind(this));

    this.resize();
    browserHistory.listen((location) => {
      var paths = location.pathname.split('/');
      this.setState({ currentPageID: paths[1] });
    });
    this.getCurrentPageID();

    let { dispatch } = this.props
    firebase.auth().onAuthStateChanged(function (user) {console.log("onAuthStateChanged");
      getSetting(dispatch, user);
      dispatch(userLogin(user));
      if (!user) {
        firebase.auth().signInAnonymously();
      }
    });
  }

  getCurrentPageID() {
    var paths = browserHistory.location.pathname.split('/');
    this.setState({ currentPageID: paths[1] });
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 1000 });
  }




  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    window.removeEventListener("getCurrentPageID", this.getCurrentPageID.bind(this));
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Drawer history={browserHistory} smallScreen={this.state.smallScreen} currentPageID={this.state.currentPageID} /></div>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const user = state.app.user;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
