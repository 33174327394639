import firebase from "../firebase_config";
import { config } from "../firebase_config";
import 'firebase/firestore';
import { SET_IS_UPDATING, SET_SHOW_ALERT } from "./app";

export const GET_MONTHS = "GET_MONTHS";
export const GET_MESSAGES = "GET_MESSAGES";
export const GET_RATES = "GET_RATES";
export const NO_MORE_DATA = "NO_MORE_DATA";
export const CHANGE_SMS_ROW_PER_PAGE = "CHANGE_SMS_ROW_PER_PAGE";

var _color = "";
var _message = "";

export const getSMSMonths = () => (dispatch, getState) => {
    dispatch({
        type: GET_MONTHS,
        months: []
    });

    firebase.firestore().collection("sms")
        .get()
        .then(function (querySnapshot) {
            var months = [];
            querySnapshot.forEach(function (doc) {
                var _month = doc.data();
                _month['id'] = doc.id;
                months.push(_month);
            });
            dispatch({
                type: GET_MONTHS,
                months
            });
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}

export const getNextPage = (month) => (dispatch, getState) => {
    if (getState().sms.noMoreToLoad) return;

    var lastVisibleDoc = getState().sms.lastVisibleDoc;
    var rowPerPage = getState().sms.rowsPerPage;
    var messages = [];

    var query = firebase.firestore().collection("sms").doc(month).collection("messages").orderBy("date");

    if (lastVisibleDoc) {
        query = query.startAfter(lastVisibleDoc);
        messages = [...getState().sms.messages];
    }
    query = query.limit(rowPerPage);
    var lastVisible = null;

    query.get().then(
        (querySnapshot) => {
            lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            querySnapshot.forEach((doc) => {
                var record = doc.data();
                record['id'] = doc.id;
                messages.push(record);
            });
            console.log("messages length:", messages.length);
            dispatch({
                type: GET_MESSAGES,
                payload: {
                    "messages": messages,
                    "lastVisibleDoc": lastVisible
                }
            });
            if (querySnapshot.docs.length < rowPerPage) {
                dispatch({
                    type: NO_MORE_DATA,
                    payload: true
                });
            }
        }
    );
}

export const getSMSRates = () => (dispatch, getState) => {
    dispatch({
        type: GET_RATES,
        rates: []
    });

    firebase.firestore().collection("configs").doc("setting").collection("sms_rates")
        .where("delete_time", "==", 0).get()
        .then(function (querySnapshot) {
            var rates = [];
            var index = 0;
            querySnapshot.forEach(function (doc) {
                var rate = doc.data();
                rate['id'] = doc.id;
                rate['sn'] = ++index;
                rates.push(rate);
            });
            dispatch({
                type: GET_RATES,
                rates
            });
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}

export const addSMSRate = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/sms_rates", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Create sms rate successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getSMSRates());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const updateSMSRate = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/sms_rates", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update sms rate successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getSMSRates());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const deleteSMSRate = (data) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/sms_rates", {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify({ "id": data['id'] })
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Delete sms rate successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getSMSRates());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}

export const clearMessages = () => (dispatch, getState) => {
    dispatch({
        type: NO_MORE_DATA,
        payload: false
    });

    dispatch({
        type: GET_MESSAGES,
        payload: {
            "messages": [],
            "lastVisibleDoc": null
        }
    });
}

export const changeRowPerPage = (rowsPerPage) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_SMS_ROW_PER_PAGE,
        rowsPerPage
    });
}