import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from 'prop-types';

const LocalOverlay = (props) => {
    const { children, isActive, displayContent } = props;
    return <LoadingOverlay
        active={isActive}
        spinner
        styles={{
            overlay: (base) => ({
                ...base,
                background: 'rgb(140 131 131 / 50%)',
                zIndex: 13000
            })
        }}
        text={displayContent !== undefined ? displayContent : 'Loading ...'}
    >{children}
    </LoadingOverlay>;
}


LocalOverlay.prototype = {
    children: PropTypes.any.isRequired
}

export default LocalOverlay;