
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import theme from '../../theme';
import { Box, Button, } from '@material-ui/core';
import { setTemplateDetail } from '../../actions/template';

const TemplateSearchBar = (props) => {
    const { dispatch, history, smallScreen } = props;

    const handleNewTemplate = (v) => {
        dispatch(setTemplateDetail({}));
        history.push('/new-template');
    }

    return (
        <ThemeProvider theme={theme}>
            <Toolbar>
                <Box>
                    <Button variant="contained" color="primary" style={{
                        textTransform: 'none', color: 'white', float: 'left', marginLeft: '10px', marginBottom: '10px', whiteSpace: "nowrap",
                        marginTop: smallScreen ? "5px" : "0px"
                    }}
                        onClick={handleNewTemplate}
                        disableRipple
                    > New Template</Button>
                </Box>
            </Toolbar>
        </ThemeProvider>
    );
};

TemplateSearchBar.propTypes = {
    numSelected: PropTypes.number,
    dispatch: PropTypes.func.isRequired
};


export default connect()(TemplateSearchBar);
