
import firebase from "../firebase_config";
import { config } from "../firebase_config";
import 'firebase/firestore';
import { SET_IS_UPDATING, SET_SHOW_ALERT } from "./app";
import { v4 as uuidv4 } from 'uuid';

export const GET_PICKUPS = 'GET_PICKUPS';
export const GET_PICKUP_DETAIL = 'GET_PICKUP_DETAIL';
export const GET_PICKUP_ROW_COUNT = "GET_PICKUP_ROW_COUNT";
export const CHANGE_PICKUP_ORDER = "CHANGE_PICKUP_ORDER";
export const CHANGE_PICKUP_PAGE = "CHANGE_PICKUP_PAGE";
export const CHANGE_PICKUP_ADDRESS = 'CHANGE_PICKUP_ADDRESS';
export const CHANGE_CUSTOEMR_PICKUP_ADDRESS = 'CHANGE_CUSTOEMR_PICKUP_ADDRESS';

export const GET_MYPICKUPS = 'GET_MYPICKUPS';
export const GET_MYPICKUP_DETAIL = 'GET_MYPICKUP_DETAIL';
export const GET_MYPICKUP_ROW_COUNT = "GET_MYPICKUP_ROW_COUNT";
export const CHANGE_MYPICKUP_ORDER = "CHANGE_MYPICKUP_ORDER";
export const CHANGE_MYPICKUP_PAGE = "CHANGE_MYPICKUP_PAGE";
export const CHANGE_MYPICKUP_ADDRESS = 'CHANGE_MYPICKUP_ADDRESS';

export const GET_ASSIGN_PICKUPS = 'GET_ASSIGN_PICKUPS';
export const GET_ASSIGN_PICKUP_ROW_COUNT = "GET_ASSIGN_PICKUP_ROW_COUNT";
export const GET_ASSIGN_PICKUP_DETAIL = 'GET_ASSIGN_PICKUP_DETAIL';
export const CHANGE_ASSIGN_PICKUP_ORDER = "CHANGE_ASSIGN_PICKUP_ORDER";
export const CHANGE_ASSIGN_PICKUP_PAGE = "CHANGE_ASSIGN_PICKUP_PAGE";
export const CHANGE_ASSIGN_PICKUP_ADDRESS = 'CHANGE_ASSIGN_PICKUP_ADDRESS';

export const SET_PICKUP = 'SET_PICKUP';
export const SET_PICKUP_SEARCH_TERM = 'SET_PICKUP_SEARCH_TERM';
export const SET_MYPICKUP_SEARCH_TERM = 'SET_MYPICKUP_SEARCH_TERM';

export const GET_MAP_PICKUPS = "GET_MAP_PICKUPS";

export const GET_RECENT_PICKUPS = 'GET_RECENT_PICKUPS';
export const SET_PICKUP_STATUS_FILTER = 'SET_PICKUP_STATUS_FILTER';
export const SET_ASSIGNED_PICKUP_STATUS_FILTER = 'SET_ASSIGNED_PICKUP_STATUS_FILTER';

var _message = '';
var _color = '';

export const clearPickupDetail = () => (dispatch, getState) => {
    dispatch({
        type: GET_PICKUP_DETAIL,
        pickup: {}
    })
}


export const getPickupDetail = (id) => (dispatch, getState) => {
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.firestore().collection("pickups").doc(id)
                .get()
                .then(function (doc) {
                    var pickup = doc.data();
                    pickup['id'] = doc.id;

                    dispatch({
                        type: GET_PICKUP_DETAIL,
                        pickup
                    })
                }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });


        }
    });

}

export const getPickupInfo = (id) => (dispatch, getState) => {

    firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
            console.log("firebase user:", firebase.auth().currentUser);
            var idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
            if (!idTokenResult || !idTokenResult.token) return;
            const token = idTokenResult.token;
            console.log("config.fcsUrl :", config.fcsUrl);
            fetch(config.fcsUrl + "/pickup_info/" + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': token,
                },
            })
                .then(response => response.json())
                .then((result) => {
                    var pickup = {};
                    if (result && result.data) {
                        pickup = result.data;
                        dispatch({
                            type: GET_PICKUP_DETAIL,
                            pickup
                        })
                    }
                });
        }
    })
}

export const getPickupsData = (order) => (dispatch, getState) => {
    if (!order) {
        order = getState().pickup.order;
    }
    dispatch(changePickupsOrder(order));

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        var offset = getState().pickup.page * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }
        var status = getState().pickup.pickupStatusFilter;
        var filter = [];
        if (!status.includes("ALL")) {
            filter.push({ "field": "status", "compare": " in ", "value": status });
        }
        var data = { limit: limit, offset: offset, sorts: sorts, filters: filter };
        fetch(config.fcsReportUrl + "/api/data/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var pickups = [];
                if (result && result.data) {
                    result.data.forEach(function (u) {
                        u["sn"] = ++offset;
                        pickups.push(u);
                    });
                }
                dispatch({
                    type: GET_PICKUPS,
                    pickups
                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_PICKUP_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const getMyPickupsData = (order) => (dispatch, getState) => {
    if (!order) {
        order = getState().pickup.myPickupOrder;
    }
    dispatch(changeMyPickupOrder(order));

    firebase.auth().currentUser.getIdToken().then(token => {
        var _user = getState().app.user;
        if (!_user || !(_user.id)) {
            return;
        }
        if (!token || token === "") return;
        console.log("getMyPickupsData2-->");
        var offset = getState().pickup.myPickupPage * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }

        var _filters = [];
        var _user = getState().app.user;
        _filters.push({ 'field': 'customer_fcs_id', 'compare': "=", 'value': _user.fcs_id });

        var data = { limit: limit, offset: offset, sorts: sorts, filters: _filters };
        fetch(config.fcsReportUrl + "/api/data/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var pickups = [];
                if (result && result.data) {
                    result.data.forEach(function (u) {
                        u["sn"] = ++offset;
                        pickups.push(u);
                    });
                }
                dispatch({
                    type: GET_MYPICKUPS,
                    pickups
                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_MYPICKUP_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

/**
 * change Pickups order
 * @param {*} order 
 * @returns 
 */
export const changePickupsOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_PICKUP_ORDER,
        order
    });
}

/**
 * change Pickups page
 * @param {*} page 
 * @returns 
 */
export const changePickupPage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_PICKUP_PAGE,
        page
    });
}

/**
 * change my Pickup order
 * @param {*} order 
 * @returns 
 */
export const changeMyPickupOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_MYPICKUP_ORDER,
        order
    });
}

/**
 * change my Pickup page
 * @param {*} page 
 * @returns 
 */
export const changeMyPickupPage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_MYPICKUP_PAGE,
        page
    });
}

/**
 * change assign Pickup order
 * @param {*} order 
 * @returns 
 */
export const changeAssignPickupOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_ASSIGN_PICKUP_ORDER,
        order
    });
}

/**
 * change assign Pickup page
 * @param {*} page 
 * @returns 
 */
export const changeAssignPickupPage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_ASSIGN_PICKUP_PAGE,
        page
    });
}

export const changePickupAddress = (address) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_PICKUP_ADDRESS,
        address
    });
}

export const changeCustomerPickupAddress = (address) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_CUSTOEMR_PICKUP_ADDRESS,
        address
    });
}

export const setPickupData = (pickup) => (dispatch, getState) => {
    dispatch({
        type: SET_PICKUP,
        pickup
    });
}

export const setPickupSearchTerm = (term) => (dispatch, getState) => {
    if (firebase.auth().currentUser) {
        dispatch(searchPickups(term));
        return;
    }
    dispatch({
        type: SET_PICKUP_SEARCH_TERM,
        term
    });
}

export const searchPickups = (term) => (dispatch, getState) => {
    if (!!!firebase.auth().currentUser) return;
    if (!term || term === "") {
        dispatch(getPickupsData());
        return;
    }

    let index = 0;
    var b64 = decodeURIComponent(term);
    var _value = atob(b64);
    console.log("searchPickups:", _value);

    var _filters = [];
    _filters.push({ 'field': 'pickup_number', 'compare': "LIKE", 'value': "%" + _value + "%" });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/fts/pickups/" + term + "/10", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                var pickups = [];
                var pickupList = [];
                var _allPickups = [];


                if (result && result.data) {

                    result.data.forEach(function (u) {
                        pickups.push(u);
                    });
                }

                fetch(config.fcsReportUrl + "/api/data/pickups", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': token,
                        'Project-ID': config.fcsReportId,
                    },
                    body: JSON.stringify({ filters: _filters })
                }).then(response => response.json())
                    .then((result) => {
                        if (result && result.data) {
                            result.data.forEach(function (p) {
                                pickupList.push(p);
                            });
                        }

                        var ids = new Set(pickups.map(d => d.id));
                        _allPickups = [...pickups, ...pickupList.filter(d => !ids.has(d.id))];

                        _allPickups.forEach((_p) => {
                            _p["sn"] = ++index;
                        });
                        dispatch({
                            type: GET_PICKUPS,
                            pickups: _allPickups
                        });
                        var count = _allPickups.length;
                        dispatch({
                            type: GET_PICKUP_ROW_COUNT,
                            count
                        });
                    }).catch((e) => {
                        console.log("error:", e);
                    });

            }).catch((e) => {
                console.log("error:", e);
            });

    });
}

export const setMyPickupSearchTerm = (term) => (dispatch, getState) => {
    if (firebase.auth().currentUser) {
        dispatch(searchMyPickups(term));
        return;
    }
    dispatch({
        type: SET_MYPICKUP_SEARCH_TERM,
        term
    });
}

export const searchMyPickups = (term) => (dispatch, getState) => {
    if (!!!firebase.auth().currentUser) return;
    if (!term || term === "") {
        dispatch(getMyPickupsData());
        return;
    }

    var _filters = [];
    var _user = getState().app.user;
    if (!_user || !(_user.fcs_id)) return;
    _filters.push({ 'field': 'customer_fcs_id', 'compare': "=", 'value': _user.fcs_id });
    var b64 = btoa(JSON.stringify(_filters));
    var filtersEsc = encodeURIComponent(b64);

    let index = 0;
    var _b64 = decodeURIComponent(term);
    var _value = atob(_b64);
    console.log("searchPickups:", _value);

    var _wcfilters = [];
    _wcfilters.push({ 'field': 'customer_fcs_id', 'compare': "=", 'value': _user.fcs_id });
    _wcfilters.push({ 'field': 'pickup_number', 'compare': "LIKE", 'value': "%" + _value + "%" });


    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/fts_filter/pickups/" + term + "/10/0/" + filtersEsc, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                var pickups = [];
                var pickupList = [];
                var _allPickups = [];

                if (result && result.data) {
                    let index = 0;
                    result.data.forEach(function (u) {
                        u["sn"] = ++index;
                        pickups.push(u);
                    });
                }

                fetch(config.fcsReportUrl + "/api/data/pickups", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': token,
                        'Project-ID': config.fcsReportId,
                    },
                    body: JSON.stringify({ filters: _wcfilters })
                }).then(response => response.json())
                    .then((result) => {
                        if (result && result.data) {
                            result.data.forEach(function (p) {
                                pickupList.push(p);
                            });
                        }


                        var ids = new Set(pickups.map(d => d.id));
                        _allPickups = [...pickups, ...pickupList.filter(d => !ids.has(d.id))];

                        _allPickups.forEach((_p) => {
                            _p["sn"] = ++index;
                        });

                        dispatch({
                            type: GET_MYPICKUPS,
                            pickups: _allPickups
                        });
                        var count = _allPickups.length;
                        dispatch({
                            type: GET_MYPICKUP_ROW_COUNT,
                            count
                        });
                    }).catch((e) => {
                        console.log("error:", e);
                    });

            }).catch((e) => {
                console.log("error:", e);
            });

    });
}


export const addPickupRequest = (pickup, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(pickup)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Request pickup successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getMyPickupsData());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const updatePickupRequest = (pickup, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/pickups", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(pickup)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update pickup successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getMyPickupsData());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const confirmPickupRequest = (pickup, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/pickups/confirm", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(pickup)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Confirm pickup successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}


export const cancelPickupRequest = (pickup, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/pickups/cancel", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(pickup)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Cancel pickup successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const completePickupRequest = (pickup, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/pickups/complete", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(pickup)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Complete pickup successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const reschedulePickupRequest = (pickup, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/pickups/reschedule", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(pickup)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Reschedule pickup successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}


export const uploadPickupPhotos = (pickupId, photos, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        console.log("token:",);
        if (!token || token === "") return;
        var promoises = [];
        photos.forEach(file => {
            // Create a root reference
            var ps = uploadImageAsPromise(pickupId, file);
            promoises.push(ps);
        });

        Promise.all(promoises).then((values) => {
            return onSuccess(values);
        });

    })
}

function uploadImageAsPromise(pickupId, file) {
    return new Promise(function (resolve, reject) {
        const storage = firebase.storage();
        const metadata = {
            contentType: file.type
        };
        const storageRef = storage.ref();

        var fileName = uuidv4();

        var uploadTask = storageRef.child("pickups/" + pickupId + "/" + fileName).put(file, metadata);
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.log("upload error!", error);
            },
            () => {
                var url = uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    return downloadURL;
                });
                resolve(url);
            }
        );
    });
}


export const getAssignPickups = (order) => (dispatch, getState) => {
    if (!order) {
        order = getState().pickup.assignOrder;
    }
    dispatch(changeAssignPickupOrder(order));
    firebase.auth().currentUser.getIdToken().then(token => {
        var _user = getState().app.user;
        if (!token || token === "" || _user.id === undefined) return;
        var offset = getState().pickup.assignPage * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }

        var _filters = [];
        _filters.push({ 'field': 'pickup_staff_id', 'compare': "==", 'value': _user.id });
        var statusFilter = getState().pickup.assignPickupStatusFilter;
        _filters.push({ 'field': 'status', 'compare': "IN", 'value': statusFilter });

        var data = { limit: limit, offset: offset, sorts: sorts, filters: _filters };
        console.log("payload:", data);
        fetch(config.fcsReportUrl + "/api/data/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var pickups = [];
                if (result && result.data) {
                    result.data.forEach(function (u) {
                        u["sn"] = ++offset;
                        pickups.push(u);
                    });
                }
                dispatch({
                    type: GET_ASSIGN_PICKUPS,
                    pickups
                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_ASSIGN_PICKUP_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const getMapPickups = (userId, selectedDate) => (dispatch, getState) => {
    console.log("getMapPickups=> userId", userId, ", selectedDate:", selectedDate)
    dispatch({
        type: GET_MAP_PICKUPS,
        mapPickups: []
    });

    if (selectedDate === undefined) return;
    var _fromDate = new Date(selectedDate);
    _fromDate.setHours(0, 0, 0, 0);

    var _toDate = new Date(selectedDate);
    _toDate.setDate(_toDate.getDate() + 1);
    _toDate.setHours(0, 0, 0, 0);

    // Date to Timestamp
    const fromTimeStamp = firebase.firestore.Timestamp.fromDate(_fromDate);
    const toTimestamp = firebase.firestore.Timestamp.fromDate(_toDate);

    firebase.firestore().collection("pickups")
        .where("pickup_staff_id", "==", userId)
        .where("status", "in", ["confirmed", "rescheduled"])
        .where("pickup_date", ">=", fromTimeStamp)
        .where("pickup_date", "<", toTimestamp)
        .get()
        .then(function (querySnapshot) {
            var mapPickups = [];
            querySnapshot.forEach(function (doc) {
                var _address = doc.data();
                _address['id'] = doc.id;
                mapPickups.push(_address);
            });

            dispatch({
                type: GET_MAP_PICKUPS,
                mapPickups
            });
            return;
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });

}

export const getRecentPickups = (dispatch, userID) => {
    firebase.firestore().collection("pickups")
        .where("customer_id", "==", userID).orderBy('update_time', 'desc').limit(5)
        .get()
        .then(function (querySnapshot) {
            var pickups = [];
            querySnapshot.forEach(function (doc) {
                var p = doc.data();
                p['id'] = doc.id;
                pickups.push(p);
            });
            dispatch({
                type: GET_RECENT_PICKUPS,
                payload: pickups
            });
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}

export const addAdminPickupRequest = (pickup, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/admin/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(pickup)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Request pickup successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getPickupsData());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const updateAdminPickupRequest = (pickup, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/admin/pickups", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(pickup)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update pickup successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getPickupsData());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const setPickupStatusFilter = (filter) => (dispatch, getState) => {
    dispatch({
        type: SET_PICKUP_STATUS_FILTER,
        filter: filter
    });
}

export const setAssignPickupStatusFilter = (filter) => (dispatch, getState) => {
    dispatch({
        type: SET_ASSIGNED_PICKUP_STATUS_FILTER,
        filter: filter
    });
}