import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { searchUsers, setUserSearchTerm } from '../../actions/user';
import theme from '../../theme';
import ClearIcon from '@material-ui/icons/Clear';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const UserSearchBar = (props) => {
    const { dispatch, history, onInvite, smallScreen } = props;
    const [searchText, setSearchText] = React.useState("");
    const [data, setData] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const location = useLocation()

    useEffect(() => {
        var pathName = location.pathname;
        var pathArr = pathName.split('/');
        var term = pathArr[2];
        var txt = "";
        if (term && term !== "") {
            var de = decodeURIComponent(term);
            txt = atob(de);
            dispatch(setUserSearchTerm(term));
        } else {
            dispatch(searchUsers(""));
        }
        setSearchText(txt);
    }, [location, dispatch])

    const handleOnChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            navigate(searchText);
        }
    }

    const handleSearch = (e) => {
        navigate(searchText);
    }

    const handleClear = (e) => {
        setSearchText("");
        navigate("");
    }

    const navigate = (term) => {
        if (term) term = term.replace('-', ' ');
        var b64 = btoa(term);
        var esc = encodeURIComponent(b64);
        var pathName = '/users/' + esc;
        history.push(pathName);
    }

    const handleInviteCustomer = (v) => {
        setOpen(true);
    }

    const handleCustomerName = (e) => {
        console.log("username: ", e.target.value);
        setData({ ...data, 'user_name': e.target.value });
    }

    const handlePhoneNumber = (e) => {
        setData({ ...data, 'phone_number': e.target.value });
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleInvite = () => {
        setOpen(false);
        onInvite(data);
    }

    return (
        <ThemeProvider theme={theme}>
            <Toolbar>
                <Box textAlign="left" fontWeight="fontWeightBold" flexGrow={1}>
                    <div style={{ height: '40px', alignItems: 'left' }}>
                        <OutlinedInput style={{ height: '40px' }}
                            id="standard-adornment-password"
                            value={searchText}
                            variant="outlined"
                            placeholder={props.placeHolder}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility"
                                        onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                    <IconButton aria-label="toggle password visibility"
                                        onClick={handleClear}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            onChange={handleOnChange}
                            onKeyDown={handleKeyDown}
                        ></OutlinedInput>
                    </div>
                </Box>
                <Box style={{ marginTop: smallScreen ? "10px" : "0px" }}>
                    <Button variant="contained" color="primary" style={{
                        textTransform: 'none', color: 'white', float: 'left', marginLeft: '10px', marginBottom: '10px', whiteSpace: "nowrap",
                    }}
                        onClick={handleInviteCustomer}
                        disableRipple
                    > Invite Customer</Button>
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Invite Customer"}</DialogTitle>
                    <DialogContent>
                        <Grid item xs={12}>
                            <Grid>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: "5px" }}>
                                    <Box style={{ width: '130px' }}>
                                        <Typography style={{ paddingRight: '30px' }}>Customer Name</Typography>
                                    </Box>

                                    <TextField
                                        id="txt-tracking-id"
                                        value={data.user_name !== undefined ? data.user_name : "" || ""}
                                        variant="outlined"
                                        size={"small"}
                                        onChange={(e) => handleCustomerName(e)} />
                                </div>
                            </Grid>
                            <Grid>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Box style={{ width: '130px' }}>
                                        <Typography style={{ paddingRight: '30px' }}>Phone Number</Typography>
                                    </Box>

                                    <TextField
                                        id="txt-tracking-id"
                                        value={data.phone_number !== undefined ? data.phone_number : "" || ""}
                                        variant="outlined"
                                        size={"small"}
                                        onChange={(e) => handlePhoneNumber(e)} />
                                </div>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleInvite} color="primary" autoFocus>
                            Invite
                        </Button>
                    </DialogActions>
                </Dialog>
            </Toolbar>
        </ThemeProvider>
    );
};

UserSearchBar.propTypes = {
    numSelected: PropTypes.number,
    dispatch: PropTypes.func.isRequired
};


export default connect()(UserSearchBar);
