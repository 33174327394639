import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";

const ShowPhotoDialog = (props) => {
    const { open, onClose, onChangeSelectedPhoto, photoUrls, selectedPhoto } = props;

    const handleClose = () => {
        onClose(false);
    }

    const handleSelectedPhoto = (value) => {
        onChangeSelectedPhoto(value);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Photos"}</DialogTitle>
            <DialogContent dividers>
                <Grid item xs={12}>
                    <Grid>
                        <img src={selectedPhoto} className="show-img" alt="logo" />
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                        {photoUrls ? photoUrls.map((value, index) => (
                            <Grid key={index} item>
                                <Box className="square" > <img src={value} className="thumnail-img" alt="logo"
                                    onClick={(e) => handleSelectedPhoto(value)} /></Box>
                            </Grid>
                        )) : <span />}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ShowPhotoDialog;