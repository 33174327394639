import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';
import { getFormatDate, getFormatObject, getTimeFormDate } from '../../util';

const PrintTableData = (props) => {
    const { reports, fields, title } = props;

    return (
        <div style={{ display: 'none', }} >
            <Card>
                <CardContent >
                    <div id="print-table">
                        <span >{title}</span>
                        <table>
                            <thead>
                                <tr>
                                    {fields.map((field, index) => {
                                        return (<th size="small" key={index + 'cell' + field.display_name}>{field.display_name}</th>)
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {reports.map((report, index) => {
                                    return <tr key={'tr' + index} style={{ textAlign: "left" }}>
                                        {fields.map((f, index) => {
                                            if (f.field_type === "date") {
                                                return <td key={'td' + index} style={{ textAlign: "left" }}>{getFormatDate(report[f.field_name], f.display_format)}</td>
                                            }
                                            else if (f.field_type === "time") {
                                                return <td key={'td' + index} style={{ textAlign: "left" }}>{getTimeFormDate(new Date(report[f.field_name]))}</td>
                                            } else if (f.field_type === "object") {
                                                var object = null;
                                                try {
                                                    object = JSON.parse(report[f.field_name]);

                                                } catch (e) {
                                                    console.log('map object error!:', e);
                                                }

                                                if (object) {
                                                    return <td key={'td' + index} style={{ textAlign: "left", whiteSpace: "pre" }}>{getFormatObject(object, f.display_format)}</td>
                                                } else {
                                                    return <td key={'td' + index}></td>;
                                                }

                                            }
                                            else {
                                                return <td key={'td' + index} style={{ textAlign: "left" }}>{report[f.field_name]}</td>
                                            }
                                        })}
                                    </tr>
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                </CardContent>
            </Card>
            <iframe id="printingFrame" style={{
                height: '0px',
                width: '0px',
                position: 'absolute'
            }}
                title={'reports'}
            ></iframe>
        </div>
    );

}

PrintTableData.print = () => {
    console.log("clone=>", document.getElementById('print-table').cloneNode(true));

    var frame = document.getElementById('printingFrame');
    if (!frame) {
        alert("Error: Can't find printing frame.");
        return;
    }
    frame.contentWindow.document.write('<!DOCTYPE html>' +
        '<head><style></style></head>' +
        '<body id="body">' +
        '</body></html>');
    frame.contentWindow.document.getElementById("body").appendChild(document.getElementById('print-table').cloneNode(true));
    frame.contentWindow.document.close();
    frame.contentWindow.focus();
    frame.contentWindow.print();
    return false;
}

PrintTableData.propTypes = {
    reports: PropTypes.array,
    fields: PropTypes.array,
    title: PropTypes.string,
};

export default PrintTableData;
