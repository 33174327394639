import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as Icons from '@material-ui/icons';
import { BoxIcon } from '../icons';
import { AssignPickupIcon } from '../icons';

import React from 'react';

const titles = [
    { name: 'profile', labelKey: 'My Profile', iconName: 'AccountCircle', showAppBarForSmallScreen: true },
    { name: 'users', labelKey: 'Customers', iconName: 'SupervisedUserCircle', showAppBarForSmallScreen: true },
    { name: 'user-detail', labelKey: 'Customers', iconName: 'SupervisedUserCircle', showAppBarForSmallScreen: false },
    { name: 'track-packages', labelKey: 'Track Packages', iconName: 'LocationSearching', showAppBarForSmallScreen: true },
    { name: 'my-packages', labelKey: 'My Packages', iconName: 'BoxIcon', showAppBarForSmallScreen: true },
    { name: 'my-package-detail', labelKey: 'My Pacakge Detail', iconName: 'AccountCircle', showAppBarForSmallScreen: false },
    { name: 'assign-pickups', labelKey: 'Collect', iconName: 'AssignPickupIcon', showAppBarForSmallScreen: true },
    { name: 'pickups', labelKey: 'Pickups', iconName: 'AirportShuttle', showAppBarForSmallScreen: true },
    { name: 'pickup-detail', labelKey: 'Pickups', iconName: 'AirportShuttle', showAppBarForSmallScreen: false },
    { name: 'my-pickups', labelKey: 'My Requested Pickups', iconName: 'AirportShuttle', showAppBarForSmallScreen: true },
    { name: 'my-pickup-detail', labelKey: 'My Requested Pickups', iconName: 'AirportShuttle', showAppBarForSmallScreen: false },
    { name: 'my-invoices', labelKey: 'My Invoices', iconName: 'Receipt', showAppBarForSmallScreen: true },
    { name: 'my-invoice-detail', labelKey: 'My Incoice Detail', iconName: 'Receipt', showAppBarForSmallScreen: false },
    { name: 'invoices', labelKey: 'Invoices', iconName: 'Receipt', showAppBarForSmallScreen: true },
    { name: 'invoice-detail', labelKey: 'Incoice Detail', iconName: 'Receipt', showAppBarForSmallScreen: false },
    { name: 'packages', labelKey: 'Packages', iconName: 'BoxIcon', showAppBarForSmallScreen: true },
    { name: 'package-detail', labelKey: 'Packages', iconName: 'Search', showAppBarForSmallScreen: false },
    { name: 'contact-us', labelKey: 'Contact Us', iconName: 'Phone', showAppBarForSmallScreen: true },
    { name: 'settings', labelKey: 'Settings', iconName: 'Settings', showAppBarForSmallScreen: true },
    { name: 'templates', labelKey: 'Templates', iconName: 'NoteAddSharp', showAppBarForSmallScreen: true },
    { name: 'reports', labelKey: 'Reports', iconName: 'Description', showAppBarForSmallScreen: true },
];


const useStyles = makeStyles((theme) => ({
    root: {
        color: "grey",
    },
    mainMenuItemSelected: {
        padding: '10px 15px 10px 15px',
        color: "grey",
        textTransform: 'none',
        borderBottom: '2px solid ' + theme.palette.primary.main,
    },
}));

const Icon = props => {
    const { iconName, size, color } = props;
    if (iconName === "BoxIcon") {
        return <div style={{ fontSize: size, color: color }}><BoxIcon></BoxIcon></div>;
    }
    if (iconName === "AssignPickupIcon") {
        return <div style={{ fontSize: size, color: color }}><AssignPickupIcon></AssignPickupIcon></div>;
    }

    var i = Icons[iconName];
    const icon = i ? React.createElement(Icons[iconName]) : "";
    return (
        <div style={{ fontSize: size, color: color }}>{icon}</div>
    );
};

function RawTitle(props) {
    const classes = useStyles();
    return <Box className={classes.root} justifyContent="center" display="flex" flexDirection="row" alignItems="center">
        {props.img ? <img src={props.img} alt="FCS Logo" style={{ width: "30px" }} /> :
            <Icon iconName={props.iconName} className={classes.root}></Icon>}
        <Box m={1}>
            <Typography variant="h6" noWrap className={classes.root}>
                {props.labelKey}
            </Typography>
        </Box>
    </Box>;
}

export default function getTitle(page) {
    if (!page) {
        page = 'track-packages';
    }
    let ts = titles.filter(e => e.name === page);
    if (ts && ts.length > 0) {
        let t = ts[0];
        return <RawTitle labelKey={t.labelKey} iconName={t.iconName} img={t.img}></RawTitle>;
    }
    return <span />;
}


export function getPage(pageID) {
    if (!pageID) {
        pageID = 'track-packages';
    }
    let _page = titles.filter(e => e.name === pageID);

    return _page[0];
}