import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import theme from '../../theme';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import MyPickupSearchBar from './my_pickup_search_bar';
import EnhancedTableHeader from '../enhanced_table_header';
import { changeRowPerPage, changeTab } from '../../actions/app';
import { changeMyPickupPage, getMyPickupsData, getPickupDetail } from '../../actions/pickup';
import { formatDateToLocal, removeFCSString } from '../../util';

const useStyles = makeStyles({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px'
    },
    table: {
        minWidth: 650,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    },
    paper: {
        display: "inline-flex",
        width: "100%",
        flexWrap: "wrap"
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const headCells = [
    { id: 'sn', numeric: true, label: 'No.', width: 30 },
    { id: 'pickup_number', numeric: false, label: 'Pickup Number', sortable: true, width: 200 },
    { id: 'pickup_date', numeric: false, label: 'Pickup Date', sortable: true, width: 200 },
    { id: 'zone_name', numeric: false, label: 'Zone', sortable: true, width: 200 },
    { id: 'updated_date', numeric: false, label: 'Updated Date', sortable: true },
    { id: 'status', numeric: false, label: 'Status', sortable: true, width: 100 },
];


function MyPickupList(props) {
    const classes = useStyles();
    const { dispatch, history } = props;
    const count = useSelector(state => state.pickup.myPickupCount);
    const rowsPerPage = useSelector(state => state.app.rowsPerPage);
    const page = useSelector(state => state.pickup.myPickupPage);
    const order = useSelector(state => state.pickup.myPickupOrder);
    const pickups = useSelector(state => state.pickup.myPickups);
    const user = useSelector(state => state.app.user);

    useEffect(() => {
        dispatch(changeTab("my-pickups"));
        if (user.id) {
            dispatch(getMyPickupsData());
        }
    }, [user, dispatch]);

    const handleRequestSort = (event, property) => {
        const isAsc = order['field_name'] === property && order['direction'] === 'asc';
        var _order = { "field_name": property, "direction": isAsc ? 'desc' : 'asc' };
        dispatch(changeMyPickupPage(0));
        dispatch(getMyPickupsData(_order));
    };

    const handleClick = (event, value) => {
        event.preventDefault();
        dispatch(changeTab(''));
        dispatch(getPickupDetail(value.id));
        var pathName = '/my-pickup-detail/' + value.id;
        history.push(pathName);
    };

    const handleChangePage = (event, newPage) => {
        dispatch(changeMyPickupPage(newPage));
        dispatch(getMyPickupsData());
    };

    const handleChangeRowsPerPage = (event) => {
        var rowPerPage = parseInt(event.target.value, 10);
        dispatch(changeMyPickupPage(0));
        dispatch(changeRowPerPage(rowPerPage));
        dispatch(getMyPickupsData());
    };

    return (
        <div className={classes.root}>
            <Paper>
                <MyPickupSearchBar {...props} placeHolder={'Search pickup number'}></MyPickupSearchBar>
                <div>
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">

                            <EnhancedTableHeader
                                classes={classes}
                                order={order.direction}
                                orderBy={order.field_name}
                                onRequestSort={handleRequestSort}
                                rowCount={count}
                                headCells={headCells}
                            />
                            <TableBody>
                                {pickups.map((row) => (
                                    <StyledTableRow key={row['sn']} >
                                        {headCells.map((h, i) => {
                                            if (h.id === 'sn') {
                                                return (<TableCell key={h.id} style={{ width: h.width }} align="right">{row[h.id]}</TableCell>);
                                            } if (h.id === 'pickup_number') {
                                                return (<TableCell className={classes.trackingID} style={{ width: h.width }} key={h.id} align={h.numeric ? 'right' : 'left'}
                                                    onClick={(event) => handleClick(event, row)}
                                                >{row[h.id]}</TableCell>);
                                            }
                                            if (h.id === 'customer_fcs_id') {
                                                return (<TableCell style={{ width: h.width }} key={h.id} align={h.numeric ? 'right' : 'left'}>{removeFCSString(row[h.id])}</TableCell>);
                                            }
                                            if (h.id === 'pickup_date') {
                                                return (<TableCell style={{ width: h.width }} key={h.id} align={h.numeric ? 'right' : 'left'}>{formatDateToLocal(row[h.id], false)}</TableCell>);
                                            }
                                            else if (h.id === 'updated_date') {
                                                return (<TableCell style={{ width: h.width }} key={h.id} align={'left'} >{formatDateToLocal(row[h.id], false)}</TableCell>);
                                            } else {
                                                return (<TableCell key={h.id} style={{ width: h.width }} align={h.numeric ? 'right' : 'left'}>{row[h.id]}</TableCell>);
                                            }
                                        })}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`}
                    />
                </div>
            </Paper>
        </div>
    );
}

MyPickupList.propTypes = {
    history: PropTypes.object,
};


export default connect()(MyPickupList);