import { CLEAR_FILTER_VALUES, CLEAR_REPORTS, GET_FILTER_VALUES, GET_REPORTS } from "../actions/report";

const initialState = {
    reports: [],
    filterValues: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_REPORTS: {
            return {
                ...state,
                reports: action.reports,
            }
        }
        case GET_FILTER_VALUES: {
            return {
                ...state,
                filterValues: action.filterValues
            }
        }
        case CLEAR_REPORTS: {
            return {
                ...state,
                reports: action.reports,
            }
        }
        case CLEAR_FILTER_VALUES: {
            return {
                ...state,
                filterValues: action.filterValues
            }
        }
        default:
            return state;
    }
}