import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { lighten, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import theme from '../../theme';
import { clearPickupDetail, getPickupsData, searchPickups, setPickupSearchTerm } from '../../actions/pickup';
import { useSelector } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import { Box, Button } from '@material-ui/core';
import { setSelectedCustomer } from '../../actions/user';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    btn: {
        color: theme.palette.primary.main,
    }
}));


const PickupSearchBar = (props) => {
    const classes = useToolbarStyles();
    const { dispatch, history, smallScreen } = props;
    const [searchText, setSearchText] = React.useState("");  

    const handleSearch = (e) => {
        var b64 = btoa(searchText);
        var esc = encodeURIComponent(b64);
        dispatch(setPickupSearchTerm(esc));
    }

    const handleOnChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const handleClear = (e) => {
        console.log('handleClear');
        setSearchText("");
        dispatch(searchPickups(""));
    }

    const handleCreatePickupForCustomer = () => {
        dispatch(clearPickupDetail({}));
        dispatch(setSelectedCustomer({}));
        history.push('/create-pickup-form');
    }

    return (
        <ThemeProvider theme={theme}>
            <Toolbar
                className={clsx(classes.root)}
            >
                <Box textAlign="left" fontWeight="fontWeightBold" flexGrow={1}>
                    <div style={{ marginTop: '10px', alignItems: 'left', display: "inline-flex", flexWrap: 'wrap', width: "100%" }}>
                        <OutlinedInput style={{ height: '40px', marginBottom: '10px', }}
                            id="standard-adornment-password"
                            variant="outlined"
                            value={searchText}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility"
                                        onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                    <IconButton aria-label="toggle password visibility"
                                        onClick={handleClear}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            onChange={handleOnChange}
                            onKeyDown={handleKeyDown}
                        ></OutlinedInput>
                    </div>
                </Box>
                <Box style={{ marginTop: smallScreen ? "5px" : "0px" }}>
                    <Button variant="contained" color="primary" style={{ textTransform: 'none', color: 'white', float: 'left', marginLeft: '10px', marginBottom: '10px', whiteSpace: "nowrap" }}
                        onClick={handleCreatePickupForCustomer}
                        disableRipple
                    > Create Pickup</Button>
                </Box>
            </Toolbar>
        </ThemeProvider>
    );
};

export default PickupSearchBar;
