import { Button, IconButton, InputBase, makeStyles, MenuItem, NativeSelect, Select, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTemplateDetail, getTemplates } from '../../actions/template';
import { clearFilterValues, clearReports, downloadReport, generateReports, getFilterValues } from '../../actions/report';
import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/GetApp';
import PrintTableData from './print_table_data';
import { formatDateToLocal, getFormatDate, getFormatObject, getFormatTime, getTimeFormDate } from '../../util';
import ReportTableHeader from './report_table_header';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterListOffIcon } from '../../icons';
import { useLocation } from 'react-router';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        padding: "10px 20px 10px 20px"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }, button: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: 150,
        height: 55
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    cardBox: {
        width: 200,
        height: 150,
        margin: '5px'
    },
    cardDiv: {
        scrollBehavior: 'smooth',
        display: 'flex'
    },
    dot: {
        right: '0',
        top: '4px',
        position: 'absolute',
        background: 'red',
        width: '6px',
        height: '6px',
        borderRadius: '50px',
    },
    parent: {
        margin: '0 auto',
        position: 'relative'
    },
}));

const ReportList = (props) => {
    const classes = useStyles();
    const { dispatch, history, smallScreen } = props;
    const templates = useSelector(state => state.template.templates);
    const templateDetail = useSelector(state => state.template.templateDetail);
    const filterValues = useSelector(state => state.report.filterValues);
    const reports = useSelector(state => state.report.reports);
    const [templateID, setTemplateID] = useState();
    const [templateName, setTemplateName] = useState();
    const [templateObject, setTemplateObject] = useState();
    const [headers, setHeaders] = useState();
    const [filters, setFilters] = useState();
    const [fields, setFields] = useState();
    const [order, setOrder] = useState();
    const [showFilters, setShowFilters] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const location = useLocation();

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var reportId = pathArr[2];
    var dataPayload = pathArr[3];

    useEffect(() => {
        dispatch(getTemplates());
    }, [dispatch]);

    useEffect(() => {
        if (reportId) {
            dispatch(getTemplateDetail(reportId));
        }
    }, [dispatch, reportId]);

    useEffect(() => {
        if (templateDetail.id && dataPayload) {
            setTemplateID(templateDetail.id);
            setTemplateName(templateDetail.name);
            setTemplateObject(templateDetail.object);
            var _fields = JSON.parse(JSON.stringify(templateDetail.fields));
            var _headers = [{ "field_name": "sn", "display_name": "No" }];
            var _filters = [];

            var de = decodeURIComponent(dataPayload);
            var txt = atob(de);
            var obj = JSON.parse(txt);

            _fields.forEach((f, i) => {
                if (f.allow_filter) {
                    _filters.push(f);
                }
                if (f.show) {
                    _headers.push(f);
                }
            });

            _filters.forEach((f, i) => {
                if (obj.filters.length > 0) {
                    obj.filters.forEach((_f) => {
                        if (_f.field === f.field_name) {
                            f.selected_value = _f.value
                        }
                    });
                }
            });

            setFields(_fields);
            setOrder(obj.order);
            setHeaders(_headers);
            setFilters(_filters);

            var value = {
                "object": templateDetail.object,
                "fields": _filters
            }

            if (obj.filters.length > 0) {
                dispatch(getFilterValues(value, obj.selectedField));
            } else {
                dispatch(getFilterValues(value, obj.selectedField));
            }

            var payload = {
                "object": templateDetail.object,
                "fields": _fields,
                "filters": _filters
            }
            dispatch(generateReports(payload, obj.order, () => {
                setIsLoadingData(false);
            }));
        }

    }, [location, dataPayload, dispatch, templateDetail]);

    const clearFilters = () => {
        dispatch(clearReports());
        dispatch(clearFilterValues());
        setFilters([]);
        setHeaders([]);
    }

    const handleClearFilters = () => {
        clearFilters();
        dispatch(getTemplateDetail(templateID));
        navigate([], {}, templateID);
    }

    const handleChangeTemplate = (e) => {
        e.preventDefault();
        setIsLoadingData(true);
        clearFilters();
        if (e.target.value === "") {
            setTemplateID("");
            return;
        }
        var id = e.target.value;
        var _template = templates.find(e => e.id === id);
        var _fields = JSON.parse(JSON.stringify(_template.fields));
        var _filters = [];
        var _headers = [{ "field_name": "sn", "display_name": "No" }];
        var _order = order;

        setTemplateID(id);
        setTemplateName(_template.name);
        setTemplateObject(_template.object);
        setFields(_fields);

        _fields.forEach(f => {
            if (f.allow_filter) {
                _filters.push(f);
            }
            if (f.show) {
                _headers.push(f);
                if (f.allow_sort && f.default_sort !== "no_sort") {
                    _order = { "direction": f.default_sort, "field_name": f.field_name }
                }
            }
        });

        setOrder(_order);
        setHeaders(_headers);
        setFilters(_filters);

        var value = {
            "object": _template.object,
            "fields": _filters
        }
        navigate(_filters, _order, id);
    }

    const handleChangeFilter = (e, changeField, index) => {
        e.preventDefault();
        var _filters = [];
        _filters = JSON.parse(JSON.stringify(filters));
        _filters[index].selected_value = e.target.value;

        // var value = {
        //     "object": templateObject,
        //     "fields": _filters
        // }

        // dispatch(getFilterValues(value, field));
        navigate(_filters, order, templateID, changeField);
    }

    const navigate = (filters, order, id, changeField) => {
        // var payload = {
        //     "object": templateObject,
        //     "fields": fields,
        //     "filters": filters
        // }
        // dispatch(generateReports(payload, order, () => {
        //     setIsLoadingData(false);
        // }));

        var _filters = [];
        filters.forEach((f) => {
            if (f.allow_filter && f.selected_value) {
                var field = f.field_name;
                var value = f.selected_value;
                _filters.push({ 'field': field, 'compare': f.filter_compare, 'value': value });
            }
        });
        var d = {
            "filters": _filters,
            "order": order,
            "selectedField": changeField

        };
        var str = JSON.stringify(d);
        var b64 = btoa(str);
        var encoded = encodeURIComponent(b64);
        var pathName = "/reports/" + id + "/" + encoded;
        history.push(pathName);
    }

    const handlePrint = (e) => {
        e.preventDefault();
        PrintTableData.print();
    }

    const handleDownload = () => {
        dispatch(downloadReport(fields, filters, templateObject, order));
    }

    const getTime = (data) => {
        var date = formatDateToLocal(new Date(data));
        var time = getTimeFormDate(new Date(date));
        return time;
    }

    const handleFilter = (e) => {
        setShowFilters(!showFilters);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = order['field_name'] === property && order['direction'] === 'asc';
        var _order = { "field_name": property, "direction": isAsc ? 'desc' : 'asc' };
        var payload = {
            "object": templateObject,
            "fields": fields,
            "filters": filters,
        }
        setOrder(_order);
        dispatch(generateReports(payload, _order, () => {
            setIsLoadingData(false);
        }));
    };

    return (
        <div className={classes.root}>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                                <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Report'}</Typography>
                                <NativeSelect
                                    id="field-select"
                                    value={templateID ? templateID : "" || ""}
                                    onChange={handleChangeTemplate}
                                    style={{ width: '200px' }}
                                    input={<BootstrapInput />}
                                >
                                    <option key={'none'} value={""}>{"Select Report"}</option>
                                    {templates ? templates.map((s) => {
                                        return <option key={s.id} value={s.id}>{s.name}</option>
                                    }) : <MenuItem />}
                                </NativeSelect>

                                <div className={classes.parent}>
                                    {filters ? filters.map((f, index) => {
                                        var svalue = f.selected_value;
                                        if (svalue) {
                                            return <div key={index} className={classes.dot}></div>
                                        } else { return <span key={index} /> }
                                    }) : <span />}
                                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                        <Tooltip title="Show/Hide Filters">
                                            <IconButton color="primary" onClick={handleFilter} size="small" >
                                                <FilterListIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                    <Tooltip title="Clear Filters">
                                        <IconButton color="primary" onClick={handleClearFilters} size="small">
                                            <FilterListOffIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                    <Tooltip title="Print">
                                        <span>
                                            <IconButton disabled={!templateID ? true : false} color="primary" onClick={handlePrint} size="small">
                                                <PrintIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {showFilters ?
                        filters ? filters.map((f, index) => {
                            var _fieldType = f.field_type;
                            var _dropdownField = f.dropdown_display_field;
                            var _field = filterValues[index] ? filterValues[index].field_name : '';
                            var _values = filterValues[index] ? filterValues[index].values : [];
                            return <tr key={'tr' + index}>
                                <td>
                                    <div key={index} style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{f.display_name}</Typography>
                                        <Select
                                            id="filter-select"
                                            value={f.selected_value ? f.selected_value : "" || ""}
                                            onChange={(e) => handleChangeFilter(e, _field, index)}
                                            style={{ width: '200px' }}
                                            input={<BootstrapInput />}
                                        >
                                            {_values ? _values.map((s, i) => {
                                                if (_fieldType === "date") {
                                                    return <MenuItem key={s + i} value={s[_field]}>{formatDateToLocal(new Date(s[_field]), false)}</MenuItem>
                                                }
                                                if (_fieldType === "time") {
                                                    return <MenuItem key={s + i} value={s[_field]}>{getTime(s[_field])}</MenuItem>

                                                } else if (_dropdownField || _dropdownField !== '') {
                                                    return <MenuItem key={s + i} value={s[_field]}>{s[_dropdownField]}</MenuItem>
                                                } else {
                                                    return <MenuItem key={s + i} value={s[_field]}>{s[_field]}</MenuItem>
                                                }

                                            }) : <MenuItem />}
                                        </Select>

                                    </div>
                                </td>
                            </tr>
                        }) : <tr /> : <tr />}
                </tbody>
            </table>
            {templateID ?
                <TableContainer>
                    <Table size="small">
                        <ReportTableHeader
                            classes={classes}
                            headCells={headers}
                            order={order && order.direction ? order.direction : 'asc'}
                            orderBy={order && order.field_name ? order.field_name : "sn"}
                            onRequestSort={handleRequestSort}
                        >
                        </ReportTableHeader>
                        <TableBody>
                            {isLoadingData ? <TableRow>
                                <TableCell colSpan={headers.length + 1} style={{ textAlign: "center" }}>{"Loading..."}</TableCell>
                            </TableRow> :
                                reports.length === 0 ?
                                    <TableRow>
                                        <TableCell colSpan={headers.length + 1} style={{ textAlign: "center" }}>{"No Data"}</TableCell>
                                    </TableRow> : <TableRow />}
                            {reports.map((report, index) => (<TableRow key={index}>
                                {headers.map((f, index) => {
                                    if (f.field_type === "date") {
                                        return <TableCell key={'cell' + index}>{getFormatDate(report[f.field_name], f.display_format)}</TableCell>
                                    }
                                    else if (f.field_type === "time") {
                                        return <TableCell key={'cell' + index}>{getFormatTime(report[f.field_name], f.display_format)}</TableCell>
                                    } else if (f.field_type === "object") {
                                        var object = null;
                                        try {
                                            object = JSON.parse(report[f.field_name]);

                                        } catch (e) {
                                            // console.log('map object error!:', e);
                                        }

                                        if (object) {
                                            return <TableCell key={'cell' + index} style={{ whiteSpace: "pre" }}>{getFormatObject(object, f.display_format)}</TableCell>;
                                        } else {
                                            return <TableCell key={'cell' + index}></TableCell>;
                                        }
                                    }
                                    else {
                                        return <TableCell key={'cell' + index}>{report[f.field_name]}</TableCell>
                                    }

                                })}

                            </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer> : <span />}
            {templateName ?
                <PrintTableData
                    title={templateName ? templateName : ""}
                    reports={reports ? reports : []}
                    fields={headers ? headers : []} /> : <span />}
        </div>
    );
}

export default ReportList;