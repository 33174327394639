import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Box, makeStyles, NativeSelect, Grid, InputBase, withStyles, Paper, TextField } from '@material-ui/core';
import theme from '../../theme';
import { connect, useSelector } from 'react-redux';
import { getPickupDates, getPickupZones } from '../../actions/app';
import AddressCard from '../address_card';
import { changeCustomerPickupAddress, getPickupDetail, setPickupData, updateAdminPickupRequest } from '../../actions/pickup';
import { convertStringToDate, formatDateToLocal, getDay, removeFCSString } from '../../util';
import firebase from "../../firebase_config";
import { getCustomerAddresses, getCustomerDetail } from '../../actions/user';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));

function MyPickupEdit(props) {
    const classes = useStyles();
    const { title, history, dispatch, smallScreen } = props;

    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const addresses = useSelector(state => state.user.customerAddresses);
    const selectedCustomer = useSelector(state => state.user.selectedCustomer);
    const data = useSelector(state => state.pickup.pickupDetail)

    const zones = useSelector(state => state.app.zones);
    const dates = useSelector(state => state.app.pickupDates);
    const selectedPickupAddress = useSelector(state => state.pickup.customerPickupAddress);

    const [zoneDates, setZoneDates] = useState([]);

    const [zoneId, setZoneId] = useState();
    const [pickupDate, setPickupDate] = useState();
    const [customerRemark, setCustomerRemark] = useState();
    const [pickupAddress, setPickupAddress] = useState();

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var pickupId = pathArr[2];

    useEffect(() => {
        dispatch(getPickupDetail(pickupId));
    }, [dispatch, pickupId]);

    useEffect(() => {
        if (data.id !== undefined) {
            setCustomerRemark(data.customer_remark);
            setZoneId(data.zone_id);
            setPickupDate((data.pickup_date).toDate());

            if (selectedPickupAddress.id === undefined) {
                setPickupAddress(data.pickup_address);
            } else {
                setPickupAddress(selectedPickupAddress);
            }
        }

    }, [data, selectedPickupAddress]);

    useEffect(() => {
        if (data.id) {
            dispatch(getCustomerDetail(data.customer_id));
        }

    }, [data, dispatch]);


    useEffect(() => {
        if (data.id !== undefined) {
            var _dates = [];
            dates.forEach(d => {
                for (var i = 0; i < d.zones.length; i++) {
                    if (data.zone_id === d.zones[i].id) {
                        var dbDate= convertStringToDate(d.id);
                        var compareDate = new Date(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate());

                        var today = new Date();
                        today.setHours(0, 0, 0, 0);
                        if (compareDate >= today) {
                            _dates.push(dbDate);
                        }
                    }
                }
            });

            setZoneDates(_dates);
        }
    }, [addresses, data, dates]);

    useEffect(() => {
        dispatch(getPickupZones());
        dispatch(getPickupDates());
    }, [dispatch]);

    const handleChangePickupZone = (e) => {
        var id = e.target.value;
        var _dates = [];
        dates.forEach(d => {
            for (var i = 0; i < d.zones.length; i++) {
                if (id === d.zones[i].id) {
                    var dbDate= convertStringToDate(d.id);
                    var compareDate = new Date(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate());

                    var today = new Date();
                    today.setHours(0, 0, 0, 0);
                    if (compareDate >= today) {
                        _dates.push(dbDate);
                    }
                }
            }
        });
        setZoneDates(_dates);
        setZoneId(id);
        setPickupDate();
    }

    const handleChangePickupDate = (e) => {
        var date = e.target.value;
        setPickupDate(new Date(date));
    }

    const handleRemark = (e) => {
        setCustomerRemark(e.target.value);
    }

    const handleBack = () => {
        dispatch(setPickupData({}));
        dispatch(changeCustomerPickupAddress({}));
        history.goBack();
    }

    const handleUpdatePickup = () => {
        var value = {
            "id": data.id,
            "zone_id": zoneId,
            "pickup_date": pickupDate.toISOString(),
            "customer_id": data.customer_id,
            "customer_remark": customerRemark,
            "address_id": pickupAddress.id
        }
        dispatch(setPickupData({}));
        dispatch(changeCustomerPickupAddress({}));

        dispatch(updateAdminPickupRequest(value, () => {
            history.goBack();
        }));
    }

    const handleChangeAddress = () => {
        const timestamp = firebase.firestore.Timestamp.fromDate(pickupDate);
        var value = {
            "id": data.id,
            "zone_id": zoneId,
            "pickup_date": timestamp,
            "customer_id": data.customer_id,
            "customer_remark": customerRemark,
            "address_id": pickupAddress.id
        }
        dispatch(setPickupData(value));
        dispatch(getCustomerAddresses(data.customer_id));
        history.push("/customer-addresses/" + selectedCustomer.id);
    }

    return (

        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><Button color="primary" onClick={handleBack} ><BackIcon /></Button></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? "5px" : '30px' }}>
                <tbody>
                    <tr style={{ height: '50px' }}>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{data.pickup_number !== undefined ? data.pickup_number : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{data.status !== undefined ? data.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Zone'}</Typography>
                                <NativeSelect
                                    id="pickup-zone-select"
                                    value={zoneId ? zoneId : '' || ''}
                                    onChange={handleChangePickupZone}
                                    style={{ width: '200px' }}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" >Select</option>
                                    {zones.map((e) => {
                                        return <option key={e.id} value={e.id}>{e.name}</option>;
                                    })}
                                </NativeSelect>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Date'}</Typography>
                                <NativeSelect
                                    id="pickup-date-select"
                                    value={pickupDate ? pickupDate : '' || ''}
                                    onChange={handleChangePickupDate}
                                    style={{ width: '200px' }}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" >Select</option>
                                    {zoneDates.map((d) => {
                                        return (<option name={d} value={d} key={d}>{formatDateToLocal(d, false) + '\t' + getDay(d)}</option>);
                                    })}
                                </NativeSelect>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{selectedCustomer ? removeFCSString(selectedCustomer.fcs_id) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{selectedCustomer ? selectedCustomer.user_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <div>
                                    <Box style={{ display: 'inline-flex', marginTop: '5px' }}>
                                        <Typography style={{ color: 'grey', paddingRight: '30px' }}>{'Pickup Address'}</Typography>
                                        <Button size="small" variant="outlined" color="primary" style={{ float: 'left', textTransform: 'none' }}
                                            onClick={handleChangeAddress}
                                        >Select address</Button>
                                    </Box>
                                </div>
                                <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                    {pickupAddress ? <Paper key={pickupAddress.id} style={{ marginRight: '5px', marginBottom: '5px', width: '280px', height: '165px', border: '1px solid lightgray' }}>
                                        <AddressCard address={pickupAddress}
                                            editIcon={false}
                                            deleteIcon={false}
                                            makeDefaultIcon={false}
                                        />
                                    </Paper> : <span />}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Customer Remark'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={customerRemark ? customerRemark : '' || ''}
                                    style={{ width: '400px' }}
                                    multiline
                                    rows={3}
                                    onChange={handleRemark}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleUpdatePickup}
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Update request pickup"} </Button>
                                </Box>

                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

MyPickupEdit.propTypes = {
    history: PropTypes.object,
    open: PropTypes.bool,
    callback: PropTypes.func
};

export default connect()(MyPickupEdit);
