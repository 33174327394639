import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AssignMap from './assign_map';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Box, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        width: '100%',
        flexGrow: 1,
        paddingTop: "10px",
    },
});


function AssignPickupRoute(props) {
    const { title, history, smallScreen } = props;
    const classes = useStyles();

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                <Box><Button color="primary" onClick={handleBack} ><BackIcon /></Button></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '5px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <AssignMap smallScreen={smallScreen}></AssignMap>
        </div>
    );
}

AssignPickupRoute.propTypes = {
    history: PropTypes.object,
};
export default connect()(AssignPickupRoute);