import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogContent, DialogActions, DialogTitle, TextField, Typography, NativeSelect, InputBase, FormGroup, FormControlLabel, Radio, RadioGroup, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const fieldTypes = ["string", "bool", "date", "time", "int", "float", "object"];
const filterCompares = ["=", ">=", "<=", ">", "<", "blank", "not blank"];

function TemplateFieldDialog(props) {
    const { data, title, fields, onClose, onSave, open } = props;
    const [fieldName, setFieldName] = useState();
    const [displayName, setDisplayName] = useState();
    const [fieldType, setFieldType] = useState();
    const [filterCompare, setFilterCompare] = useState();
    const [show, setShow] = useState(false);
    const [displayFormat, setDisplayFormat] = useState();
    const [allowFilter, setAllowFilter] = useState(false);
    const [allowSort, setAllowSort] = useState(false);
    const [defaultSort, setDefaultSort] = useState("no_sort");
    const [dropDownDisplayField, setDropDownDisplayField] = useState();

    useEffect(() => {
        if (data) {
            setFieldName(data.field_name);
            setDisplayName(data.display_name);
            setFieldType(data.field_type);
            setShow(data.show);
            setDisplayFormat(data.display_format);
            setAllowFilter(data.allow_filter);
            setAllowSort(data.allow_sort);
            setDefaultSort(data.default_sort);
            setDropDownDisplayField(data.dropdown_display_field);
            setFilterCompare(data.filter_compare);
        }

    }, [data]);

    const handleClose = () => {
        onClose(false);
    }

    const handleSave = () => {
        var value = {
            "field_name": fieldName,
            "display_name": displayName,
            "field_type": fieldType,
            "show": show,
            "display_format": displayFormat,
            "allow_filter": allowFilter,
            "allow_sort": allowSort,
            "default_sort": defaultSort,
            "dropdown_display_field": dropDownDisplayField,
            "filter_compare": filterCompare
        }
        console.log("field card:", value);
        onSave(value, data.index);
        onClose(false);
    }

    const handleChangeField = (e) => {
        setFieldName(e.target.value);
        setDisplayName(e.target.value);
    }

    const handleDisplayName = (e) => {
        setDisplayName(e.target.value);
    }

    const handleFieldType = (e) => {
        setFieldType(e.target.value);
    }

    const handleOnChangeDisplay = (e) => {
        setShow(e.target.checked);
    }

    const handleDisplayFormat = (e) => {
        setDisplayFormat(e.target.value);
    }

    const handleOnChangeAllowFilter = (e) => {
        setAllowFilter(e.target.checked);
    }

    const handleOnChangeAllowSort = (e) => {
        setAllowSort(e.target.checked);
    }

    const handleOnChangeDefaultSort = (e) => {
        setDefaultSort(e.target.value);
    }

    const handleChangeDropDownDisplayField = (e) => {
        setDropDownDisplayField(e.target.value);
    }

    const handleChangeFilterCompare = (e) => {
        setFilterCompare(e.target.value);
    }

    return <div>
        <div style={{ textAlign: 'start' }}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Field Name'}</Typography>
                        <NativeSelect
                            id="field-select"
                            value={fieldName ? fieldName : "" || ""}
                            onChange={handleChangeField}
                            style={{ width: '200px' }}
                            input={<BootstrapInput />}
                        >
                            <option aria-label="None" value="" >Select</option>
                            {fields.map((s) => {
                                return <option key={s.cid} value={s.name}>{s.name}</option>
                            })}
                        </NativeSelect>

                    </div>
                    <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Display Name'}</Typography>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            size={"small"}
                            value={displayName ? displayName : "" || ""}
                            style={{ width: '200px' }}
                            onChange={(e) => handleDisplayName(e)}
                        />

                    </div>
                    <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Field Type'}</Typography>
                        <NativeSelect
                            id="field-select"
                            value={fieldType ? fieldType : "" || ""}
                            onChange={handleFieldType}
                            style={{ width: '200px' }}
                            input={<BootstrapInput />}
                        >
                            <option aria-label="None" value="" >Select</option>
                            {fieldTypes.map((s) => {
                                return <option key={s} value={s}>{s}</option>
                            })}
                        </NativeSelect>

                    </div>
                    <div style={{ display: "flex", marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Display Format'}</Typography>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            size={"small"}
                            multiline
                            rows={10}
                            value={displayFormat ? displayFormat : "" || ""}
                            style={{ width: '200px' }}
                            onChange={(e) => handleDisplayFormat(e)}
                        />

                    </div>
                    <div>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox color="primary" checked={show ? show : false} onChange={handleOnChangeDisplay} />} label="Show" />
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox color="primary" checked={allowSort ? allowSort : false} onChange={handleOnChangeAllowSort} />} label="Allow Sort" />
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox color="primary" checked={allowFilter ? allowFilter : false} onChange={handleOnChangeAllowFilter} />} label="Allow Filter" />
                        </FormGroup>
                        {allowFilter ?
                            <Box style={{ border: "1px solid lightgrey", borderRadius: '3px', padding: "18px" }}>
                                <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                                    <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Filter compare'}</Typography>
                                    <NativeSelect
                                        id="field-select"
                                        value={filterCompare ? filterCompare : "" || ""}
                                        onChange={handleChangeFilterCompare}
                                        style={{ width: '200px' }}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >Select</option>
                                        {filterCompares.map((s) => {
                                            return <option key={s} value={s}>{s}</option>
                                        })}
                                    </NativeSelect>

                                </div>
                                <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                                    <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Dropdown Display Field'}</Typography>
                                    <NativeSelect
                                        id="field-select"
                                        value={dropDownDisplayField ? dropDownDisplayField : "" || ""}
                                        onChange={handleChangeDropDownDisplayField}
                                        style={{ width: '200px' }}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >Select</option>
                                        {fields.map((s) => {
                                            return <option key={s.cid} value={s.name}>{s.name}</option>
                                        })}
                                    </NativeSelect>

                                </div>
                            </Box> : <Box />}
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Default Sort'}</Typography>
                        <RadioGroup row aria-label="order" name="row-radio-buttons-group"
                            value={defaultSort ? defaultSort : "no_sort"}
                            onChange={handleOnChangeDefaultSort}
                        >
                            <FormControlLabel value="no_sort" control={<Radio color="primary" />} label="No sorting" />
                            <FormControlLabel value="asc" control={<Radio color="primary" />} label="Ascending" />
                            <FormControlLabel value="desc" control={<Radio color="primary" />} label="Descending" />
                        </RadioGroup>
                    </div>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </div>;
}

TemplateFieldDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    fields: PropTypes.array,
    data: PropTypes.object,
    onClose: PropTypes.func,
    onSave: PropTypes.func
}

export default TemplateFieldDialog;