import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { changeRowPerPage, changeTab } from '../../actions/app';
import { getSelectedUser } from '../../actions/explore';
import { changeUserPage, getUserDetail, getUsersData, inviteUser } from '../../actions/user';
import theme from '../../theme';
import palette from '../../theme/palette';
import { formatDateToLocal, removeFCSString } from '../../util';
import EnhancedTableHeader from '../enhanced_table_header';
import UserSearchBar from './user_search_bar';
import LocalOverlay from '../local_overlay';


const useStyles = makeStyles({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px'
    },
    table: {
        minWidth: 650,
    },
    FCSID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    },
    packages: {
        cursor: "pointer",
        color: palette.primary.main,
        textDecoration: "underline",
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const headCells = [
    { id: 'sn', numeric: true, label: 'No.', width: 50 },
    { id: 'fcs_id', numeric: false, label: 'FCS ID', sortable: true, width: 100 },
    { id: 'user_name', numeric: false, label: 'Name', sortable: true, width: 200 },
    { id: 'phone_number', numeric: false, label: 'Phone Number', sortable: true, width: 150 },
    { id: 'updated_date', numeric: false, label: 'Updated Date', sortable: true, width: 150 },
    { id: 'status', numeric: false, label: 'Status', sortable: true, width: 100 },
    { id: 'packages', numeric: false, label: '', width: 100 },
];

function UserList(props) {
    const classes = useStyles();
    const { dispatch, history } = props;
    const users = useSelector(state => state.user.users);
    const count = useSelector(state => state.user.count);
    const rowsPerPage = useSelector(state => state.app.rowsPerPage);
    const page = useSelector(state => state.user.page);
    const order = useSelector(state => state.user.order);
    const [loading, setIsLoading] = useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = order['field_name'] === property && order['direction'] === 'asc';
        var _order = { "field_name": property, "direction": isAsc ? 'desc' : 'asc' };
        dispatch(changeUserPage(0));
        dispatch(getUsersData(_order));
    };

    const handleClick = (event, id) => {
        console.log('id:', id);
        event.preventDefault();
        var pathName = '/user-detail/' + id;
        dispatch(getUserDetail(id));
        history.push(pathName);
    };

    const handleChangePage = (event, newPage) => {
        dispatch(changeUserPage(newPage));
        dispatch(getUsersData());
    };

    const handleChangeRowsPerPage = (event) => {
        var rowPerPage = parseInt(event.target.value, 10);
        dispatch(changeUserPage(0));
        dispatch(changeRowPerPage(rowPerPage));
        dispatch(getUsersData());
    };

    const handleUserPackages = (e, value) => {
        dispatch(changeTab(''));
        history.push('/user-packages/' + value.id + '/packages');
    }

    const handleInvite = (value) => {
        setIsLoading(true);
        dispatch(inviteUser(value, () => {
            setIsLoading(false);
        }));
    }

    return (
        <LocalOverlay isActive={loading}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <UserSearchBar placeHolder={'Search user'} {...props} onInvite={(value) => handleInvite(value)}></UserSearchBar>
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <EnhancedTableHeader
                                classes={classes}
                                order={order.direction}
                                orderBy={order.field_name}
                                onRequestSort={handleRequestSort}
                                rowCount={count}
                                headCells={headCells}
                            />
                            <TableBody>
                                {users.map((row) => (
                                    <StyledTableRow key={row['id']}>
                                        {headCells.map((h, i) => {
                                            if (h.id === 'sn') {
                                                return (<TableCell key={h.id} align="right" style={{ width: h.width }}>{row[h.id]}</TableCell>);
                                            } if (h.id === 'fcs_id') {
                                                return (<TableCell style={{ width: h.width }} className={classes.FCSID} key={h.id} align={'left'} onClick={(event) => handleClick(event, row['id'])}>{removeFCSString(row[h.id])}</TableCell>);
                                            } else if (h.id === 'packages') {
                                                return (<TableCell style={{ width: h.width }} key={h.id} align={'left'} className={classes.packages} onClick={(event) => handleUserPackages(event, row)}>{'packages'}</TableCell>);
                                            }
                                            else if (h.id === 'updated_date') {
                                                return (<TableCell style={{ width: h.width }} key={h.id} align={'left'} >{formatDateToLocal(row[h.id])}</TableCell>);
                                            } else {
                                                return (<TableCell style={{ width: h.width }} key={h.id} align={'left'}>{row[h.id]}</TableCell>);
                                            }
                                        })}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`}
                    />
                </Paper>
            </div>
        </LocalOverlay>
    );
}

export default connect()(UserList);
