import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import FCSApp from './app';
import configureStore from './store'
const store = configureStore();

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <FCSApp />
        </BrowserRouter>

    </Provider>
    , rootElement
);


