import React from 'react';
import { Typography, CardContent, Tooltip, IconButton, Card, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { connect } from 'react-redux';
import { getCityStateZipCode, getFullAddress } from '../util';
import ConfirmDialog from './confirm_dialog';

function AddressCard(props) {
    const { address, onMakeDefault, onEdit, onDelete, onSelectedChange } = props;
    const [isDeleteConfirm, setDeleteConfirm] = React.useState(false);
    const [isMakeDefault, setMakeDefault] = React.useState(false);

    const handleMakeDefault = (v) => {
        onMakeDefault(address);
        setMakeDefault(v);
    }

    const handleMakeDefaultDialog = () => {
        setMakeDefault(true);
    }

    const handleEditAddress = _address => {
        onEdit(_address.id);
    }

    const handleDeleteAddress = (v) => {
        setDeleteConfirm(v);
        onDelete(address);
    }

    const handleSelectedChange = _address => {
        onSelectedChange(_address);
    }

    const handleDeleteCancel = (v) => {
        setDeleteConfirm(v);
    }

    const handleDeleteDialog = () => {
        setDeleteConfirm(true);
    }

    const handelMakeDefaultCancel = (v) => {
        setMakeDefault(v);
    }


    return (
        <Card key={address.id} style={{ width: '280px', height: '165px' }}>
            <CardContent style={{ padding: '10px' }}>
                <div style={{
                    color: 'grey',
                    height: '115px',
                }}>
                    <div style={{ width: '100%', display: 'inline-flex' }}>
                        <Typography variant="h5" style={{ width: '75%', textAlign: 'left' }}>{address.full_name}</Typography>
                        <div style={{ display: 'inline-flex', }}>
                            {onEdit !== undefined ? <Tooltip title="Edit" aria-label="add"><IconButton key={'edit'} color="primary" size="small" onClick={() => handleEditAddress(address)} ><EditIcon /></IconButton></Tooltip> : <span />}
                            {onDelete !== undefined ? <Tooltip title="Delete" aria-label="add"><IconButton key={'delete'} color="primary" size="small" onClick={() => handleDeleteDialog(address)}><DeleteIcon /></IconButton></Tooltip> : <span />}
                        </div>
                    </div>
                    <div><Typography style={{ color: 'grey', textAlign: 'left' }}>{address.phone_number}</Typography></div>
                    <div><Typography style={{ wordWrap: 'break-word', color: 'grey', textAlign: 'left' }}>{getFullAddress(address)}</Typography></div>
                    <div><Typography style={{ wordWrap: 'break-word', color: 'grey', textAlign: 'left' }}>{getCityStateZipCode(address)}</Typography></div>
                    <div><Typography style={{ wordWrap: 'break-word', color: 'grey', textAlign: 'left' }}>{address.country}</Typography></div>
                </div>
                {onSelectedChange !== undefined ? <div><Button color="primary" variant="contained" size="small" style={{ textTransform: 'none', float: 'right' }}
                    onClick={() => handleSelectedChange(address)}
                >Select address</Button></div> : <div />}
                {onMakeDefault !== undefined ? (!address.is_default) ? <div><Button color="primary" variant="outlined" size="small" style={{ textTransform: 'none', float: 'right' }}
                    onClick={() => handleMakeDefaultDialog(address)}
                >Make default</Button></div> : <div /> : <div />}
            </CardContent>
            {isDeleteConfirm ? <ConfirmDialog
                msg={'Delete this delivery address "' + address.full_name + '"?'}
                openDialog={isDeleteConfirm}
                confirmBtnLabel={"Delete"}
                onCancel={(v) => handleDeleteCancel(v)}
                onContinue={(v) => handleDeleteAddress(v)}></ConfirmDialog> : <div />}

            {isMakeDefault ? <ConfirmDialog
                msg={'Make "' + address.full_name + '" as the default delivery address?'}
                openDialog={isMakeDefault}
                confirmBtnLabel={"Make default"}
                onCancel={(v) => handelMakeDefaultCancel(v)}
                onContinue={(v) => handleMakeDefault(v)}
            ></ConfirmDialog> : <div />}
        </Card>

    );
}

AddressCard.propTypes = {
    address: PropTypes.any,
    onMakeDefault: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSelectedChange: PropTypes.func,
}

export default connect()(AddressCard);