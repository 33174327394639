import React, { } from 'react';
import { MenuItem, FormControl, ListItemText, Select, Checkbox, Input, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme';
import PropTypes from 'prop-types';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
});
const statusAllList = [
    'ACTIVES',
    'ALL',
    'requested',
    'confirmed',
    'rescheduled',
    'completed',
    'canceled',
];
const statusList = [
    'requested',
    'confirmed',
    'rescheduled',
    'completed',
    'canceled',
];

function PickupStatusFilter(props) {
    const classes = useStyles();
    const { filter, onChange, statusActives } = props;
    const [status, setStatus] = React.useState(filter);
    var statusListActives=[];
    if (statusActives) {
        statusListActives = statusActives;
    }else{
        statusListActives=[
            'requested',
            'confirmed',
            'rescheduled',
        ];
    }

    const handleChange = (event) => {
        var s = event.target.value;
        if (!status.includes("ACTIVES") && s.includes("ACTIVES")) {
            s = statusListActives;
        } else if (status.includes("ACTIVES") && !s.includes("ACTIVES")) {
            s = [];
        } else if (status.includes("ALL") && !s.includes("ALL")) {
            s = [];
        } else if (!status.includes("ALL") && s.includes("ALL")) {
            s = statusAllList;
        } else if (s.includes("ALL")) {
            if (!statusList.every(e => s.includes(e))) {
                s = s.filter(e => e !== "ALL");
            }
        }
        if (statusListActives.every(e => s.includes(e))) {
            if (!s.includes("ACTIVES")) {
                s.push("ACTIVES");
            }
        } else {
            s = s.filter(e => e !== "ACTIVES");
        }
        setStatus(s);
        onChange(s);
    };

    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Status</InputLabel>
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={status}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => {
                        if (selected.includes("ALL") || statusAllList.every(e => selected.includes(e)) || statusList.every(e => selected.includes(e))) {
                            return "ALL";
                        }
                        if (selected.includes("ACTIVES") || statusListActives.every(e => selected.includes(e))) {
                            return "ACTIVES";
                        }
                        return selected.join(', ');
                    }
                    }
                    MenuProps={MenuProps}
                >
                    {statusAllList.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={status.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

        </div>
    );
}

PickupStatusFilter.propTypes = {
    history: PropTypes.object,
};

export default PickupStatusFilter;