import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Box, IconButton, makeStyles, NativeSelect, Grid, InputBase, withStyles, Paper, TextField, FormControl, FormHelperText } from '@material-ui/core';
import theme from '../../theme';
import { connect, useSelector } from 'react-redux';
import { getPickupDates, getPickupZones } from '../../actions/app';
import AddressCard from '../address_card';
import { addAdminPickupRequest, changeCustomerPickupAddress, setPickupData } from '../../actions/pickup';
import {  convertStringToDate, formatDateToLocal, getDay } from '../../util';
import { clearSearchCustomers } from '../../actions/user';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    table: {
        padding: "10px 20px 10px 20px"
    },
}));

function CreatePickupForCustomer(props) {
    const classes = useStyles();
    const { title, history, dispatch } = props;

    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const selectedCustomer = useSelector(state => state.user.selectedCustomer);
    const addresses = useSelector(state => state.user.customerAddresses);
    const zones = useSelector(state => state.app.zones);
    const dates = useSelector(state => state.app.pickupDates);

    const pickupAddress = useSelector(state => state.pickup.customerPickupAddress);
    const [zoneDates, setZoneDates] = useState([]);

    const data = useSelector(state => state.pickup.pickupDetail)

    const [pickupDate, setPickupDate] = useState();
    const [pickupZone, setPickupZone] = useState();
    const [customerRemark, setCustomerRemark] = useState();

    const [customerFCSId, setCustomerFCSId] = useState();
    const [customerID, setCustomerID] = useState();
    const [customerName, setCustomerName] = useState();
    const [customerPhoneNumber, setCustomerPhoneNumber] = useState();

    const [error, setError] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        dispatch(getPickupZones());
        dispatch(getPickupDates());
    }, [dispatch]);

    useEffect(() => {
        if (data.zone_id !== undefined) {
            setPickupZone(data.zone_id);
            setCustomerRemark(data.customer_remark);
            setCustomerID(data.customer_id);
            setCustomerFCSId(data.customer_fcs_id);
            setCustomerName(data.customer_name);
            setCustomerPhoneNumber(data.customer_phone_number);
            setPickupDate(data.pickup_date);
        }
    }, [data]);

    useEffect(() => {
        if (selectedCustomer.id) {
            setCustomerID(selectedCustomer.id);
            setCustomerFCSId(selectedCustomer.fcs_id);
            setCustomerName(selectedCustomer.user_name);
            setCustomerPhoneNumber(selectedCustomer.phone_number);
        }
    }, [selectedCustomer]);

    useEffect(() => {
        if (pickupZone !== undefined) {
            var _dates = [];
            dates.forEach(d => {
                for (var i = 0; i < d.zones.length; i++) {
                    if (pickupZone === d.zones[i].id) {
                        var dbDate= convertStringToDate(d.id);
                        var compareDate = new Date(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate());

                        var today = new Date();
                        today.setHours(0, 0, 0, 0);

                        if (compareDate >= today) {
                            _dates.push(dbDate);
                        }

                    }
                }
            });

            setZoneDates(_dates);
        }
    }, [addresses, dates, pickupZone]);

    const handleChangePickupZone = (e) => {
        var id = e.target.value;
        var _dates = [];
        dates.forEach(d => {
            for (var i = 0; i < d.zones.length; i++) {
                if (id === d.zones[i].id) {
                    _dates.push(d.id);
                }
            }
        });
        setPickupZone(id);
        setZoneDates(_dates);
    }

    const handleChangePickupDate = (e) => {
        var date = e.target.value;
        setPickupDate(new Date(date));
    }

    const handleRemark = (e) => {
        setCustomerRemark(e.target.value);
    }

    const handleBack = () => {
        dispatch(setPickupData({}));
        dispatch(changeCustomerPickupAddress({}));
        history.goBack();
    }

    const handleRequestPickup = () => {
        let isError = false;
        var errorMsg = [...errorMessages];
        if (!pickupZone) {
            isError = true;
            setError(true);
            errorMsg['zone'] = "Pickup zone is required";
            setErrorMessages(errorMsg);
        }
        if (!pickupDate) {
            isError = true;
            setError(true);
            errorMsg['pickupDate'] = "Pickup date is required";
            setErrorMessages(errorMsg);
        }
        if (!customerID) {
            isError = true;
            setError(true);
            errorMsg['customer'] = "Customer is required";
            setErrorMessages(errorMsg);
        }
        if (!pickupAddress.id) {
            isError = true;
            setError(true);
            errorMsg['address'] = "Pickup address is required";
            setErrorMessages(errorMsg);
        }
        if (!isError) {
            setError(false);
            setErrorMessages([]);
            var d = {
                "zone_id": pickupZone,
                "pickup_date": pickupDate.toISOString(),
                "customer_id": customerID,
                "customer_remark": customerRemark,
                "address_id": pickupAddress.id
            };
            console.log("pickup request for customer:",d);
            dispatch(setPickupData({}));
            dispatch(changeCustomerPickupAddress({}));
            dispatch(addAdminPickupRequest(d, () => {
                history.goBack();
            }));
        }
    }

    const handleChangeAddress = () => {
        var d = {
            "zone_id": pickupZone,
            "pickup_date": pickupDate,
            "customer_id": customerID,
            "customer_fcs_id": customerFCSId,
            "customer_phone_number": customerPhoneNumber,
            "customer_remark": customerRemark,
            "address_id": pickupAddress.id
        };
        dispatch(setPickupData(d));
        dispatch(changeCustomerPickupAddress({}));
        history.push("/customer-addresses/" + selectedCustomer.id);
    }

    const handleSelectCustomer = () => {
        var value = {
            "zone_id": pickupZone,
            "pickup_date": pickupDate,
            "customer_id": customerID,
            "customer_fcs_id": customerFCSId,
            "customer_phone_number": customerPhoneNumber,
            "customer_remark": customerRemark,
            "address_id": pickupAddress.id
        };

        dispatch(setPickupData(value));
        dispatch(clearSearchCustomers());
        dispatch(changeCustomerPickupAddress({}));
        history.push("/search-customers");
    }

    return (

        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table className={classes.table}>
                <tbody>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Zone'}</Typography>
                                    <NativeSelect
                                        id="pickup-zone-select"
                                        value={pickupZone ? pickupZone : '' || ''}
                                        onChange={handleChangePickupZone}
                                        style={{ width: '200px', border: error ? !pickupZone ? "1px solid red" : "1px solid #ced4da" : "1px solid #ced4da", borderRadius: error ? "4px" : "4px" }}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >Select</option>
                                        {zones.map((e) => {
                                            return <option key={e.id} value={e.id}>{e.name}</option>;
                                        })}
                                    </NativeSelect>
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['zone']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Date'}</Typography>
                                    <NativeSelect
                                        id="pickup-date-select"
                                        value={pickupDate ? pickupDate : '' || ''}
                                        onChange={handleChangePickupDate}
                                        style={{ width: '200px', border: error ? !pickupDate ? "1px solid red" : "1px solid #ced4da" : "1px solid #ced4da", borderRadius: error ? "4px" : "4px" }}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >Select</option>
                                        {zoneDates.map((d) => {
                                            return (<option name={d} value={d} key={d}>{formatDateToLocal(d, false) + '\t' + getDay(d)}</option>);
                                        })}
                                    </NativeSelect>
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['pickupDate']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>

                            <div style={{ marginBottom: '5px' }}>
                                <div>
                                    <FormControl error={error}>
                                        <Box style={{ display: 'inline-flex', marginTop: '5px' }}>
                                            <Typography style={{ color: 'grey', paddingRight: '30px' }}>{'Customer'}</Typography>
                                            <Button size="small" variant="outlined" color="primary" style={{ float: 'left', textTransform: 'none' }}
                                                onClick={handleSelectCustomer}
                                            >Select Customer</Button>
                                        </Box>
                                        {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['customer']}</FormHelperText> : <span />}
                                    </FormControl>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {customerID ? <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'FCS ID'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{customerFCSId ? customerFCSId : ''}</Typography>

                                </Box>
                            </div>
                        </td>
                    </tr> : <tr />}
                    {customerID ? <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Name'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{customerName ? customerName : ''}</Typography>

                                </Box>
                            </div>
                        </td>
                    </tr> : <tr />}
                    {customerID ? <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Phone Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{customerPhoneNumber ? customerPhoneNumber : ''}</Typography>
                                </Box>

                            </div>
                        </td>
                    </tr> : <tr />}
                    {customerID ? <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            {customerFCSId ?
                                <div style={{ marginBottom: '5px' }}>
                                    <div>
                                        <Box style={{ display: 'inline-flex', marginTop: '5px' }}>
                                            <Typography style={{ color: 'grey', paddingRight: '30px' }}>{'Address'}</Typography>
                                            <Button size="small" variant="outlined" color="primary" style={{ float: 'left', textTransform: 'none' }}
                                                onClick={handleChangeAddress}
                                            >Select address</Button>
                                        </Box>
                                    </div>
                                    <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                        <FormControl error={error}>
                                            {pickupAddress.id ? <Paper key={pickupAddress.id} style={{ marginRight: '5px', marginBottom: '5px', width: '280px', height: '165px', border: pickupAddress.id ? '1px solid lightgray' : '1px solid red' }}>
                                                <AddressCard address={pickupAddress}
                                                    editIcon={false}
                                                    deleteIcon={false}
                                                    makeDefaultIcon={false}
                                                />
                                            </Paper> : <span />}
                                            {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['address']}</FormHelperText> : <span />}
                                        </FormControl>

                                    </div>
                                </div> : <div />}
                        </td>
                    </tr> : <tr />}
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Customer Remark'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={customerRemark !== undefined ? customerRemark : '' || ''}
                                    style={{ width: '400px' }}
                                    multiline
                                    rows={3}
                                    onChange={handleRemark}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleRequestPickup}
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Request Pickup"} </Button>
                                </Box>

                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >
    );
}

CreatePickupForCustomer.propTypes = {
    history: PropTypes.object,
    open: PropTypes.bool,
    callback: PropTypes.func
};

export default connect()(CreatePickupForCustomer);
