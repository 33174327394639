
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.primary.main,
        active: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function ReportTableHeader(props) {
    const { classes, order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        if (property === 'index') return;
        console.log('sort => ', property);
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.field_name}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.field_name ? order : false}
                    >
                        {headCell.allow_sort ?
                            <TableSortLabel
                                active={orderBy === headCell.field_name}
                                direction={orderBy === headCell.field_name ? order : 'asc'}
                                onClick={createSortHandler(headCell.field_name)}
                            >
                                {headCell.display_name}
                                {orderBy === headCell.field_name ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                            : <div >{headCell.display_name}</div>
                        }
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ReportTableHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired
};

export default ReportTableHeader;
