import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import theme from '../theme';

class ContactUs extends React.Component {
    render() {
        const styles = {
            root: {
                flexGrow: 1,
            },
            paper: {
                padding: theme.spacing(2),
                textAlign: 'center',
                color: theme.palette.text.secondary,
            },
            button: {
                backgroundColor: theme.palette.primary.main,
            },
            text: {
                fontSize: 16,
                textAlign: 'left',
                color: '#283878'
            },
            lineText: {
                textAlign: 'left',
            },
            container: {
                height: 50,
                backgroundColor: '#5399e7',
                textAlign: "center",
                textColor: '#ffffff'
            },
            icon: {
                color: 'grey',
                fontSize: 40,
                textAlign: "left",
                cursor: "pointer"
            },
            fcsText: {
                color: theme.palette.primary.main,
                textAlign: "left"
            },
            headerText: {
                textAlign: 'left',
                fontWeight: "w500"
            },
            daytime: {
                marginTop: 10,
                marginBottom: 10
            },
            welcomeText: {
                textAlign: "center",
                color: "#283878",
                paddingTop: "165px",
                paddingBottom: "165px",
                height: "20px"
            },
        };
        var setting = this.props.setting;
        return (
            <div style={styles.contact}>
                {/* <MainMenu selectedMenu="3"></MainMenu> */}
                <ThemeProvider theme={theme}>
                    <div style={{ display: "flex", paddingTop: "10px" }}>
                        <Grid container>
                            {/* <Grid item xs={12} sm={7}>
                                <img src={bgImg} className="item-img" alt="logo" style={{ height: "350px", width: "100%" }} />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Container style={{ backgroundColor: "#f2f3fb", height: "350px" }}><Typography style={styles.welcomeText}>WELCOME</Typography></Container>
                            </Grid> */}
                        </Grid>
                    </div>
                    <div style={{ marginTop: "10px" }}></div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h5" style={styles.lineText}>Visit us at social networks!</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <div style={{ textAlign: "left", }}> <FacebookIcon style={styles.icon} onClick={() => window.open(setting['facebook_link'], "_blank")} />
                                <TwitterIcon style={styles.icon} onClick={() => window.open(setting['facebook_link'], "_blank")} /></div>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: "40px" }}></div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h5" style={styles.lineText}>Visit us in person!</Typography>
                            <br />
                            <div style={{ color: "grey", textAlign: "left" }}>We love our customers, so feel free to visit during normal business hours.</div>
                            <div style={{ marginTop: "50px" }}></div>
                            <Typography variant="h5" style={styles.lineText}>FCS TRADING</Typography>
                            <br />
                            <div style={{ color: "grey", textAlign: "left" }}>{setting['usa_address']}</div>
                            <br />
                            <div style={{ color: "grey", textAlign: "left" }}>{setting['usa_contact_number']}</div>
                            <br />
                            <div style={{ color: "grey", textAlign: "left" }}>{setting['email_address']}</div>
                            <div style={{ marginTop: "40px" }}></div>
                            <Typography variant="h5" style={styles.lineText}>Office Hours</Typography>
                            <br />
                            <div style={{ color: "grey", textAlign: "left" }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Mon</td>
                                            <td>10:00 am – 06:00 pm</td>
                                        </tr>
                                        <tr>
                                            <td>Tue</td>
                                            <td>10:00 am – 06:00 pm</td>
                                        </tr>
                                        <tr>
                                            <td>Wed</td>
                                            <td>10:00 am – 06:00 pm</td>
                                        </tr>
                                        <tr>
                                            <td>Thu</td>
                                            <td>10:00 am – 06:00 pm</td>
                                        </tr>
                                        <tr>
                                            <td>Fri</td>
                                            <td>10:00 am – 06:00 pm</td>
                                        </tr>
                                        <tr>
                                            <td>Sat</td>
                                            <td>10:00 am – 06:00 pm</td>
                                        </tr>
                                        <tr>
                                            <td>Sun</td>
                                            <td>Closed</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <br></br>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography style={{ textAlign: "left", fontSize: "12px", color: "grey" }}>COPYRIGHT © 2020 FCS TRADING - ALL RIGHTS RESERVED</Typography>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </div>
        )
    }
}

ContactUs.propTypes = {
    setting: PropTypes.object.isRequired,
}

function mapStateToProps(state, ownProps) {
    const setting = state.app.setting;
    return {
        setting
    };
}

export default connect(mapStateToProps)(ContactUs)