import { Box, Button, Grid, TextField, Typography, Paper, IconButton, Select, InputBase, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import palette from '../../theme/palette';
import AddIcon from '@material-ui/icons/Add';
import FieldCard from './field_card';
import { withStyles } from '@material-ui/styles';
import TemplateFieldDialog from './template_field_dialog';
import { addTemplate, getObjectMeta, getObjects, getTemplateDetail, updateTemplate } from '../../actions/template';

// const fields = [
//     "pickup_date", "pickup_number", "pickup_staff_name", "pickup_staff_id",
//     "zone_id", "zone_name", "customer_id", "customer_name", "customer_fcs_id",
//     "pickup_time_from", "pickup_time_to", "pickup_address"];

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        padding: "10px 20px 10px 20px"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }, button: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: 150,
        height: 55
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    cardBox: {
        width: 200,
        height: 150,
        margin: '5px'
    },
    cardDiv: {
        scrollBehavior: 'smooth',
        display: 'flex'
    }
}));


function TemplateEdit(props) {
    const classes = useStyles();
    const { dispatch, title, history } = props;
    const templateDetail = useSelector(state => state.template.templateDetail);
    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const objects = useSelector(state => state.template.objects);
    const isLogin = useSelector(state => state.app.isLogin);
    const fields = useSelector(state => state.template.objectMeta);

    const [isNew, setIsNew] = React.useState(false);
    const [isNewField, setIsNewField] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [templateName, setTemplateName] = React.useState('');
    const [objectName, setObjectName] = React.useState('');
    const [templateFields, setTemplateFields] = React.useState([]);
    const [selectedField, setSelectedField] = React.useState({});


    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var templateId = pathArr[2];

    useEffect(() => {
        if (isLogin) {
            dispatch(getObjects());
            if (templateId) {
                dispatch(getTemplateDetail(templateId));
            }
        }

    }, [dispatch, templateId, isLogin])


    useEffect(() => {
        if (templateDetail && templateDetail.id && templateDetail.id !== "") {
            setIsNew(false);
            setTemplateFields(templateDetail.fields);
            setTemplateName(templateDetail.name);
            setObjectName(templateDetail.object);
        }else{
            setIsNew(true);
        }
    }, [templateDetail]);

    useEffect(() => {
        if (objectName) {
            dispatch(getObjectMeta(objectName));
        }
    }, [dispatch, objectName])


    const handleTemplateName = (e) => {
        setTemplateName(e.target.value);
    }

    const handleObjectName = (e) => {
        setObjectName(e.target.value);
    }

    const handleBack = () => {
        history.goBack();
    }

    const handleNewField = () => {
        setIsNewField(true);
        setOpen(true);
    }
    const handleClose = (v) => {
        setIsNewField(false);
        setOpen(v);
    }

    const handleSaveNewField = (value, index) => {
        if (isNewField) {
            templateFields.push(value);
        } else {
            templateFields[index] = value;
        }

    }

    const handleEditField = (v, index) => {
        var value = v;
        value.index = index;
        // setIsNew(false);
        setSelectedField(v);
        setOpen(true);
    }

    const handleDeleteField = (value) => {
        var list = [...templateFields];
        for (var i = 0; i < list.length; i++) {
            if (value.field_name === list[i].field_name) {
                list.splice(i, 1);
                console.log("after delete field:", list);
            }
        }
        setTemplateFields(list);
    }

    const handleSaveTemplate = () => {
        var value = {
            "id": templateDetail.id ? templateDetail.id : "",
            "name": templateName,
            "object": objectName,
            "fields": templateFields
        }
        if (isNew) {
            dispatch(addTemplate(value, () => {
                history.goBack();
            }));
        } else {
            dispatch(updateTemplate(value, () => {
                history.goBack();
            }));
        }

    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table className={classes.table}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Name'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={templateName ? templateName : "" || ''}
                                    style={{ width: '250px' }}
                                    onChange={handleTemplateName}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Object'}</Typography>
                                <Select
                                    id="field-select"
                                    value={objectName ? objectName : "" || ''}
                                    onChange={handleObjectName}
                                    style={{ width: '200px' }}
                                    input={<BootstrapInput />}
                                >
                                    {objects ? objects.map((s) => {
                                        return <MenuItem key={s.sn} value={s.name}>{s.name}</MenuItem>
                                    }) : <MenuItem />}
                                </Select>
                            </div>
                        </td>
                    </tr><tr>
                        <td style={{ textAlign: 'left' }}>
                            <div>
                                <Grid container spacing={0} style={{}}>
                                    <Box >
                                        <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Fields'}</Typography>
                                    </Box>
                                    <Box>
                                        <Button size="small" variant="contained" style={{ float: 'left', marginBottom: '10px', marginRight: '10px', textTransform: 'none' }}
                                            onClick={handleNewField}
                                            startIcon={<AddIcon />}
                                        > Add Field</Button>
                                    </Box>
                                </Grid>

                                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>
                                    {templateFields ? templateFields.map((value, index) => {
                                        return <Paper key={index} style={{ marginRight: '5px', marginBottom: '5px', border: value.is_default ? '2px solid green' : '2px solid lightgray' }} >
                                            <FieldCard field={value}
                                                onEdit={(v) => { handleEditField(value, index) }}
                                                onDelete={(v) => { handleDeleteField(v) }}
                                            ></FieldCard>
                                        </Paper>
                                    }) : <span />
                                    }
                                </div>
                                {fields ? <TemplateFieldDialog open={open} fields={fields} data={isNewField ? {} : selectedField}
                                    onClose={(v) => handleClose(v)}
                                    onSave={(v, i) => handleSaveNewField(v, i)}
                                ></TemplateFieldDialog> : <span />}

                            </div>
                        </td>
                    </tr>
                    <tr style={{ textAlign: "left" }}>
                        <td>
                            <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                color="primary"
                                onClick={handleSaveTemplate}
                                startIcon={<SaveIcon />}
                            >{stateIsUpdating ? "Updating..." : "Save"} </Button>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div >
    );
}

TemplateEdit.propTypes = {
    history: PropTypes.object,
    data: PropTypes.object,
    displayFields: PropTypes.array,
    onEdit: PropTypes.func,
    isNew: PropTypes.bool,
    collectionName: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string
};

export default connect()(TemplateEdit);
