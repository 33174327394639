import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

function ConfirmDialog(props) {
  const { msg, confirmBtnLabel, openDialog, onCancel, onContinue, } = props;
  const [open, setOpen] = React.useState(openDialog);

  const handleClose = () => {
    setOpen(false);
    onCancel(false);
  };

  const handleContinue = () => {
    setOpen(false);
    onContinue(true);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><Typography>{msg}</Typography></DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleContinue} color="primary" autoFocus>
            {confirmBtnLabel}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

ConfirmDialog.propTypes = {
  history: PropTypes.object,
  type: PropTypes.string,
  itemName: PropTypes.string,
  openDialog: PropTypes.bool,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  onCancelMakeDefault: PropTypes.func,
  onContinueMakeDefault: PropTypes.func,
  isDefault: PropTypes.bool
};

export default ConfirmDialog;
