import { makeStyles } from '@material-ui/styles';
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { connect,useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import firebase from "../../firebase_config";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    marginTop: '30px'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
}));

const LoginPhoneNumber = (props) => {
  const classes = useStyles();
  const countries = useSelector(state => state.app.countries);
  const defalutCountry = useSelector(state => state.app.defalutCountry);

  const uiConfig = {
    'callbacks': {
      'signInSuccess': function (user, credential, redirectUrl) {
        handleSignedInUser(user);
        return false;
      }
    },
    'signInFlow': 'popup',
    'signInOptions': [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        recaptchaParameters: {
          type: 'image',
          size: 'invisible',
          badge: 'bottomleft'
        },
        defaultCountry: defalutCountry,
        // defaultNationalNumber: '1234567890',
        // loginHint: '+11234567890'
        whitelistedCountries: countries
      }
    ],
    'tosUrl': '/'
  };

  const handleSignedInUser = (user) => {
    window.location.href = '/';
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    </div>
  );
};

export default withRouter(connect()(LoginPhoneNumber));
