import firebase from "../firebase_config";
import { config } from "../firebase_config";
import 'firebase/firestore';

export const TRACK_PACKAGE = "TRACK_PACKAGE";
export const LOADING_STARTED = "LOADING_STARTED";
export const LOADING_ENDED = "LOADING_ENDED";

const MAX_TRACKING_IDS = 30;

export const trackPackages = (ids) => async (dispatch, getState) => {
  console.log('trackpackeages');
  if (!ids || ids.trim() === "") return;
  if (ids.includes(",")) {
    if (ids.split(",").length > MAX_TRACKING_IDS) {
      alert("Exceed more than " + MAX_TRACKING_IDS);
      return;
    }
  }
  if (ids.includes("\n")) {
    if (ids.split("\n").length > MAX_TRACKING_IDS) {
      alert("Exceed more than " + MAX_TRACKING_IDS);
      return;
    }
  }

  var idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
  if (!idTokenResult || !idTokenResult.token) return;
  const token = idTokenResult.token;
  dispatch({
    type: LOADING_STARTED,
  });
  fetch(config.fcsUrl + "/track/" + btoa(encodeURIComponent(ids)), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Token': token,
    },
  })
    .then(response => response.json())
    .then((result) => {
      var packages = [];
      if (result && result.data) {
        result.data.forEach(function (p) {
          packages.push(p);
        });
      }
      dispatch({
        type: TRACK_PACKAGE,
        payload: {
          packages
        }
      });
    }).finally(() => {
      dispatch({
        type: LOADING_ENDED,
      });
    });
}