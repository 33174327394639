import {
  LOADING_ENDED, LOADING_STARTED, TRACK_PACKAGE
} from "../actions/tracking";


const initialState = {
  packages: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TRACK_PACKAGE: {
      const list = action.payload.packages;
      return {
        ...state,
        packages: list,
      };
    }
    case LOADING_STARTED: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOADING_ENDED: {
      return {
        ...state,
        isLoading: false,
      };
    }   
    default:
      return state;
  }
}
