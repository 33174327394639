
import { Box, IconButton, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { changeRowPerPage } from '../../actions/app';
import { changeExploreOrder, changeExplorePage, clearSelectedUser, getExploreData, getSelectedUser, setFilters } from '../../actions/explore';
import theme from '../../theme';
import { formatDateToLocal } from '../../util';
import EnhancedTableHeader from '../enhanced_table_header';
import ExploreToolbar from './explore_toolbar';
import BackIcon from '@material-ui/icons/ArrowBack';

function getUpdatedDate(p) {
    var date = new Date(p['update_time']);
    return formatDateToLocal(date);
}

function getReceivedDate(p) {
    var statusDate = p['received_date'];
    var convertDate = new Date(statusDate);
    var str = formatDateToLocal(convertDate);
    return str;
}

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const headCells = [
    { id: 'sn', numeric: true, label: 'No.' },
    { id: 'tracking_id', numeric: false, label: 'Tracking Id', sortable: true },
    { id: 'received_date', numeric: false, label: 'Received Date', sortable: true },
    { id: 'updated_date', numeric: false, label: 'Updated Date', sortable: true },
    { id: 'status', numeric: false, label: 'Status', sortable: true },
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '30px'
    },
    paper: {
        // width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 800,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    }
}));

function ExplorePackageList(props) {
    const { title, dispatch, history } = props;
    const classes = useStyles();
    const packages = useSelector(state => state.explore.packages);
    const count = useSelector(state => state.explore.count);
    const rowsPerPage = useSelector(state => state.app.rowsPerPage);
    const page = useSelector(state => state.explore.page);
    const order = useSelector(state => state.explore.order);
    const selectUser = useSelector(state => state.explore.user);
    const isLogin = useSelector(state => state.app.isLogin);

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var _page = pathArr[1];
    var userId = pathArr[2];

    useEffect(() => {
        if (isLogin) {
            if (_page === "user-packages") {
                dispatch(changeExplorePage(0));
                dispatch(setFilters([]));
                dispatch(getSelectedUser(userId));
            } else {
                dispatch(clearSelectedUser({}));
                dispatch(getExploreData());
            }
        }
    }, [_page, userId, isLogin, dispatch]);

    const handleRequestSort = (event, property) => {
        const isAsc = order['field_name'] === property && order['direction'] === 'asc';
        var _order = { "field_name": property, "direction": isAsc ? 'desc' : 'asc' };
        dispatch(changeExplorePage(0));
        dispatch(getExploreData([], _order));
    };

    const handleClick = (event, id) => {
        event.preventDefault();
        var pathName = '/package-detail/' + id;
        history.push(pathName);
    };

    const handleChangePage = (event, newPage) => {
        dispatch(changeExplorePage(newPage));
        dispatch(getExploreData());
    };

    const handleChangeRowsPerPage = (event) => {
        var rowPerPage = parseInt(event.target.value, 10);
        dispatch(changeExplorePage(0));
        dispatch(changeRowPerPage(rowPerPage));
        dispatch(getExploreData([]));
    };

    const handleBack = () => {
        history.goBack();
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>

                <Paper className={classes.paper}>
                    {selectUser.user_name !== undefined ?
                        <Box display="flex" p={1} bgcolor="background.paper">
                            <Box display="flex" p={1} bgcolor="background.paper">
                                <IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton>
                            </Box>
                            <Box flexGrow={1}>
                                <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{"Customer Packages"}</Typography>
                            </Box>
                        </Box>
                        : <span />}
                    <ExploreToolbar
                        dispatch={dispatch}
                        selectUser={selectUser}
                        title={title}
                        {...props}
                    />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'small'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHeader
                                classes={classes}
                                order={order.direction}
                                orderBy={order.field_name}
                                onRequestSort={handleRequestSort}
                                rowCount={count}
                                headCells={headCells}
                            />
                            <TableBody>
                                {packages.map((p, index) => {
                                    return (
                                        <StyledTableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={p['id']}
                                        >
                                            <TableCell align="center" style={{ width: 50 }}  >{p['sn']}</TableCell>
                                            <TableCell align="left" style={{ width: 340 }} className={classes.trackingID} onClick={(event) => handleClick(event, p['id'])}>{p['tracking_id']}</TableCell>
                                            <TableCell align="left" style={{ width: 180 }}>{getReceivedDate(p)}</TableCell>
                                            <TableCell align="left" style={{ width: 180 }}>{getUpdatedDate(p)}</TableCell>
                                            <TableCell align="left" style={{ width: 50 }}>{p['status']}</TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`}
                    />
                </Paper>
            </div>
        </ThemeProvider>
    );
}

ExplorePackageList.propTypes = {
    history: PropTypes.object,
    title: PropTypes.string
};

export default connect()(ExplorePackageList);
