
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BackIcon from '@material-ui/icons/ArrowBack';
import ConfirmDialog from '../confirm_dialog';
import LocalOverlay from '../local_overlay';
import { connect, useSelector } from 'react-redux';
import { getSMSRates, addSMSRate, deleteSMSRate, updateSMSRate } from '../../actions/sms';
import { showErr } from '../../actions/app';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px',
        backgroundColor: "white"
    },
    paper: {
        width: '100%',
        height: '400px',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 800,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    },
    listItemSelected: {
        padding: '10px',
        color: theme.palette.primary.main,
        textTransform: 'none',
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    listItem: {
        padding: '10px',
        color: "grey",
        textTransform: 'none',
        cursor: 'pointer'
    },
    listItemHeader: {
        padding: '10px 15px 10px 15px',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginBottom: '10px'
    },
}));

function RatePage(props) {
    const classes = useStyles();
    const { dispatch, history } = props;
    const [open, setOpen] = React.useState(false);
    const [showDeleteZoneConfirm, setShowDeleteRateConfirm] = React.useState(false);
    const [loading, setIsLoading] = useState(false);
    const [country, setCountry] = React.useState();
    const [countryCode, setCountryCode] = React.useState();
    const [rate, setRate] = React.useState(0.0);
    const [smsRate, setSmsRate] = React.useState(null);

    const rates = useSelector(state => state.sms.rates);
    const _privileges = useSelector(state => state.app.privileges);

    useEffect(() => {
        dispatch(getSMSRates());
    }, [dispatch]);

    const handleClose = () => {
        setOpen(false);
    };

    const getData = () => {
        var _rate = parseFloat(rate);
        if (!_rate || isNaN(_rate)) {
            dispatch(showErr("Invalid rate"));
            return null;
        }
        return { id: smsRate && smsRate.id ? smsRate.id : null, "country": country, "country_code": countryCode, "rate": _rate };
    }

    const handleSaveRate = () => {
        var data = getData();
        if (data == null) return;
        setIsLoading(true);
        if (smsRate && smsRate['id']) {
            dispatch(updateSMSRate(data, () => {
                setIsLoading(false);
                setOpen(false);
            }));
        } else {
            dispatch(addSMSRate(data, () => {
                setIsLoading(false);
                setOpen(false);
            }));
        }
    }

    const handelAddRateDialog = () => {
        setSmsRate(null);
        setCountry();
        setCountryCode();
        setRate(0.0);
        setOpen(true);
    }

    const handleDeleteRateConfirmDialog = (r) => {
        setSmsRate(r);
        setShowDeleteRateConfirm(true);
    }

    const handleDeleteRate = (v) => {
        setShowDeleteRateConfirm(false);
        dispatch(deleteSMSRate(smsRate));
        setSmsRate(null);
    }

    const handleRateEdit = (e, r) => {
        e.preventDefault();
        setSmsRate(r);
        setCountry(r.country);
        setCountryCode(r.country_code);
        setRate(r.rate);
        setOpen(true);
    }

    const handleOnChangeCountry = (e) => {
        setCountry(e.target.value);
    }

    const handleOnChangeCountryCode = (e) => {
        setCountryCode(e.target.value);
    }

    const handleOnChangeRate = (e) => {
        setRate(e.target.value);
    }

    const handleBack = () => {
        history.goBack();
    }

    const hasSaPrivilege = () => {
        return _privileges.includes('sa');
    }

    var index = 0;

    return (
        <LocalOverlay isActive={loading}>
            <div className={classes.root}>
                <Grid container style={{ padding: '10px' }} spacing={2}>
                    <Grid item xs={12} sm={9}>
                        <Box display="flex" >
                            <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>

                        </Box>
                        {/* <Paper> */}
                        <CardContent>
                            <div style={{ height: 'auto' }}>
                                <div style={{ textAlign: 'start' }}>
                                    {hasSaPrivilege() ? <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<AddIcon />}
                                        style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        onClick={handelAddRateDialog}>
                                        Add SMS Rate
                                    </Button> : <span />}
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"SMS Rate"}</DialogTitle>
                                        <DialogContent>
                                            <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                                                <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Country'}</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    size={"small"}
                                                    value={country ? country : '' || ''}
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => handleOnChangeCountry(e)}
                                                />

                                            </div>
                                            <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                                                <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Country Code'}</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    size={"small"}
                                                    value={countryCode ? countryCode : '' || ''}
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => handleOnChangeCountryCode(e)}
                                                />

                                            </div>
                                            <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                                                <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Rate'}</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    size={"small"}
                                                    value={rate ? rate : '' || ''}
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => handleOnChangeRate(e)}
                                                />

                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose} color="primary">
                                                Cancel
                                            </Button>
                                            <Button onClick={handleSaveRate} color="primary" autoFocus>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                                <div style={{ textAlign: 'start' }}>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Country</TableCell>
                                                    <TableCell>Country Code</TableCell>
                                                    <TableCell>Rate</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rates.map((rate) => (<TableRow key={index}>
                                                    <TableCell>{++index}</TableCell>
                                                    <TableCell>{rate['country']}</TableCell>
                                                    <TableCell>{rate['country_code']}</TableCell>
                                                    <TableCell>{rate['rate']}</TableCell>
                                                    {hasSaPrivilege() ? <TableCell width="60px">
                                                        <IconButton color="primary" size="small"
                                                            onClick={(e) => handleRateEdit(e, rate)}
                                                        ><EditIcon /></IconButton>
                                                    </TableCell> : <TableCell />}
                                                    {hasSaPrivilege() ? <TableCell>
                                                        <IconButton color="primary" size="small"
                                                            onClick={() => handleDeleteRateConfirmDialog(rate)}
                                                        ><DeleteIcon /></IconButton>
                                                    </TableCell> : <TableCell />}
                                                </TableRow>))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {showDeleteZoneConfirm ? <ConfirmDialog
                                        msg={'Delete this rate "' + smsRate.country + '(' + smsRate.country_code + ')"?'}
                                        openDialog={showDeleteZoneConfirm}
                                        confirmBtnLabel={"Delete"}
                                        onCancel={(v) => setShowDeleteRateConfirm(false)}
                                        onContinue={(v) => handleDeleteRate(v)}></ConfirmDialog> : <div />}
                                </div>
                            </div>
                        </CardContent>
                        {/* </Paper> */}
                    </Grid>
                </Grid>

            </div>
        </LocalOverlay >
    );
}

export default connect()(RatePage);