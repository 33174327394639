import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Box, makeStyles, NativeSelect, TextField, Grid, InputBase, withStyles, FormControl, FormHelperText } from '@material-ui/core';
import theme from '../../theme';
import { connect, useSelector } from 'react-redux';
import { convertStringToDate, formatDateToLocal, getDay, getTimeFormDate, removeFCSString } from '../../util';
import { confirmPickupRequest, getPickupDetail } from '../../actions/pickup';
import { getStaffsData } from '../../actions/user';
import { getPickupDates } from '../../actions/app';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden'
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    table: {
        padding: "10px 20px 10px 20px"
    },
}));

function ConfirmPickupForm(props) {
    const classes = useStyles();
    const { title, history, dispatch } = props;
    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const dates = useSelector(state => state.app.pickupDates);
    const selector = useSelector(state => state.pickup);
    const [data, setData] = useState(selector.pickupDetail);
    const staffs = useSelector(state => state.user.staffs);
    const [zoneDates, setZoneDates] = useState([]);

    const [pickupDate, setPickupDate] = useState(new Date());
    var start = new Date();
    start.setHours(20, 0, 0, 0);
    const [fromTime, setFromTime] = useState(start);
    var end = new Date();
    end.setHours(0, 0, 0, 0);
    const [toTime, setToTime] = useState(end);
    const [staff, setStaff] = useState();
    const [confirmRemark, setConfirmRemark] = useState('');

    const [error, setError] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var pickupId = pathArr[2];

    useEffect(() => {
        dispatch(getStaffsData());
        dispatch(getPickupDetail(pickupId));
        dispatch(getPickupDates());
    }, [dispatch, pickupId]);

    useEffect(() => {
        setData(selector.pickupDetail);
    }, [selector]);

    useEffect(() => {
        if (data.id !== undefined) {
            setConfirmRemark(data.confirm_remark);
            setPickupDate((data.pickup_date).toDate());
        }

    }, [data]);

    useEffect(() => {
        if (data.zone_id !== undefined) {
            var _dates = [];
            dates.forEach(d => {
                for (var i = 0; i < d.zones.length; i++) {
                    if (data.zone_id === d.zones[i].id) {
                        var dbDate = convertStringToDate(d.id);
                        var compareDate = new Date(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate());

                        var today = new Date();
                        today.setHours(0, 0, 0, 0);

                        if (compareDate >= today) {
                            _dates.push(dbDate);
                        }
                    }
                }
            });
            setZoneDates(_dates);
        }
    }, [data, dates]);

    const handleConfirmRemark = (e) => {
        setConfirmRemark(e.target.value);
    }

    const handleFromTime = (e) => {
        var time = e.target.value;
        var date = formatDateToLocal(fromTime, false);
        var localDate = new Date(date + ' ' + time);

        setFromTime(localDate);
    }

    const handleToTime = (e) => {
        var time = e.target.value;
        var date = formatDateToLocal(toTime, false);
        var localDate = new Date(date + ' ' + time);

        setToTime(localDate);
    }

    const handleChangeStaff = (e) => {
        setStaff(e.target.value);
    }

    const handleBack = () => {
        history.goBack();
    }

    const handleSaveConfirmed = () => {
        let isError = false;
        var errorMsg = [...errorMessages];
        if (!staff) {
            isError = true;
            setError(true);
            errorMsg['staff'] = "Staff is required";
            setErrorMessages(errorMsg);
        }

        if (!pickupDate) {
            isError = true;
            setError(true);
            errorMsg['pickupDate'] = "Pickup date is required";
            setErrorMessages(errorMsg);
        }

        if (!isError) {
            setError(false);
            setErrorMessages([]);
            var value = {
                "id": data.id,
                "pickup_date": pickupDate.toISOString(),
                "pickup_time_from": fromTime.toISOString(),
                "pickup_time_to": toTime.toISOString(),
                "pickup_staff_id": staff,
                "remark": confirmRemark
            };

            dispatch(confirmPickupRequest(value, () => {
                history.goBack();
            }))
        }

    }

    const handleSaveRescheduled = () => {
        history.push('/reschedule-pickup-form');
    }

    const handleChangePickupDate = (e) => {
        var date = e.target.value;
        setPickupDate(new Date(date));
    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                <Box><Button color="primary" onClick={handleBack} ><BackIcon /></Button></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table className={classes.table}>
                <tbody>
                    <tr style={{ height: '50px' }}>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{data.pickup_number !== undefined ? data.pickup_number : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{data.status !== undefined ? data.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>

                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Zone'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{data.zone_name !== undefined ? data.zone_name : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Date'}</Typography>
                                    <NativeSelect
                                        id="pickup-date-select"
                                        value={pickupDate ? pickupDate : '' || ''}
                                        onChange={handleChangePickupDate}
                                        style={{ width: '200px', border: error ? !pickupDate ? "1px solid red" : "1px solid #ced4da" : "1px solid #ced4da", borderRadius: error ? "4px" : "4px" }}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >Select</option>
                                        {zoneDates.map((d) => {
                                            return (<option name={d} value={d} key={d}>{formatDateToLocal(d, false) + '\t' + getDay(d)}</option>);
                                        })}
                                    </NativeSelect>
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['pickupDate']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{data.customer_fcs_id !== undefined ? removeFCSString(data.customer_fcs_id) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{data.customer_name !== undefined ? data.customer_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', marginBottom: '5px', color: 'grey', textAlign: 'left' }}>{'Pickup Time'}</Typography>
                                <div style={{ marginBottom: '5px' }}>
                                    <TextField
                                        id="time"
                                        label="From"
                                        type="time"
                                        size={"small"}
                                        variant="outlined"
                                        value={fromTime !== '' ? getTimeFormDate(fromTime, false) : "" || "09:00"}
                                        onChange={handleFromTime}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}

                                        style={{ paddingRight: '30px' }}
                                    />
                                    <TextField
                                        id="time"
                                        label="To"
                                        type="time"
                                        size={"small"}
                                        variant="outlined"
                                        value={toTime !== '' ? getTimeFormDate(toTime, false) : "" || "12:00"}
                                        onChange={handleToTime}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                    />

                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Staff'}</Typography>
                                    <NativeSelect
                                        id="staff-select"
                                        value={staff ? staff : ""}
                                        onChange={handleChangeStaff}
                                        style={{ width: '200px', border: error ? !staff ? "1px solid red" : "1px solid #ced4da" : "1px solid #ced4da", borderRadius: error ? "4px" : "4px" }}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >Select</option>
                                        {staffs.map((s) => {
                                            return <option key={s.id} value={s.id}>{s.user_name}</option>
                                        })}
                                    </NativeSelect>
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['staff']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Remark'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={confirmRemark ? confirmRemark : ""}
                                    multiline
                                    rows={3}
                                    style={{ width: '400px' }}
                                    onChange={(e) => handleConfirmRemark(e)}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                {data.status === "requested" ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        onClick={handleSaveConfirmed}
                                        color="primary"
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Confirm pickup"} </Button>
                                </Box> : <span />}

                                {data.status === "confirmed" ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        onClick={handleSaveRescheduled}
                                        color="primary"
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Reschedule pickup"} </Button>
                                </Box> : <span />}

                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >
    );
}

ConfirmPickupForm.propTypes = {
    history: PropTypes.object,
    open: PropTypes.bool,
    callback: PropTypes.func
};

export default connect()(ConfirmPickupForm);
