import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import DirectionsIcon from '@material-ui/icons/Directions';

const DisplayMapDirection = (props) => {
    const { waypointOrder, routeLegs, routeCoods } = props;

    const getCustomer = (p) => {
        return (p && p.pickup.pickup_address.full_name) ?? "";
    }

    const getPhoneNumber = (p) => {
        return (p && p.pickup.pickup_address.phone_number) ?? "";
    }
    const getPickupNumber = (p) => {
        return (p && p.pickup.pickup_number) ?? "";
    }

    return (

        routeLegs.map((rl, index) => {
            var from = index + 1;
            var to = index + 2;

            var order = waypointOrder[index];
            var href = "https://www.google.com/maps/dir/" + rl.start_location.lat() + "," + rl.start_location.lng() + "/" + rl.end_location.lat() + "," + rl.end_location.lng();
            return <Grid style={{ marginBottom: "20px", textAlign: "left", borderBottom: ((routeLegs.length - 1) !== index) ? "1px solid lightgrey" : "" }} key={index}>
                <Grid container style={{ display: "flex" }}>
                    <Grid item xs={3} ><Typography style={{ color: "grey" }}>From ({(from + 9).toString(36).toUpperCase()})</Typography></Grid>
                    <Grid item xs={9}>{rl.start_address}</Grid>
                </Grid>
                <Grid style={{ display: "flex" }}>
                    <Grid item xs={3} ><Typography style={{ color: "grey" }}>To ({(to + 9).toString(36).toUpperCase()})</Typography></Grid>
                    <Grid item xs={9} >{rl.end_address}</Grid>
                </Grid>
                <Grid style={{ display: "flex" }}>
                    <Grid item xs={3} ><Box></Box></Grid>
                    <Grid item xs={9} ><Button variant="text" color="primary" href={href} target="_blank"
                        style={{ textTransform: "none" }}
                        startIcon={<DirectionsIcon />}>Direction</Button></Grid>
                </Grid>
                {order !== undefined ? <Grid style={{ display: "flex" }}>
                    <Grid item xs={3} ><Typography style={{ color: "grey" }}>Pickup number</Typography></Grid>
                    <Grid item xs={9} > <Box>{getPickupNumber(routeCoods[order])}</Box></Grid>
                </Grid> : <Grid />}
                {order !== undefined ? <Grid style={{ display: "flex" }}>
                    <Grid item xs={3} > <Typography style={{ color: "grey" }}>Customer</Typography></Grid>
                    <Grid item xs={9} > <Box>{getCustomer(routeCoods[order])}</Box></Grid>
                </Grid> : <Grid />}
                {order !== undefined ? <Grid style={{ display: "flex", marginBottom: "10px" }}>
                    <Grid item xs={3} > <Typography style={{ color: "grey" }}>Phone number</Typography></Grid>
                    <Grid item xs={9} >  <Box>{getPhoneNumber(routeCoods[order])}</Box></Grid>
                </Grid> : <Grid />}

            </Grid >;
        })


    );

}

export default DisplayMapDirection;