import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box, Button, Grid, InputBase, makeStyles, MenuItem, Select,
    TextField, Typography, withStyles, FormControlLabel, Checkbox
} from '@material-ui/core';
import theme from '../theme';
import SaveIcon from '@material-ui/icons/Save';
import { useSelector } from 'react-redux';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px',
        backgroundColor: 'white'
    },
    paper: {
        width: '100%',
        height: '400px',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 800,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    },
    listItemSelected: {
        padding: '10px 15px 10px 15px',
        color: theme.palette.primary.main,
        textTransform: 'none',
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    listItem: {
        padding: '10px 15px 10px 15px',
        color: "grey",
        textTransform: 'none',
        cursor: 'pointer'
    },
    listItemHeader: {
        padding: '10px 15px 10px 15px',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginBottom: '10px'
    },
}));

function EmailForm(props) {
    var classes = useStyles();
    const { formName, title, subject = '', message = '', sms = '',
        valueList = [], onSave, smallScreen, enableSendEmail, enableSendSms } = props;
    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const [data, setData] = useState({ "enable_send_email": false, "enable_send_sms": false });
    const [cursorStart, setCursorStart] = useState(0);
    const [cursorEnd, setCursorEnd] = useState(0);
    const [smsCursorStart, setSMSCursorStart] = useState(0);
    const [smsCursorEnd, setSMSCursorEnd] = useState(0);
    const [targetId, setTargetId] = useState();

    useEffect(() => {
        setData({
            "subject": subject, "message": message, "sms": sms,
            "enable_send_email": enableSendEmail, "enable_send_sms": enableSendSms
        });
    }, [subject, message, sms, enableSendEmail, enableSendSms]);

    const handleChangeField = (e) => {
        if (targetId === "txt_message") {
            const newValue = data['message'].substring(0, cursorStart) + ' %' + e.target.value + '% ' + data['message'].substring(cursorEnd);

            setData({ ...data, 'message': newValue });
            setCursorStart(cursorStart + 1);
            setCursorEnd(cursorStart + 1);
        } else {
            const newValue = data['sms'].substring(0, smsCursorStart) + ' %' + e.target.value + '% ' + data['sms'].substring(smsCursorEnd);

            setData({ ...data, 'sms': newValue });
            setSMSCursorStart(smsCursorStart + 1);
            setSMSCursorEnd(smsCursorStart + 1);
        }

    }

    const handleSubject = (e) => {
        setTargetId(e.target.id);
        setData({ ...data, 'subject': e.target.value });
    }

    const handleMessage = (e) => {
        setTargetId(e.target.id);
        setData({ ...data, 'message': e.target.value });
    }

    const handleSMSMessage = (e) => {
        setData({ ...data, 'sms': e.target.value });
    }

    const handleCursorPosition = (e) => {
        var start = e.target.selectionStart;
        var end = e.target.selectionEnd;
        setCursorStart(start);
        setCursorEnd(end);
    }

    const handleSMSCursorPosition = (e) => {
        var start = e.target.selectionStart;
        var end = e.target.selectionEnd;
        setSMSCursorStart(start);
        setSMSCursorEnd(end);
    }

    const handelSave = () => {
        onSave({
            "id": formName, "subject": data.subject, "message": data.message, "sms": data.sms,
            "enable_send_email": data.enable_send_email, "enable_send_sms": data.enable_send_sms
        });
    }
    const handleEnableEmailChange = (e) => {
        setData({ ...data, 'enable_send_email': e.target.checked });
    };
    const handleEnableSmsChange = (e) => {
        setData({ ...data, 'enable_send_sms': e.target.checked });
    };

    return <div>
        <div>
            <Typography className={classes.listItemHeader} style={{ color: theme.palette.primary.main, textAlign: 'center', paddingTop: '8px', fontSize: '16px' }}> {title}</Typography>
        </div>
        <FormControlLabel control={<Checkbox checked={data.enable_send_email} name="email" onChange={handleEnableEmailChange} />} label="Enable Send Email" />
        <FormControlLabel control={<Checkbox checked={data.enable_send_sms} name="sms" onChange={handleEnableSmsChange} />} label="Enable Send SMS" />
        <Grid container>
            <Grid item xs={12} sm={7}>
                <div style={{ marginBottom: '5px' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left', width: '100px', }}>{'Subject'}</Typography>
                    <TextField
                        disabled={!data.enable_send_email}
                        variant="outlined"
                        autoComplete="off"
                        size={"small"}
                        value={data !== undefined ? data.subject || '' : ''}
                        style={{ width: smallScreen ? "" : '500px' }}
                        fullWidth={smallScreen ? true : false}
                        onChange={(e) => handleSubject(e)}
                    />
                </div>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} sm={7}>
                <div style={{ marginBottom: '5px' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left', width: '100px' }}>{'Message'}</Typography>
                    <TextField
                        disabled={!data.enable_send_email}
                        id="txt_message"
                        variant="outlined"
                        autoComplete="off"
                        size={"small"}
                        placeholder=""
                        multiline
                        rows={23}
                        value={data !== undefined ? data.message || '' : ''}
                        style={{ width: smallScreen ? "" : '500px' }}
                        fullWidth={smallScreen ? true : false}
                        onChange={(e) => handleMessage(e)}
                        onClick={(e) => handleCursorPosition(e)}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={4}>
                <div style={{ marginBottom: '5px' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left', width: '100px' }}>{'Variables'}</Typography>
                    <Select
                        onChange={handleChangeField}
                        style={{ width: smallScreen ? "" : '170px' }}
                        fullWidth={smallScreen ? true : false}
                        value={''}
                        input={<BootstrapInput />}>

                        {valueList ? valueList.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>) : <MenuItem key={'none'} ></MenuItem>}
                    </Select>
                </div>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={7}>
            <div style={{ marginBottom: '5px' }}>
                <Typography style={{ color: 'grey', textAlign: 'left', width: '100px' }}>{'SMS'}</Typography>
                <TextField
                    disabled={!data.enable_send_sms}
                    id="txt_sms"
                    variant="outlined"
                    autoComplete="off"
                    size={"small"}
                    placeholder=""
                    multiline
                    rows={5}
                    value={data !== undefined ? data.sms || '' : ''}
                    style={{ width: smallScreen ? "" : '500px' }}
                    fullWidth={smallScreen ? true : false}
                    onChange={(e) => handleSMSMessage(e)}
                    onClick={(e) => handleSMSCursorPosition(e)}
                />
            </div>
        </Grid>
        <div style={{ marginBottom: '5px' }}>
            <span />
            <Box>
                <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }} color="primary"
                    onClick={handelSave}
                    startIcon={<SaveIcon />}
                >{stateIsUpdating ? "Updating ..." : "Save"} </Button>
            </Box>
        </div>
    </div>;
}

EmailForm.propTypes = {
    formName: PropTypes.string,
    title: PropTypes.string,
    subject: PropTypes.string,
    message: PropTypes.string,
    sms: PropTypes.string,
    valueList: PropTypes.any,
    onSave: PropTypes.func
}

export default EmailForm;