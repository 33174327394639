import {
    Box,
    Button, Dialog,
    DialogActions, Grid,
    IconButton,
    TextField, Toolbar, Tooltip, Typography
} from "@material-ui/core";
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { lighten, makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { changeExplorePage, getExploreData } from '../../actions/explore';
import theme from '../../theme';
import { removeFCSString } from "../../util";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    btn: {
        color: theme.palette.primary.main,
    }
}));

const styles = {
    root: {
        flexGrow: 1,
    },
    button: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: 150,
        height: 55
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
};

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const ExploreToolbar = (props) => {
    const classes = useToolbarStyles();
    const { dispatch, selectUser } = props;
    const explore = useSelector(state => state.explore);
    const [openDialog, setOpenDialog] = React.useState(false);

    const [trackingId, setTrackingId] = React.useState(explore.filters.tracking_id);
    const [fromReceivedDate, setFromReceivedDate] = React.useState('');
    const [toReceivedDate, setToReceivedDate] = React.useState('');
    const [fromUpdatedDate, setFromUpdatedDate] = React.useState('');
    const [toUpdatedDate, setToUpdatedDate] = React.useState('');

    const handleClose = (e) => {
        setOpenDialog(false);
    };

    const handleDialog = (e) => {
        setOpenDialog(true);
    };

    const handleApply = (e) => {
        var f = [];
        f.push({ 'key': 'tracking_id', 'value': trackingId });
        f.push({ 'key': 'from_received_date', 'value': fromReceivedDate });
        f.push({ 'key': 'to_received_date', 'value': toReceivedDate });
        f.push({ 'key': 'from_updated_date', 'value': fromUpdatedDate });
        f.push({ 'key': 'to_updated_date', 'value': toUpdatedDate });

        dispatch(changeExplorePage(0));

        dispatch(getExploreData(f));
        setOpenDialog(false);
    };

    const handleClear = (e) => {
        dispatch(changeExplorePage(0));

        setFromReceivedDate("");
        setToReceivedDate("");
        setFromUpdatedDate("");
        setToUpdatedDate("");
        setTrackingId("");

        dispatch(getExploreData([]));
        setOpenDialog(false);
    };

    const handleTrackingId = (e) => {
        console.log('value => ', e.target.value);
        setTrackingId(e.target.value);
    };

    const handleFromReceivedDate = (e) => {
        setFromReceivedDate(e.target.value);
    };

    const handleToReceivedDate = (e) => {
        setToReceivedDate(e.target.value);
    };

    const handleFromUpdatedDate = (e) => {
        setFromUpdatedDate(e.target.value);
    };

    const handleToUpdatedDate = (e) => {
        setToUpdatedDate(e.target.value);
    };


    return (
        <ThemeProvider theme={theme}>
            <Toolbar
                className={clsx(classes.root)}
            >

                <Box textAlign="left" fontWeight="fontWeightBold" flexGrow={1}>
                    <Typography variant="h6" id="tableTitle" component="div" color="primary">
                    </Typography>
                    {selectUser.user_name !== undefined ?
                        <div>
                            <div style={{ display: 'flex', marginBottom: '5px' }}>
                                <Box style={{ width: '100px' }}><Typography style={{ color: 'grey', textAlign: 'left' }}>{'FCS ID'}</Typography></Box>
                                <Box display="flex">
                                    <Typography style={{ color: 'black', textAlign: 'left' }}>{removeFCSString(selectUser.fcs_id)}</Typography>
                                </Box>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Box style={{ width: '100px' }}><Typography style={{ color: 'grey', textAlign: 'left' }}>{'Name'}</Typography></Box>
                                <Box display="flex">
                                    <Typography style={{ color: 'black', textAlign: 'left' }}>{selectUser.user_name}</Typography>
                                </Box>
                            </div>
                        </div> : <span />}
                </Box>

                <div style={{ display: "flex" }}>
                    <Tooltip title="Download list">
                        <IconButton aria-label="download list">
                            <GetAppIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Filter list">
                        <IconButton aria-label="filter list" onClick={(e) => handleDialog(e)}>
                            <FilterListIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Dialog maxWidth="lg" aria-labelledby="customized-dialog-title" open={openDialog}>
                        <DialogTitle id="customized-dialog-title" onClose={(e) => handleClose(e)} >
                            Filters
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid item xs={12}>
                                <Grid>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box style={{ width: '130px' }}>
                                            <Typography style={{ paddingRight: '30px' }}>Tracking id</Typography>
                                        </Box>

                                        <TextField
                                            id="txt-tracking-id"
                                            value={trackingId || ""}
                                            variant="outlined"
                                            size={"small"}
                                            onChange={(e) => handleTrackingId(e)} />
                                    </div>
                                </Grid>
                                <br />
                                <Grid >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box style={{ width: '130px' }}>
                                            <Typography style={{ paddingRight: '30px' }}>Received date</Typography>
                                        </Box>
                                        <div>
                                            <Typography>From</Typography>
                                            <TextField type="date"
                                                id="from-receiced-date"
                                                variant="outlined"
                                                size={"small"}
                                                value={fromReceivedDate}
                                                onChange={(e) => handleFromReceivedDate(e)} />
                                        </div>
                                        <Box style={{ width: '10px' }}></Box>
                                        <div>
                                            <Typography>To</Typography>
                                            <TextField type="date"
                                                id="to-receiced-date"
                                                variant="outlined"
                                                size={"small"}
                                                value={toReceivedDate}
                                                onChange={(e) => handleToReceivedDate(e)} />
                                        </div>

                                    </div>
                                </Grid>
                                <br />
                                <Grid >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box style={{ width: '130px' }}><Typography style={{ paddingRight: '30px' }}>Updated date</Typography></Box>
                                        <div>
                                            <Typography>From</Typography>
                                            <TextField type="date"
                                                id="from-updated-date"
                                                variant="outlined"
                                                size={"small"}
                                                value={fromUpdatedDate || ""}
                                                onChange={(e) => handleFromUpdatedDate(e)}
                                            />
                                        </div>
                                        <Box style={{ width: '10px' }}></Box>
                                        <div>
                                            <Typography>To</Typography>
                                            <TextField type="date"
                                                id="to-updated-date"
                                                variant="outlined"
                                                size={"small"}
                                                value={toUpdatedDate || ""}
                                                onChange={(e) => handleToUpdatedDate(e)}
                                            />
                                        </div>

                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClear} className={classes.btn}>
                                Clear
                            </Button>
                            <Button autoFocus onClick={handleApply} className={classes.btn}>
                                Apply
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Toolbar>
        </ThemeProvider>
    );
};

ExploreToolbar.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectUser: PropTypes.object
};

export default ExploreToolbar;
