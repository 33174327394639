import {
    GET_EXPLORE_PACKAGE_DETAIL,
    GET_EXPLORE_FILTERS,
    GET_EXPLORE_PACKAGES,
    GET_EXPLORE_ROW_COUNT,
    CHANGE_EXPLORE_ORDER,
    CHANGE_EXPLORE_PAGE,
    CLEAR_SELECTED_USER,
    GET_SELECTED_USER,
    GET_MYPACKAGES,
    GET_MYPACKAGES_ROW_COUNT,
    CHANGE_MYPACKAGES_ORDER,
    CHANGE_MYPACKAGES_PAGE,
    GET_RECENT_PACKAGES
} from "../actions/explore";

const initialState = {
    packages: [],
    packageDetail: {},
    filters: [],
    count: 0,
    page: 0,
    order: { "field_name": "received_date", "direction": "desc" },

    myPackages: [],
    myPackagesFilters: [],
    myPackagesCount: 0,
    myPackagesPage: 0,
    myPackagesOrder: { "field_name": "updated_date", "direction": "desc" },

    user: {},
    recentPackages: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_EXPLORE_PACKAGES: {
            return {
                ...state,
                packages: action.payload['packages'],
            };
        }
        case GET_EXPLORE_ROW_COUNT: {
            return {
                ...state,
                count: action.count,
            };
        }
        case GET_EXPLORE_PACKAGE_DETAIL: {
            return {
                ...state,
                packageDetail: action.payload['packageDetail'],
            };
        }
        case GET_EXPLORE_FILTERS: {
            return {
                ...state,
                filters: action.filters,
            };
        }
        case CHANGE_EXPLORE_ORDER: {
            return {
                ...state,
                order: action.order,
            };
        }
        case CHANGE_EXPLORE_PAGE: {
            return {
                ...state,
                page: action.page,
            };
        }
        case CLEAR_SELECTED_USER: {
            return {
                ...state,
                user: action.user,
            };
        }
        case GET_SELECTED_USER: {
            return {
                ...state,
                user: action.user,
            };
        }
        case GET_MYPACKAGES: {
            return {
                ...state,
                myPackages: action.packages,
            };
        }
        case GET_MYPACKAGES_ROW_COUNT: {
            return {
                ...state,
                myPackagesCount: action.count,
            };
        }
        case CHANGE_MYPACKAGES_ORDER: {
            return {
                ...state,
                myPackagesOrder: action.order,
            };
        }
        case CHANGE_MYPACKAGES_PAGE: {
            return {
                ...state,
                myPackagesPage: action.page,
            };
        }
        case GET_RECENT_PACKAGES: {
            return {
                ...state,
                recentPackages: action.payload,
            };
        }
        default:
            return state;
    }
}
