import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import theme from '../../theme';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import MyInvoiceToolbar from './my_invoice_toolbar';
import { formatCurreny } from '../../util';
import { changeMyInvoicePage, getMyInvoicesData } from '../../actions/invoice';
import EnhancedTableHeader from '../enhanced_table_header';
import { changeRowPerPage } from '../../actions/app';

const useStyles = makeStyles({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px'
    },
    table: {
        minWidth: 650,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const headCells = [
    { id: 'sn', numeric: true, disablePadding: false, label: 'No.' },
    { id: 'invoice_number', numeric: false, disablePadding: false, label: 'Invoice Number' },
    { id: 'invoice_date', numeric: false, disablePadding: false, label: 'Received Date' },
    { id: 'amount', numeric: true, disablePadding: false, label: 'Amount' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

function MyInvoiceList(props) {
    const classes = useStyles();
    const { dispatch } = props;
    const count = useSelector(state => state.invoice.myInvoicesCount);
    const rowsPerPage = useSelector(state => state.app.rowsPerPage);
    const page = useSelector(state => state.invoice.myInvoicesPage);
    const order = useSelector(state => state.invoice.myInvoicesOrder);
    const invoices = useSelector(state => state.invoice.myInvoices);
    const user = useSelector(state => state.app.user);

    useEffect(() => {
        if (user.id !== undefined) {
            dispatch(getMyInvoicesData());
        }
    }, [user, dispatch]);

    const handleRequestSort = (event, property) => {
        const isAsc = order['field_name'] === property && order['direction'] === 'asc';
        var _order = { "field_name": property, "direction": isAsc ? 'desc' : 'asc' };
        dispatch(changeMyInvoicePage(0));
        dispatch(getMyInvoicesData(_order));
    };

    const handleChangePage = (event, newPage) => {
        dispatch(changeMyInvoicePage(newPage));
        dispatch(getMyInvoicesData());
    };

    const handleChangeRowsPerPage = (event) => {
        var rowPerPage = parseInt(event.target.value, 10);
        dispatch(changeMyInvoicePage(0));
        dispatch(changeRowPerPage(rowPerPage));
        dispatch(getMyInvoicesData());
    };


    return (
        <div className={classes.root}>
            <Paper>
                <MyInvoiceToolbar placeHolder={'Search invoice number'} {...props}></MyInvoiceToolbar>
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <EnhancedTableHeader
                            classes={classes}
                            order={order.direction}
                            orderBy={order.field_name}
                            onRequestSort={handleRequestSort}
                            rowCount={count}
                            headCells={headCells}
                        />
                        <TableBody>
                            {invoices.map((row) => (
                                <StyledTableRow key={row.id} >
                                    {headCells.map((h, i) => {
                                        if (h.id === 'sn') {
                                            return (<TableCell key={h.id} align="right">{row[h.id]}</TableCell>);
                                        }
                                        if (h.id === 'invoice_number') {
                                            return (<TableCell className={classes.trackingID} key={h.id} align={h.numeric ? 'right' : 'left'}>{row[h.id]}</TableCell>);
                                        } if (h.id === 'amount') {
                                            return (<TableCell key={h.id} align={h.numeric ? 'right' : 'left'}>{formatCurreny(row[h.id], 0)}</TableCell>);
                                        } else {
                                            return (<TableCell key={h.id} align={h.numeric ? 'right' : 'left'}>{row[h.id]}</TableCell>);
                                        }
                                    })}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`}
                />
            </Paper>
        </div>
    );
}

MyInvoiceList.propTypes = {
    history: PropTypes.object,
};


export default connect()(MyInvoiceList);