import firebase from "../firebase_config";
import { config } from "../firebase_config";
import { SET_IS_UPDATING, SET_SHOW_ALERT } from "./app";

export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATE_ROW_COUNT = "GET_TEMPLATE_ROW_COUNT";
export const GET_TEMPLATE_DETAIL = "GET_TEMPLATE_DETAIL";
export const CHANGE_TEMPLATE_ORDER = "CHANGE_TEMPLATE_ORDER";
export const CHANGE_TEMPLATE_PAGE = "CHANGE_TEMPLATE_PAGE";
export const GET_OBJECTS = "GET_OBJECTS";
export const GET_OBJECT_META = "GET_OBJECT_META";

var _message = '';
var _color = '';

export const getTemplates = () => (dispatch, getState) => {
    dispatch({
        type: GET_TEMPLATES,
        templates: []
    });

    firebase.firestore().collection("reports").where("delete_time", "==", 0)
        .get()
        .then(function (querySnapshots) {
            var templates = [];
            var index = 0;
            querySnapshots.forEach((doc) => {
                var r = doc.data();
                r['sn'] = ++index;
                r['id'] = doc.id;

                templates.push(r);
            });

            dispatch({
                type: GET_TEMPLATES,
                templates
            })
            var count = templates.length;
            dispatch({
                type: GET_TEMPLATE_ROW_COUNT,
                count
            })

        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}

export const getTemplateDetail = (templateId) => (dispatch, getState) => {
    dispatch({
        type: GET_TEMPLATE_DETAIL,
        templateDetail: {}
    });

    firebase.firestore().collection("reports").doc(templateId)
        .get()
        .then(function (doc) {
            var templateDetail = doc.data();
            templateDetail['id'] = doc.id;
            // console.log("doc:", doc.data());

            dispatch({
                type: GET_TEMPLATE_DETAIL,
                templateDetail
            })

        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}

export const setTemplateDetail = (template) => (dispatch, getState) => {
    dispatch({
        type: GET_TEMPLATE_DETAIL,
        templateDetail: template
    });
}

export const addTemplate = (template, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/templates", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(template)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Add template successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const updateTemplate = (template, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/templates", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(template)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update template successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const deleteTemplate = (template, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/templates", {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify({ "id": template.id })
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Delete template successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getTemplates());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const changeTemplateOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_TEMPLATE_ORDER,
        order
    });
}

export const changeTemplatePage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_TEMPLATE_PAGE,
        page
    });
}

export const getObjects = () => (dispatch, getState) => {

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/objs", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                var objects = [];
                if (result && result.data) {
                    let index = 0;
                    result.data.forEach(function (u) {
                        u["sn"] = ++index;
                        objects.push(u);
                    });
                }

                dispatch({
                    type: GET_OBJECTS,
                    objects
                });
            }).catch((e) => {
                console.log("error:", e);
            });

    });
}

export const getObjectMeta = (obj) => (dispatch, getState) => {

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/meta/" + obj, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                var objectMeta = [];
                if (result && result.data) {
                    let index = 0;
                    result.data.forEach(function (u) {
                        u["sn"] = ++index;
                        objectMeta.push(u);
                    });
                }

                dispatch({
                    type: GET_OBJECT_META,
                    objectMeta
                });
            }).catch((e) => {
                console.log("error:", e);
            });

    });
}