import { Grid, Link, SwipeableDrawer } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import InvoiceIcon from '@material-ui/icons/Receipt';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionIcon from '@material-ui/icons/Description';
import TemplateIcon from '@material-ui/icons/NoteAddSharp';
import PhoneIcon from '@material-ui/icons/Phone';
import SettingsIcon from '@material-ui/icons/Settings';
import UserIcon from '@material-ui/icons/SupervisedUserCircle';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Route, Router, Redirect } from 'react-router-dom';
import { changeTab, setShowAlert } from '../actions/app';
import firebase from "../firebase_config";
import { BoxIcon, AssignPickupIcon } from '../icons';
import logo from '../images/logo.jpg';
import ContactUs from "./contact_us";
import ExplorePackageDetail from "./explore/explore_package_detail";
import ExplorePackageList from "./explore/explore_package_list";
import InvoiceDetail from './invoice/invoice_detail';
import InvoiceList from './invoice/invoice_list';
import MyInvoiceDetail from './my_invoice/my_invoice_detail';
import MyInvoiceList from './my_invoice/my_invoice_list';
import LoginPhoneNumber from "./login/login_phone_number";
import MyPackageDetail from "./my_packages/my_package_detail";
import MyPackageList from "./my_packages/my_package_list";
import MyPickupDetail from "./my_pickups/my_pickup_detail";
import MyPickupEdit from "./my_pickups/my_pickup_edit";
import MyPickupList from "./my_pickups/my_pickup_list";
import AdminPickupDetail from "./pickups/pickup_detail";
import AdminPickupList from "./pickups/pickup_list";
import AdminPickupEdit from "./pickups/pickup_edit";
import PickupInfoPage from "./pickups/pickup_info";
import AssignPickupList from "./pickups/assign_pickup_list";
import Profile from "./profile";
import getTitle, { getPage } from './title';
import TrackPackages from "./track/track_packages";
import NewAddressPage from './users/new_address_page';
import UserEdit from "./users/user_edit";
import UserList from './users/user_list';
import Version from './version';
import { changeExploreOrder, changeExplorePage, clearSelectedUser, getExploreData, setFilters } from '../actions/explore';
import SettingsPage from './settings';
import ConfirmPickupForm from './pickups/confirm_pickup_form';
import CancelPickupForm from './pickups/cancel_pickup_form';
import CreatePickupForm from './pickups/create_pickup_for_customer';
import ReschedulePickupForm from './pickups/reschedule_pickup_form';
import CompletePickupForm from './pickups/complete_pickup_form';
import RequestPickupForm from './my_pickups/request_pickup_form';
import AddressesPage from './addresses_page';
import CustomerddressesPage from './pickups/customer_addresses_page';
import JoinPage from './login/join-page';
import InvitationRequestPage from './login/invitation_request_page';
import ConfirmDialog from './confirm_dialog';
import UpdateEmail from './update_email';
import AssignPickupRoute from './pickups/assign_pickup_route';
import TemplateList from './template/template_list';
import TemplateEdit from './template/template_edit';
import ReportList from './report/report_list';
import SearchCustomerPage from './search_customer_page';
import SmsList from './sms/sms_list';
import RatePage from './sms/sms_rate';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  snackbar: {
    backgroundColor: "lightgreen",
    color: "black"
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    width: '90%',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  smallScreenContent: {
    width: '90%',
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuItem: {
    padding: '10px 15px 10px 15px',
    color: "grey",
    textTransform: 'none',
  },
  menuItemSelected: {
    padding: '10px 15px 10px 15px',
    color: "grey",
    textTransform: 'none',
    borderBottom: '2px solid ' + theme.palette.primary.main,
  },
  mainMenuItem: {
    padding: '10px 15px 10px 15px',
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  mainMenuItemSelected: {
    padding: '10px 15px 10px 15px',
    color: theme.palette.primary.main,
    textTransform: 'none',
    borderBottom: '2px solid ' + theme.palette.primary.main,
  },
  drawerMenuItem: {
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  drawerMenuItemSelected: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    borderBottom: '2px solid ' + theme.palette.primary.main,
  },
  drawerMenuIcon: {
    color: theme.palette.primary.main,
  }
}));

function DrawerPage(props) {
  const classes = useStyles();
  const { history, dispatch, smallScreen, currentPageID } = props;
  const login = useSelector(state => state.app.isLogin);
  const showAlert = useSelector(state => state.app.showAlert);
  const _privileges = useSelector(state => state.app.privileges);
  const user = useSelector(state => state.app.user);
  const status = useSelector(state => state.app.status);
  const [logoutConfirm, setLogoutConfirm] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const toggleDrawer = (v) => {
    setOpen(v);
  }

  const handleCloseAlert = () => {
    dispatch(setShowAlert({ show: false }));
  };

  const handleChangePage = (e, name, itemNo) => {
    if (smallScreen) {
      setOpen(false);
    }
    e.preventDefault();
    var location = {
      pathname: '/' + name
    }
    history.push(location);
    if (name === 'packages') {
      dispatch(changeExplorePage(0));
      dispatch(setFilters([]));
    }
    dispatch(changeTab(name));
  }

  const logout = async () => {
    await firebase.auth().signOut();
    window.location.href = '/';
  };

  const handleLogoutDialog = () => {
    setOpen(false);
    setLogoutConfirm(true);
  }

  const handleCancel = () => {
    setLogoutConfirm(false);
  }

  const hasPrivilege = (priv) => {
    if (_privileges.includes('admin')) {
      return true;
    }
    else if (_privileges.includes('sa')) {
      return true;
    }
    else if (_privileges.includes('owner')) {
      return true;
    } else {
      return _privileges.includes(priv);
    }
  }
  const hasSaPrivilege = () => {
    return _privileges.includes('sa');
  }

  const checkStatus = (status) => {
    return status === "joined";
  }

  var appTitle = currentPageID;
  var showAppBarForSmallScreen = getPage(appTitle) ? getPage(appTitle).showAppBarForSmallScreen : false;

  return (
    <div className={classes.root}>
      {smallScreen ? <div>

        {showAppBarForSmallScreen ? <AppBar
          style={{ backgroundColor: 'white' }}
          position="fixed" >
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Link href="/">
                  <Grid item xs={2} style={{ cursor: "pointer" }}>
                    <img id="myimg" src={logo} className="item-img" alt="logo"
                      style={{ height: '70px', width: '70px', margin: 'auto' }} />
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={8}>{getTitle(appTitle)}</Grid>
              <Grid item xs={2}>
                <Grid container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <IconButton onClick={handleClick}>
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

          </Toolbar>
        </AppBar> : <span />}

        <SwipeableDrawer
          anchor={"right"}
          open={open}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <List>
            <ListItem button

              onClick={event => handleChangePage(event, 'track-packages', '8')}>
              <ListItemIcon className={classes.drawerMenuIcon}><LocationSearchingIcon /></ListItemIcon>
              <ListItemText className={appTitle === "track-packages" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Track Packages'} />
            </ListItem>
            {(login && checkStatus(user.status)) ?
              <ListItem button onClick={event => handleChangePage(event, 'my-packages', '8')} >
                <ListItemIcon className={classes.drawerMenuIcon}><BoxIcon /></ListItemIcon>
                <ListItemText className={appTitle === "my-packages" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                  primary={'My Packages'} />
              </ListItem>
              : <span />}
            {(login && checkStatus(user.status)) ?
              <ListItem button onClick={event => handleChangePage(event, 'my-invoices', '8')} >
                <ListItemIcon className={classes.drawerMenuIcon}><InvoiceIcon /></ListItemIcon>
                <ListItemText className={appTitle === "my-invoices" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                  primary={'My Invoices'} />
              </ListItem>
              : <span />}
            {(login && checkStatus(user.status)) ?
              <ListItem button onClick={event => handleChangePage(event, 'my-pickups', '8')} >
                <ListItemIcon className={classes.drawerMenuIcon}><AirportShuttleIcon /></ListItemIcon>
                <ListItemText className={appTitle === "my-pickups" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                  primary={'My Requested Pickups'} />
              </ListItem>
              : <span />}
            <Divider />

            {(hasPrivilege('inv')) ? <ListItem button onClick={event => handleChangePage(event, 'invoices', '8')} >
              <ListItemIcon><InvoiceIcon /></ListItemIcon>
              <ListItemText className={appTitle === "invoices" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Invoices'} />
            </ListItem> : <div />}
            {(hasPrivilege('pku')) ? <ListItem button onClick={event => handleChangePage(event, 'pickups', '8')}>
              <ListItemIcon><AirportShuttleIcon /></ListItemIcon>
              <ListItemText className={appTitle === "pickups" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Pickups'} />
            </ListItem> : <div />}
            {(hasPrivilege('col')) ? <ListItem button onClick={event => handleChangePage(event, 'assign-pickups', '8')}>
              <ListItemIcon><AssignPickupIcon /></ListItemIcon>
              <ListItemText className={appTitle === "assign-pickups" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Collect'} />
            </ListItem> : <div />}
            {(hasPrivilege('cu')) ? <ListItem button onClick={event => handleChangePage(event, 'users', '8')}>
              <ListItemIcon><UserIcon /></ListItemIcon>
              <ListItemText className={appTitle === "users" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Customers'} />
            </ListItem> : <div />}
            {(hasPrivilege('pkg')) ? <ListItem button
              onClick={event => handleChangePage(event, 'packages', '8')}>
              <ListItemIcon><BoxIcon /></ListItemIcon>
              <ListItemText className={appTitle === "packages" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Packages'} />
            </ListItem> : <div />}
            {(login && hasSaPrivilege()) ? <ListItem button
              onClick={event => handleChangePage(event, 'templates', '8')}>
              <ListItemIcon><TemplateIcon /></ListItemIcon>
              <ListItemText className={appTitle === "templates" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Templates'} />
            </ListItem> : <div />}
            {(login && hasPrivilege('rpt')) ? <ListItem button
              onClick={event => handleChangePage(event, 'reports', '8')}>
              <ListItemIcon><DescriptionIcon /></ListItemIcon>
              <ListItemText className={appTitle === "reports" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Reports'} />
            </ListItem> : <div />}
            {(login && hasPrivilege('admin')) ? <ListItem button
              onClick={event => handleChangePage(event, 'settings', '8')}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText className={appTitle === "settings" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Settings'} />
            </ListItem> : <div />}

            <Divider />
            <ListItem button onClick={event => handleChangePage(event, 'contact-us', '8')}>
              <ListItemIcon><PhoneIcon /></ListItemIcon>
              <ListItemText className={appTitle === "contact-us" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'Contact Us'} />
            </ListItem>
            {(login && checkStatus(user.status)) ? <ListItem button onClick={event => handleChangePage(event, 'profile', '8')} >
              <ListItemIcon><ProfileIcon /></ListItemIcon>
              <ListItemText className={appTitle === "profile" ? classes.drawerMenuItemSelected : classes.drawerMenuItem}
                primary={'My Profile'} />
            </ListItem> : <div></div>}
            {login ? <ListItem button onClick={handleLogoutDialog}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItem> : <ListItem button onClick={event => handleChangePage(event, 'login', '8')}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary={'Login'} />
            </ListItem>}
          </List>

        </SwipeableDrawer>

      </div>
        :
        <AppBar
          position="fixed"
          style={{ backgroundColor: 'white' }}
        >
          <Toolbar style={{ height: '100px' }}>
            <Grid container alignItems="center">
              <Link href="/">
                <Grid item xs={1} style={{ cursor: 'pointer' }}>
                  <img id="myimg" src={logo} className="item-img" alt="logo"
                    style={{ height: '80px', width: '80px', margin: 'auto', cursor: 'pointer' }} />

                </Grid>
              </Link>
              <Grid item xs={11}>
                <Grid container
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Box display="flex" justifyContent="flex-end">
                    <div style={{ paddingRight: '5px' }}>
                      {(login && hasPrivilege('inv')) ?
                        <Button
                          variant="text"
                          style={{ fontSize: '11px', textTransform: 'none' }}
                          className={appTitle === "invoices" ? classes.menuItemSelected : classes.menuItem}
                          startIcon={<InvoiceIcon />}
                          onClick={event => handleChangePage(event, 'invoices', '8')}
                        >
                          Invoices
                        </Button> : <span />}
                      {(login && hasPrivilege('pku')) ?
                        <Button
                          variant="text"
                          style={{ fontSize: '11px', textTransform: 'none' }}
                          className={appTitle === "pickups" ? classes.menuItemSelected : classes.menuItem}
                          startIcon={<AirportShuttleIcon />}
                          onClick={event => handleChangePage(event, 'pickups', '8')}
                        >
                          Pickups
                        </Button> : <span />}
                      {(login && hasPrivilege('col')) ?
                        <Button
                          variant="text"
                          style={{ fontSize: '11px', textTransform: 'none' }}
                          className={appTitle === "assign-pickups" ? classes.menuItemSelected : classes.menuItem}
                          startIcon={<AssignPickupIcon />}
                          onClick={event => handleChangePage(event, 'assign-pickups', '8')}
                        >
                          {"Collect"}
                        </Button> : <span />}
                      {(login && hasPrivilege('cu')) ?
                        <Button
                          variant="text"
                          style={{ fontSize: '11px', textTransform: 'none' }}
                          className={appTitle === "users" ? classes.menuItemSelected : classes.menuItem}
                          startIcon={<UserIcon />}
                          onClick={event => handleChangePage(event, 'users', '8')}
                        >
                          Customers
                        </Button> : <span />}
                      {login && hasPrivilege('pkg') ? < Button
                        variant="text"
                        style={{ fontSize: '11px', textTransform: 'none' }}
                        className={appTitle === "packages" ? classes.menuItemSelected : classes.menuItem}
                        startIcon={<BoxIcon />}
                        onClick={event => handleChangePage(event, 'packages', '8')}
                      >
                        Packages
                      </Button> : <span />}
                      {(login && hasSaPrivilege()) ? <Button
                        variant="text"
                        style={{ fontSize: '11px', textTransform: 'none' }}
                        className={appTitle === "templates" ? classes.menuItemSelected : classes.menuItem}
                        startIcon={<TemplateIcon />}
                        onClick={event => handleChangePage(event, 'templates', '8')}
                      >
                        Templates
                      </Button> : <span />}
                      {(login && hasPrivilege('rpt')) ? <Button
                        variant="text"
                        style={{ fontSize: '11px', textTransform: 'none' }}
                        className={appTitle === "reports" ? classes.menuItemSelected : classes.menuItem}
                        startIcon={<DescriptionIcon />}
                        onClick={event => handleChangePage(event, 'reports', '8')}
                      >
                        Reports
                      </Button> : <span />}
                      {(login && hasPrivilege('admin')) ? < Button
                        variant="text"
                        style={{ fontSize: '11px', textTransform: 'none' }}
                        className={appTitle === "settings" ? classes.menuItemSelected : classes.menuItem}
                        startIcon={<SettingsIcon />}
                        onClick={event => handleChangePage(event, 'settings', '8')}
                      >
                        Settings
                      </Button> : <span />}
                    </div>
                    <div style={{ color: '#0080806b', backgroundColor: '#8080806b', width: '3px' }}></div>
                    <div style={{ paddingLeft: '5px' }}>
                      <Button
                        variant="text"
                        style={{ fontSize: '11px', textTransform: 'none' }}
                        className={appTitle === "contact-us" ? classes.menuItemSelected : classes.menuItem}
                        startIcon={<PhoneIcon />}
                        onClick={event => handleChangePage(event, 'contact-us', '8')}
                      >
                        Contact Us
                      </Button>
                      {(login && checkStatus(user.status)) ? <Button
                        variant="text"
                        style={{ fontSize: '11px', textTransform: 'none' }}
                        className={appTitle === "profile" ? classes.menuItemSelected : classes.menuItem}
                        startIcon={<ProfileIcon />}
                        onClick={event => handleChangePage(event, 'profile', '8')}
                      >
                        My Profile
                      </Button> : <span />}

                      {login ? <Button
                        variant="text"
                        style={{ fontSize: '11px', textTransform: 'none' }}
                        className={classes.menuItem}
                        startIcon={<LogoutIcon />}
                        onClick={handleLogoutDialog}
                      >
                        Logout
                      </Button> : <Button
                        variant="text"
                        style={{ fontSize: '11px', textTransform: 'none' }}
                        className={classes.menuItem}
                        startIcon={<LogoutIcon />}
                        onClick={event => handleChangePage(event, 'login', '8')}
                      >
                        Login
                      </Button>}
                    </div>
                  </Box>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={10}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start">
                          <Button
                            variant="text"
                            className={appTitle === "track-packages" ? classes.mainMenuItemSelected : classes.mainMenuItem}
                            onClick={event => handleChangePage(event, 'track-packages', '8')}
                            startIcon={<LocationSearchingIcon />}
                          >
                            Track Packages
                          </Button>
                          {login && checkStatus(user.status) ?
                            <Button
                              variant="text"
                              className={appTitle === "my-packages" ? classes.mainMenuItemSelected : classes.mainMenuItem}
                              startIcon={<BoxIcon />}
                              onClick={event => handleChangePage(event, 'my-packages', '8')}
                            >
                              My Packages
                            </Button> : <span />}
                          {login && checkStatus(user.status) ?
                            <Button
                              variant="text"
                              className={appTitle === "my-invoices" ? classes.mainMenuItemSelected : classes.mainMenuItem}
                              onClick={event => handleChangePage(event, 'my-invoices', '8')}
                              startIcon={<InvoiceIcon />}
                            >
                              My Invoices
                            </Button> : <span />}
                          {login && checkStatus(user.status) ?
                            <Button
                              variant="text"
                              className={appTitle === "my-pickups" ? classes.mainMenuItemSelected : classes.mainMenuItem}
                              onClick={event => handleChangePage(event, 'my-pickups', '8')}
                              startIcon={<AirportShuttleIcon />}
                            >
                              My Requested Pickups
                            </Button> : <span />}
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <Grid container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center">
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      }
      {logoutConfirm ? <ConfirmDialog
        msg={'Logout your account ?'}
        openDialog={logoutConfirm}
        confirmBtnLabel={"Logout"}
        onCancel={(v) => handleCancel(v)}
        onContinue={(v) => logout()}></ConfirmDialog> : <div />}

      <main
        className={clsx(smallScreen ? classes.smallScreenContent : classes.content)}
      >
        <div className={(smallScreen && !showAppBarForSmallScreen) ? '' : classes.drawerHeader} />

        <Router history={history}>
          <Route exact path="/" >
            {(login && (status === 'signup')) ?
              <Redirect to="/request-invitation" />
              : (login && status === "invited") ? <Redirect to="/join-invite" /> : <TrackPackages {...props} />}

          </Route>
          <Route path="/track-packages" component={TrackPackages} {...props} />
          <Route path="/login" component={LoginPhoneNumber} />
          <Route path="/packages" render={() => <ExplorePackageList title={"Customer packages"} {...props}></ExplorePackageList>} />
          <Route path="/package-detail/:templateId" render={(props) => <ExplorePackageDetail {...props}></ExplorePackageDetail>} />

          <Route path="/pickups" render={(_props) => <AdminPickupList {...props} />} />
          <Route path="/pickup-info/:pickupId" render={(_props) => <PickupInfoPage title={"Pickup detail"} {...props}></PickupInfoPage>} />
          <Route path="/pickup-detail/:pickupId" render={(_props) => <AdminPickupDetail title={"Pickup detail"} {...props}></AdminPickupDetail>} />
          <Route path="/pickup-edit/:pickupId" render={(_props) => <AdminPickupEdit title={'Edit pickup'}
            {...props}></AdminPickupEdit>} />

          <Route path="/assign-pickups" component={AssignPickupList} />
          <Route path="/assign-pickup-route" render={(_props) => <AssignPickupRoute title={'Route'}
            {...props}></AssignPickupRoute>} />

          <Route path="/my-packages" component={MyPackageList} />
          <Route path="/my-package-detail/:packageId" component={MyPackageDetail} />

          <Route path="/my-pickups" component={MyPickupList} />
          <Route path="/my-pickup-detail/:pickupId" render={(_props) => <MyPickupDetail title={'My pickup detail'}
            {...props}></MyPickupDetail>} />
          <Route path="/my-pickup-edit/:pickupId" render={(_props) => <MyPickupEdit title={'Edit pickup'}
            {...props}></MyPickupEdit>} />

          <Route path="/request-pickup-form" render={(props) => <RequestPickupForm title={'Request pickup'}
            {...props}></RequestPickupForm>} />
          <Route path="/create-pickup-form" render={(props) => <CreatePickupForm title={'Create pickup'}
            {...props}></CreatePickupForm>} />
          <Route path="/pickup-confirm/:pickupId" render={(props) => <ConfirmPickupForm title={'Confirm pickup'}
            {...props}></ConfirmPickupForm>} />
          <Route path="/pickup-reschedule/:pickupId" render={(props) => <ReschedulePickupForm title={'Reschedule pickup'}
            {...props}></ReschedulePickupForm>} />

          <Route path="/pickup-cancel/:pickupId" render={(_props) => <CancelPickupForm title={'Cancel pickup'}
            {...props}></CancelPickupForm>} />

          <Route path="/pickup-complete/:pickupId" render={(_props) => <CompletePickupForm title={'Complete pickup'}
            {...props}></CompletePickupForm>} />

          <Route path="/my-invoices" component={MyInvoiceList} />
          <Route path="/my-invoice-detail/:invoiceId" component={MyInvoiceDetail} />

          <Route path="/user-packages/:userId/packages" component={ExplorePackageList} />

          <Route path="/users" render={(_props) => <UserList {...props} />} />
          <Route path="/user-detail/:userId" render={(props) => <UserEdit
            title={'Customer detail'}
            {...props}
          ></UserEdit>} />

          <Route path="/invoices" component={InvoiceList} />
          <Route path="/invoice-detail/:templateId" component={InvoiceDetail} />

          <Route path="/contact-us" component={ContactUs} />
          <Route path="/profile" render={(_props) => <Profile {...props} />} />
          <Route path="/email-update" render={(props) => <UpdateEmail
            title={'Update Email'}
            {...props}
          ></UpdateEmail>} />

          <Route path="/addresses" render={(props) => <AddressesPage title={"Addresses"} {...props} />} />
          <Route path="/customer-addresses" render={(props) => <CustomerddressesPage title={"Customer Addresses"} {...props} />} />
          <Route path="/new-address" render={(props) => <NewAddressPage title={"New address"} {...props} />} />
          <Route path="/address-detail/:userId/:templateId" render={(props) => <NewAddressPage title={"Edit address"} {...props} />} />

          <Route path="/settings" render={(_props) => <SettingsPage {...props} />} />

          <Route path="/templates" render={(_props) => <TemplateList {...props} />} />
          <Route path="/new-template" render={(_props) => <TemplateEdit title={"New Template"} {...props} />} />
          <Route path="/template-edit/:templateId" render={(_props) => <TemplateEdit title={"Edit Template"} {...props} />} />
          <Route path="/reports" render={(_props) => <ReportList {...props} />} />

          <Route path="/search-customers" render={(_props) => <SearchCustomerPage title={"Search Customer"} {...props} />} />

          <Route path="/join-invite" component={JoinPage} />
          <Route path="/request-invitation" component={InvitationRequestPage} />
          <Route path="/sms" component={SmsList} />
          <Route path="/rates" component={RatePage} {...props}/>
        </Router>
        {appTitle === "login" ? <div /> :
          <Version></Version>
        }
      </main>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showAlert.show}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <SnackbarContent
          className={classes.snackbar}
          style={{ color: showAlert.color }}
          message={
            <span>
              <div>{showAlert.msg}</div>
            </span>
          }
        />
      </Snackbar>

    </div >
  );
}

DrawerPage.propTypes = {
  history: PropTypes.object,
  smallScreen: PropTypes.bool,
  currentPageID: PropTypes.string
};

export default (connect()(DrawerPage));
