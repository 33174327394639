import {
    GET_INVOICE_DETAIL,
    GET_INVOICES,
    GET_INVOICE_ROW_COUNT,
    CHANGE_INVOICE_ORDER,
    CHANGE_INVOICE_PAGE,
    GET_MYINVOICES,
    GET_MYINVOICES_ROW_COUNT,
    CHANGE_MYINVOICES_ORDER,
    CHANGE_MYINVOICES_PAGE
} from "../actions/invoice";


const initialState = {
    invoices: [],
    invoiceDetail: {},
    count: 0,
    page: 0,
    order: { "field_name": "", "direction": "asc" },
    myInvoices: [],
    myInvoicesCount: 0,
    myInvoicesPage: 0,
    myInvoicesOrder: { "field_name": "", "direction": "asc" },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_INVOICES: {
            return {
                ...state,
                invoices: action.invoices,
            };
        }
        case GET_INVOICE_ROW_COUNT: {
            return {
                ...state,
                count: action.count,
            };
        }
        case GET_INVOICE_DETAIL: {
            return {
                ...state,
                invoiceDetail: action.invoice,
            };
        }
        case CHANGE_INVOICE_ORDER: {
            return {
                ...state,
                order: action.order,
            };
        }
        case CHANGE_INVOICE_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        case GET_MYINVOICES: {
            return {
                ...state,
                myInvoices: action.invoices,
            };
        }
        case GET_MYINVOICES_ROW_COUNT: {
            return {
                ...state,
                myInvoicesCount: action.count,
            };
        }
        case CHANGE_MYINVOICES_ORDER: {
            return {
                ...state,
                myInvoicesOrder: action.order,
            };
        }
        case CHANGE_MYINVOICES_PAGE: {
            return {
                ...state,
                myInvoicesPage: action.page
            }
        }
        default:
            return state;
    }
}
