import { Box,  Typography, Grid, Paper, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getPickupInfo } from '../../actions/pickup';
// import {  getPickupInfo } from '../../actions/pickup';
import theme from '../../theme';
import { convertISOToDate, getTimeFormDate, removeFCSString } from '../../util';
// import AddressCard from '../address_card';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));

const PickupInfo = (props) => {
    const { title, history, dispatch, smallScreen } = props;
    const classes = useStyles();
    const selector = useSelector(state => state.pickup);
    const [pickupDetail, setPickupDetail] = React.useState(selector.pickupDetail);
    const [open, setOpen] = React.useState(false);
    const [selectedPhoto, setSelectedPhoto] = React.useState(pickupDetail['photo_urls'] ? pickupDetail['photo_urls'][0] : "#");

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var pickupId = pathArr[2];

    useEffect(() => {
        dispatch(getPickupInfo(pickupId));
    }, [dispatch, pickupId]);

    useEffect(() => {
        setPickupDetail(selector.pickupDetail);
    }, [selector]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? '10px' : '30px' }}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{pickupDetail.pickup_number !== undefined ? pickupDetail.pickup_number : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{pickupDetail.status !== undefined ? pickupDetail.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Zone'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.zone_name !== undefined ? pickupDetail.zone_name : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Date'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_date !== undefined ? convertISOToDate(pickupDetail.pickup_date) : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    {/* <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', marginBottom: '5px', color: 'grey', textAlign: 'left' }}>{'Pickup Time'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_time_from ? getTimeFormDate((pickupDetail.pickup_time_from).toDate()) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_time_to ? getTimeFormDate((pickupDetail.pickup_time_to).toDate()) : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.customer_fcs_id !== undefined ? removeFCSString(pickupDetail.customer_fcs_id) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.customer_name !== undefined ? pickupDetail.customer_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Staff'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_staff_name ? pickupDetail.pickup_staff_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Address'}</Typography>
                                {pickupDetail.pickup_address !== undefined ? <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                    <Paper
                                        style={{ marginRight: '5px', marginBottom: '5px', width: '280px', height: '165px', border: '1px solid lightgray' }}
                                    >
                                        <AddressCard address={pickupDetail.pickup_address}
                                            editIcon={false}
                                            deleteIcon={false}
                                            makeDefaultIcon={false}
                                        />
                                    </Paper>
                                </div> : <div />}
                            </div>
                        </td>
                    </tr> */}
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Photos'}</Typography>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>
                                    {
                                        pickupDetail['photo_urls'] ? pickupDetail['photo_urls'].map((i, index) =>
                                            <Box key={index} style={{ marginRight: '5px', marginBottom: "5px" }}>
                                                <img id="output" alt={i.name}
                                                    style={{ padding: '10px', height: "100px", width: "100px", border: "1px solid lightgrey" }}
                                                    src={i}
                                                    onClick={(e) => {
                                                        setSelectedPhoto(i);
                                                        setOpen(true);
                                                    }
                                                    } /></Box>
                                        )
                                            : <span />}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Photos"}</DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12}>
                        <Grid>
                            <img src={selectedPhoto} className="show-img" alt="logo" />
                        </Grid>
                        <br />
                        <Grid container spacing={3}>
                            {pickupDetail['photo_urls'] ? pickupDetail['photo_urls'].map((value) => (
                                <Grid key={value} item>
                                    <Box className="square" > <img src={value} className="thumnail-img" alt="logo" onClick={(e) => setSelectedPhoto(value)} /></Box>
                                </Grid>
                            )) : <span />}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div >

    );
};

PickupInfo.propTypes = {
    pDetail: PropTypes.object,
    title: PropTypes.string
};

export default withRouter(connect()(PickupInfo));
