import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import {
    Box, Button, Grid, TextField, FormControlLabel, Checkbox, FormControl, FormLabel,
    Radio, RadioGroup
} from '@material-ui/core';
import { getMapPickups } from '../../actions/pickup';
import moment from 'moment';
import DisplayMapDirection from './display_map_direction';

const mapStyles = {
    // width: '90vw',
    width: "62%",
    height: '65vh',
    marginRight: 'auto'
};

const useStyles = makeStyles({
    root: {
        width: '100%',
        flexGrow: 1,
        textAlign: 'auto'
    },
    table: {
        minWidth: 650,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    },
    paper: {
        display: "inline-flex",
        width: "100%",
        flexWrap: "wrap"
    }
});

var directionsRenderer;

function AssignMap(props) {
    const classes = useStyles();
    const { google, dispatch, smallScreen } = props;
    const pickups = useSelector(state => state.pickup.mapPickups);
    const setting = useSelector(state => state.app.setting);
    const user = useSelector(state => state.app.user);
    const [map, setMap] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState();
    const [isDrawing, setIsDrawing] = React.useState(false);
    const [waypointOrder, setWaypointOrder] = React.useState([]);
    const [routeLegs, setRouteLegs] = React.useState([]);
    const [routeCoods, setRouteCoods] = React.useState([]);
    const [fromLocation, setFromLocation] = React.useState("office");
    const [currentLocation, setCurrentLocation] = React.useState(null);

    useEffect(() => {
        if (user.id) {
            dispatch(getMapPickups(user.id, selectedDate));
        }
    }, [user, dispatch, selectedDate]);


    const onMapReady = (mapProps, map) => {
        setMap(map);
        directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        draw();
    }

    const draw = () => {
        if (!pickups || map === null) {
            return;
        }
        setRouteLegs([]);
        if (pickups.length === 0) {
            directionsRenderer.set('directions', null);
            return;
        }
        console.log("currentLocation==>", currentLocation);

        let coords = [];
        let waypoints = [];
        pickups.forEach((p) => {
            if (p.pickup_address && p.pickup_address.lat && p.pickup_address.lng) {
                coords.push({ lat: p.pickup_address.lat, lng: p.pickup_address.lng, pickup: p });
            }
        });
        for (let i = 0; i < coords.length; i++) {
            waypoints.push({
                location: { lat: coords[i].lat, lng: coords[i].lng },
                stopover: true,
            });
        }

        let start = { lat: setting.office_lat, lng: setting.office_lng };
        if (currentLocation) {
            start.lat = currentLocation.lat;
            start.lng = currentLocation.lng;
        }
        let end = { lat: start.lat, lng: start.lng };
        const directionsService = new google.maps.DirectionsService();

        let request = {
            origin: start,
            waypoints: waypoints,
            destination: end,
            travelMode: "DRIVING",
            optimizeWaypoints: true,
        };
        setIsDrawing(true);
        directionsService.route(request, function (result, status) {
            console.log("show result==>>", result, ", status:", status);
            if (status === "OK") {
                directionsRenderer.setDirections(result);
                const route = result.routes[0];
                const waypointOrder = route.waypoint_order;

                setRouteLegs(route.legs);
                setWaypointOrder(waypointOrder);
                setRouteCoods(coords);
            } else if (status == "ZERO_RESULTS") {
                directionsRenderer.setDirections(result);
            }
            setIsDrawing(false);
        });
    }
    useEffect(() => {
        draw();
    }, [pickups, currentLocation])

    const handleTodayClick = (event, value) => {
        console.log("handleSelectedDate today", Date());
        setSelectedDate(new Date());
    };

    const handleSelectedDate = (event) => {
        var d = event.target.value;
        var arr = d.split("-");
        var yy = Number(arr[0]);
        var mm = Number(arr[1]) - 1;
        var dd = Number(arr[2]);
        setSelectedDate(new Date(yy, mm, dd));
    };

    let infoWindow;

    const handleFromLocation = (event) => {
        infoWindow = new google.maps.InfoWindow();

        setFromLocation(event.target.value);
        if (event.target.value==="current") {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        setCurrentLocation(pos);
                    },
                    () => {
                        handleLocationError(true, infoWindow, map.getCenter());
                    }
                );
            } else {
                handleLocationError(false, infoWindow, map.getCenter());
            }
        } else {
            setCurrentLocation(null);
        }
    };

    const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation
                ? "Error: Unable to get current location. Please enable it from your browser."
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(map);
    }

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="center" flexDirection="row" flexWrap="wrap">
                <Box>
                    <Button variant="outlined" color="primary" onClick={handleTodayClick}
                        disabled={isDrawing} >
                        Today
                    </Button>
                </Box>
                <Box pl={1} pb={1}>
                    <TextField type="date"
                        variant="outlined"
                        style={{ width: "200px" }}
                        size="small"
                        disabled={isDrawing}
                        value={moment(selectedDate).format('YYYY-MM-DD')}
                        onChange={handleSelectedDate}></TextField>
                </Box>             
                <Box pl={1}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Start from</FormLabel>
                        <RadioGroup row aria-label="from" name="from" value={fromLocation} onChange={handleFromLocation}>
                            <FormControlLabel value="office" control={<Radio />} label="Office" />
                            <FormControlLabel value="current" control={<Radio />} label="My current location" />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>

            <Grid container direction="row">
                <Grid item xs={12} md={8}>
                    <div style={{ height: "65vh", marginBottom: "20px", position: "relative" }}>
                        <Map
                            google={props.google}
                            zoom={10}
                            style={{ width: "98%", height: "65vh", marginRight: "auto", }}
                            onReady={onMapReady}
                            mapTypeControl={false}
                            streetViewControl={false}
                            initialCenter={
                                {
                                    lat: 40.7380728,
                                    lng: -73.8141291
                                }
                            }
                        >
                        </Map>
                    </div>
                </Grid>
                {routeLegs && routeLegs.length > 0 ?
                    <Grid item xs={12} md={4}>
                        <DisplayMapDirection waypointOrder={waypointOrder} routeCoods={routeCoods} routeLegs={routeLegs} {...props}></DisplayMapDirection>
                    </Grid> : <span></span>
                }
            </Grid>
        </div>
    );
}

AssignMap.propTypes = {
    history: PropTypes.object,
    smallScreen: PropTypes.bool,
};
export default connect()(GoogleApiWrapper({
    apiKey: 'AIzaSyDpRlul5v6a9yHvmjOHSHS3rowrrrtoPkI'
})(AssignMap));