import { Card, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import theme from '../../theme';
import { formatDateToLocal } from '../../util';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));


function getReceivedDate(status) {
    var convertDate = '';
    if (status !== undefined) {
        status.forEach((value, index) => {
            if (value['status'] === 'received') {
                var date = new Date(value['date'].toDate());
                convertDate = formatDateToLocal(date);
            }
        });
    }
    return convertDate;
}

const styles = {
    dialogTitle: {
        flexGrow: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
};


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const MyInvoiceDetail = (props) => {
    const { dispatch, history } = props;
    const classes = useStyles();
    const app = useSelector(state => state.app);
    const [pDetail, setPackage] = React.useState(app.packageDetail);
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    // const [selectedPhoto, setSelectedPhoto] = React.useState(pDetail['photo_urls'] ? pDetail['photo_urls'][0] : "#");

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var packageId = pathArr[2];

    console.log('pDetail => ', app.packageDetail);
    useEffect(() => {
        setPackage(app.packageDetail);
    }, [app]);

    useEffect(() => {
        // dispatch(getPackageDetail('397994676055'));
    }, [packageId, dispatch]);

    useEffect(() => {
        if (packageId !== pDetail.id) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [packageId, pDetail]);


    return (
        <div className={classes.root}>
            {/* <MainMenu></MainMenu> */}
            <Card className={classes.root}>
                <Grid item xs={12} sm={8}>
                    <div style={{ textAlign: 'left' }}>
                        <span style={{ font: '14px', fontWeight: 'bold', color: theme.palette.primary.main }}>Tracking number </span>
                        <span style={{ color: "grey", fontSize: 14, marginLeft: 5 }}> {isLoading ? 'Loading...' : pDetail['tracking_id'] ? pDetail['tracking_id'] : "Not found"}</span>
                    </div>
                    <div style={{ textAlign: 'left' }}><span style={{ font: '14px', fontWeight: 'bold', color: theme.palette.primary.main }}>Received date </span><span style={pDetail['remark'] === 'Not found' ? { color: "red", fontSize: 14, marginLeft: 5 } : { color: "grey", fontSize: 14, marginLeft: 5 }}> {getReceivedDate(pDetail['all_status'])}</span></div>
                    <div style={{ textAlign: 'left' }}><span style={{ font: '14px', fontWeight: 'bold', color: theme.palette.primary.main }}>Remark </span><span style={pDetail['remark'] === 'Not found' ? { color: "red", fontSize: 14, marginLeft: 5 } : { color: "grey", fontSize: 14, marginLeft: 5 }}> {pDetail['remark']}</span></div>
                    <div style={{ textAlign: 'left' }}><span style={{ font: '14px', fontWeight: 'bold', color: theme.palette.primary.main }}>Status </span><span style={pDetail['remark'] === 'Not found' ? { color: "red", fontSize: 14, marginLeft: 5 } : { color: "grey", fontSize: 14, marginLeft: 5 }}> {pDetail['status']}</span></div>
                </Grid>
                {/* <div className={classes.rootPhotos}>
                    <GridList className={classes.gridList}>
                        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                            <ListSubheader component="div">Photos</ListSubheader>
                        </GridListTile>
                        {pDetail['photo_urls'] ? pDetail['photo_urls'].map((tile) => (
                            <GridListTile key={tile}>
                                <img src={tile} alt={tile} onClick={(e) => {
                                    setSelectedPhoto(tile);
                                    setOpen(true);
                                }
                                } />
                            </GridListTile>
                        )) : <span />}
                    </GridList>
                </div> */}
            </Card>
            <Dialog maxWidth="lg" aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={(e) => setOpen(false)} >
                    Photos
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12}>
                        {/* <Grid>
                            <img src={selectedPhoto} className="show-img" alt="logo" />
                        </Grid>
                        <br />
                        <Grid container spacing={3}>
                            {pDetail['photo_urls'] ? pDetail['photo_urls'].map((value) => (
                                <Grid key={value} item>
                                    <Box className="square" > <img src={value} className="thumnail-img" alt="logo" onClick={(e) => setSelectedPhoto(value)} /></Box>
                                </Grid>
                            )) : <span />}
                        </Grid> */}
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>

    );
};

MyInvoiceDetail.propTypes = {
    pDetail: PropTypes.object
};

export default withRouter(connect()(MyInvoiceDetail));
