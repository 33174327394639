import React, { useEffect } from 'react';
import { Typography, Box, makeStyles, Paper, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBack';
import { connect, useSelector } from 'react-redux';
import theme from '../../theme';
import AddressCard from '../address_card';
import { getCustomerAddresses } from '../../actions/user';
import { changeCustomerPickupAddress } from '../../actions/pickup';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    selectedAddressColor: {
        border: "2px solid green",
        marginRight: '5px',
        marginBottom: '5px',
    },
    unSelectedAddressColor: {
        border: "2px solid lightgray",
        marginRight: '5px',
        marginBottom: '5px',
    }
}));


function CustomerAddressesPage(props) {
    const classes = useStyles();
    const { title, history, dispatch } = props;
    const addresses = useSelector(state => state.user.customerAddresses);

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var customerId = pathArr[2];

    useEffect(() => {
        dispatch(getCustomerAddresses(customerId));
    }, [dispatch, customerId]);

    const handleBack = () => {
        history.goBack();
    }

    const handleSelectedChangeAddress = (value) => {
        dispatch(changeCustomerPickupAddress(value));
        history.goBack();
    }

    return (

        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>

            <div style={{ padding: "30px" }}>
                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>
                    {addresses ? addresses.map((value) => {
                        return <Paper key={value.id}
                            className={
                                (value.is_default) ? classes.selectedAddressColor : classes.unSelectedAddressColor}
                        >
                            <AddressCard address={value}
                                onSelectedChange={(v) => { handleSelectedChangeAddress(v) }}></AddressCard>
                        </Paper>
                    }) : <span />
                    }
                </div>
            </div>
        </div >
    );
}

CustomerAddressesPage.propTypes = {
    title: PropTypes.string
}

export default connect()(CustomerAddressesPage);