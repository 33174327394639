import React from 'react';
import { Typography, CardContent, Tooltip, IconButton, Card, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EnableIcon from '@material-ui/icons/Check';
import DisableIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import ConfirmDialog from '../confirm_dialog';

function FieldCard(props) {
    const { field, onEdit, onDelete } = props;
    const [isDeleteConfirm, setDeleteConfirm] = React.useState(false);

    const handleEditField = f => {
        onEdit(f.id);
    }

    const handleDeleteField = (v) => {
        setDeleteConfirm(false);
        onDelete(field);
    }

    const handleDeleteCancel = (v) => {
        setDeleteConfirm(v);
    }

    const handleDeleteDialog = () => {
        setDeleteConfirm(true);
    }

    return (
        <Card key={field.id} style={{ width: '220px', height: '270px' }}>
            <CardContent style={{ padding: '10px' }}>
                <div style={{
                    color: 'grey',
                    height: '115px',
                }}>
                    <div style={{ width: '100%', display: 'inline-flex' }}>
                        <Typography variant="h5" style={{ width: '75%', textAlign: 'left' }}>{field.field_name}</Typography>
                        <div style={{ display: 'inline-flex', }}>
                            {onEdit ? <Tooltip title="Edit" aria-label="add"><IconButton key={'edit'} color="primary" size="small" onClick={() => handleEditField(field)} ><EditIcon /></IconButton></Tooltip> : <span />}
                            {onDelete ? <Tooltip title="Delete" aria-label="add"><IconButton key={'delete'} color="primary" size="small" onClick={() => handleDeleteDialog(field)}><DeleteIcon /></IconButton></Tooltip> : <span />}
                        </div>
                    </div>
                    <div><Typography style={{ color: 'grey', textAlign: 'left' }}>{field.display_name}</Typography></div>
                    <div><Typography style={{ color: 'grey', textAlign: 'left' }}>{field.field_type}</Typography></div>
                    <div>
                        <Typography style={{ color: 'grey', textAlign: 'left' }}>{field.show ?
                            <FormControlLabel control={<EnableIcon style={{ color: "green" }} />} label="Show" />
                            : <FormControlLabel control={<DisableIcon style={{ color: "red" }} />} label="Show" />}</Typography>
                    </div>

                    <div>
                        <Typography style={{ color: 'grey', textAlign: 'left' }}> {field.allow_filter ?
                            <FormControlLabel control={<EnableIcon style={{ color: "green" }} />} label="Allow Filter" />
                            : <FormControlLabel control={<DisableIcon style={{ color: "red" }} />} label="Allow Filter" />}</Typography>
                    </div>

                    <div>
                        <Typography style={{ color: 'grey', textAlign: 'left' }}> {field.allow_sort ?
                            <FormControlLabel control={<EnableIcon style={{ color: "green" }} />} label="Allow Sort" />
                            : <FormControlLabel control={<DisableIcon style={{ color: "red" }} />} label="Allow Sort" />}</Typography>
                    </div>


                    <div><Typography style={{ color: 'grey', textAlign: 'left' }}>{field.default_sort}</Typography></div>
                    <div><Typography style={{ color: 'grey', textAlign: 'left' }}>{field.filter_compare}</Typography></div>
                    <div><Typography style={{
                        color: 'grey', textAlign: 'left', display: '-webkit-box', overflow: 'hidden',
                        WebkitLineClamp: "3",
                        textOverflow: "ellipsis",
                        WebkitBoxOrient: "vertical"
                    }}>{field.display_format}</Typography></div>
                    <div><Typography style={{ color: 'grey', textAlign: 'left' }}>{field.dropdown_display_field}</Typography></div>


                </div>
            </CardContent>
            {isDeleteConfirm ? <ConfirmDialog
                msg={'Delete this field "' + field.field_name + '"?'}
                openDialog={isDeleteConfirm}
                confirmBtnLabel={"Delete"}
                onCancel={(v) => handleDeleteCancel(v)}
                onContinue={(v) => handleDeleteField(v)}></ConfirmDialog> : <div />}

        </Card>

    );
}

FieldCard.propTypes = {
    field: PropTypes.any,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
}

export default connect()(FieldCard);