import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

export const config = {
  apiKey: "AIzaSyCbjgzf-kkoCgJmcMY3oImC9WEere5BSSs",
  authDomain: "fcs-prod1.firebaseapp.com",
  databaseURL: "https://fcs-prod1.firebaseio.com",
  projectId: "fcs-prod1",
  storageBucket: "fcs-prod1",
  messagingSenderId: "168368749206",
  appId: "1:168368749206:web:36ce60757e1462b35e60b7",
  measurementId: "G-LPM0DT0D93",
  fcsReportId: "fcs-prod",
  fcsReportUrl: "https://sqldb.mokkon.com",
  fcsUrl:"https://asia-northeast1-fcs-prod1.cloudfunctions.net/API12"
};

firebase.initializeApp(config);
export default firebase;
