
import firebase from "../firebase_config";
import { config } from "../firebase_config";
import 'firebase/firestore';

export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICE_DETAIL = 'GET_INVOICE_DETAIL';
export const GET_INVOICE_ROW_COUNT = "GET_INVOICE_ROW_COUNT";
export const CHANGE_INVOICE_ORDER = "CHANGE_INVOICE_ORDER";
export const CHANGE_INVOICE_PAGE = "CHANGE_INVOICE_PAGE";

export const GET_MYINVOICES = 'GET_MYINVOICES';
export const GET_MYINVOICES_ROW_COUNT = "GET_MYINVOICES_ROW_COUNT";
export const CHANGE_MYINVOICES_ORDER = "CHANGE_MYINVOICES_ORDER";
export const CHANGE_MYINVOICES_PAGE = "CHANGE_MYINVOICES_PAGE";

export const getInvoiceDetail = (id) => (dispatch, getState) => {
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.firestore().collection("users").doc(id)
                .get()
                .then(function (doc) {
                    var pickup = doc.data();
                    pickup['id'] = doc.id;

                    dispatch({
                        type: GET_INVOICE_DETAIL,
                        pickup
                    })
                }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });


        }
    });

}

export const getInvoicesData = (order) => (dispatch, getState) => {
    if (!order) {
        order = getState().invoice.order;
    }
    dispatch(changeInvoiceOrder(order));

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        var offset = getState().invoice.page * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }

        var data = { limit: limit, offset: offset, sorts: sorts };
        fetch(config.fcsReportUrl + "/api/data/invoices", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var invoices = [];
                if (result && result.data) {
                    result.data.forEach(function (u) {
                        u["sn"] = ++offset;
                        invoices.push(u);
                    });
                }
                dispatch({
                    type: GET_INVOICES,
                    invoices
                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/invoices", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_INVOICE_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const getMyInvoicesData = (order) => (dispatch, getState) => {
    if (!order) {
        order = getState().invoice.myInvoicesOrder;
    }

    dispatch(changeMyInvoiceOrder(order));

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        var offset = getState().invoice.myInvoicesPage * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }

        var _filters = [];
        var _user = getState().app.user;
        if (_user.id !== undefined) {
            _filters.push({ 'field': 'user_id', 'compare': "==", 'value': _user.id });
        }

        var data = { limit: limit, offset: offset, sorts: sorts, filters: _filters };

        fetch(config.fcsReportUrl + "/api/data/invoices", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var invoices = [];
                if (result && result.data) {
                    result.data.forEach(function (u) {
                        u["sn"] = ++offset;
                        invoices.push(u);
                    });
                }
                dispatch({
                    type: GET_MYINVOICES,
                    invoices
                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/invoices", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_MYINVOICES_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}


export const changeInvoiceOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_INVOICE_ORDER,
        order
    });
}

export const changeInvoicePage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_INVOICE_PAGE,
        page
    });
}


export const changeMyInvoiceOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_INVOICE_ORDER,
        order
    });
}

export const changeMyInvoicePage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_INVOICE_PAGE,
        page
    });
}
