import { GET_MESSAGES, GET_MONTHS, GET_RATES, NO_MORE_DATA,CHANGE_SMS_ROW_PER_PAGE } from "../actions/sms";

const initialState = {
    months: [],
    messages: [],
    rates: [],
    lastVisibleDoc: null,
    noMoreToLoad: false,
    rowsPerPage: 10
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MONTHS: {
            return {
                ...state,
                months: action.months,
            }
        }
        case GET_MESSAGES: {
            return {
                ...state,
                messages: action.payload.messages,
                lastVisibleDoc: action.payload.lastVisibleDoc
            }
        }
        case GET_RATES: {
            return {
                ...state,
                rates: action.rates,
            }
        }
        case NO_MORE_DATA: {
            return {
                ...state,
                noMoreToLoad: action.payload,
            }
        }
        case CHANGE_SMS_ROW_PER_PAGE: {
            return {
                ...state,
                rowsPerPage: action.rowsPerPage,
            };
        }
        default:
            return state;
    }
}