import { Box, Button, Typography, Grid, Paper, TextField, IconButton } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { completePickupRequest, getPickupDetail, uploadPickupPhotos } from '../../actions/pickup';
import theme from '../../theme';
import { convertISOToDate, removeFCSString } from '../../util';
import AddressCard from '../address_card';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    parent: {
        margin: '0 auto',
        position: 'relative'
    },

    button: {
        textAlign: 'center',
        position: 'absolute',
        borderRadious: ' 5px',
        right: '0px',
    }
}));

const CompletePickupForm = (props) => {
    const { title, history, dispatch, smallScreen } = props;
    const classes = useStyles();
    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const selector = useSelector(state => state.pickup);
    const [pickupDetail, setPickupDetail] = React.useState(selector.pickupDetail);
    const [photoUrls, setPhotoUrls] = React.useState([]);

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var pickupId = pathArr[2];

    const handleBack = () => {
        history.goBack();
    }

    useEffect(() => {
        dispatch(getPickupDetail(pickupId));
    }, [dispatch, pickupId]);

    useEffect(() => {
        setPickupDetail(selector.pickupDetail);
    }, [selector]);

    const handleCompletePickupRequest = () => {
        dispatch(uploadPickupPhotos(pickupDetail.pickup_number, photoUrls, (_photos) => {
            console.log("photo url:", _photos);
            var value = {
                "id": pickupDetail.id,
                "remark": pickupDetail.remark,
                "photo_urls": _photos
            };
            dispatch(completePickupRequest(value, () => {
                history.goBack();
            }))
        }))
    }

    const handleRemark = (e) => {
        setPickupDetail({ ...pickupDetail, 'remark': e.target.value });
    }

    const handleImageChange = (event) => {
        let file = null;

        var fileList = event.target.files;
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].type.match(/^image\//)) {
                file = fileList[i];
                break;
            }
        }

        var _photos = [...photoUrls];
        if (file !== null) {
            _photos.push(file);
            setPhotoUrls(_photos);
        }
    }

    const handleClickPhoto = (event) => {
        document.getElementById('photo-input').click();
    }

    const handleDeletePhoto = (e, file) => {
        e.preventDefault();
        var _photos = [...photoUrls];
        for (var i = 0; i < _photos.length; i++) {
            if (file.name === _photos[i].name) {
                _photos.splice(i, 1);
            }
        }
        setPhotoUrls(_photos);
    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                <Box><Button color="primary" onClick={handleBack} ><BackIcon /></Button></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? '10px' : '30px' }}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{pickupDetail.pickup_number !== undefined ? pickupDetail.pickup_number : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{pickupDetail.status !== undefined ? pickupDetail.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>

                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Zone'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.zone_name !== undefined ? pickupDetail.zone_name : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Date'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_date !== undefined ? convertISOToDate(pickupDetail.pickup_date) : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.customer_fcs_id !== undefined ? removeFCSString(pickupDetail.customer_fcs_id) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.customer_name !== undefined ? pickupDetail.customer_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Address'}</Typography>
                                {pickupDetail.pickup_address !== undefined ? <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                    <Paper
                                        style={{ marginRight: '5px', marginBottom: '5px', width: '280px', height: '165px', border: '1px solid lightgray' }}
                                    >
                                        <AddressCard address={pickupDetail.pickup_address}
                                            editIcon={false}
                                            deleteIcon={false}
                                            makeDefaultIcon={false}
                                        />
                                    </Paper>
                                </div> : <div />}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>

                                <Grid container spacing={0} style={{}}>
                                    <Box >
                                        <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Photos'}</Typography>
                                    </Box>
                                    <Box>
                                        <input
                                            style={{ padding: '10px', display: "none" }}
                                            type='file'
                                            accept="image/*"
                                            capture="environment"
                                            onChange={handleImageChange}
                                            id="photo-input"
                                        />
                                        <Button size="small" variant="contained" style={{ float: 'left', marginBottom: '10px', marginRight: '10px', textTransform: 'none' }}
                                            onClick={handleClickPhoto}
                                            startIcon={<AddIcon />}
                                        > Select Photo</Button>
                                    </Box>
                                </Grid>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>                                        {
                                    photoUrls.map((i, index) =>
                                        <Box key={index} style={{ marginRight: '5px', marginBottom: "5px" }}>
                                            <div className={classes.parent}>
                                                <div className={classes.button}>
                                                    <IconButton size="small" style={{ color: "lightgrey" }} onClick={(e) => handleDeletePhoto(e, i)}><CancelIcon /></IconButton>
                                                </div>
                                                <img id="output" alt={i.name}
                                                    style={{ padding: '10px', height: "100px", width: "100px", border: "1px solid lightgrey" }}
                                                    src={URL.createObjectURL(i)} />
                                            </div>
                                        </Box>
                                    )
                                }
                                </div>

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Remark'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={pickupDetail.remark !== undefined ? pickupDetail.remark : ""}
                                    multiline
                                    rows={3}
                                    style={{ width: '100%' }}
                                    onChange={(e) => handleRemark(e)}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Box>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleCompletePickupRequest}
                                        startIcon={<DoneAllIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Complete Pickup"} </Button>
                                </Box>
                            </Grid>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div >
    );
};

CompletePickupForm.propTypes = {
    pDetail: PropTypes.object,
    title: PropTypes.string
};

export default withRouter(connect()(CompletePickupForm));
