import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { lighten, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import theme from '../../theme';
import { Box, Typography } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import { getMyPackagesData, searchMyPackages, setMyPackageSearchTerm } from '../../actions/explore';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    btn: {
        color: theme.palette.primary.main,
    }
}));

const MyPackageSearchBar = (props) => {
    const classes = useToolbarStyles();
    const { dispatch } = props;
    const [searchText, setSearchText] = React.useState("");
    const isLogin = useSelector(state => state.app.isLogin);

    useEffect(() => {
        if (isLogin) dispatch(getMyPackagesData());
    }, [dispatch, isLogin]);

    const handleSearch = (e) => {
        var b64 = btoa(searchText);
        var esc = encodeURIComponent(b64);
        dispatch(setMyPackageSearchTerm(esc));
    }

    const handleOnChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const handleClear = (e) => {
        setSearchText("");
        dispatch(searchMyPackages(""));
    }

    return (
        <ThemeProvider theme={theme}>
            <Toolbar
                className={clsx(classes.root)}
            >
                <div style={{ height: '40px', alignItems: 'left', display: 'flex' }}>
                    <OutlinedInput style={{ height: '40px' }}
                        id="standard-adornment-password"
                        variant="outlined"
                        value={searchText}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility"
                                    onClick={handleSearch}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton aria-label="toggle password visibility"
                                    onClick={handleClear}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={handleOnChange}
                        onKeyDown={handleKeyDown}
                    ></OutlinedInput>
                </div>
                <Box textAlign="left" fontWeight="fontWeightBold" flexGrow={1}>
                    <Typography variant="h6" id="tableTitle" component="div" color="primary">
                    </Typography>
                </Box>

            </Toolbar>
        </ThemeProvider>
    );
};

export default connect()(MyPackageSearchBar);
