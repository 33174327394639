import { CHANGE_TEMPLATE_ORDER, CHANGE_TEMPLATE_PAGE, GET_OBJECTS, GET_OBJECT_META, GET_TEMPLATES, GET_TEMPLATE_DETAIL, GET_TEMPLATE_ROW_COUNT } from "../actions/template";

const initialState = {
    templates: [],
    templateDetail: {},
    count: 0,
    page: 0,
    order: { "field_name": "", "direction": "asc" },
    objects: [],
    objectMeta: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TEMPLATES: {
            return {
                ...state,
                templates: action.templates,
            }
        }
        case GET_TEMPLATE_ROW_COUNT: {
            return {
                ...state,
                count: action.count,
            }
        }
        case GET_TEMPLATE_DETAIL: {
            return {
                ...state,
                templateDetail: action.templateDetail
            }
        }
        case CHANGE_TEMPLATE_ORDER: {
            return {
                ...state,
                order: action.order,
            };
        }
        case CHANGE_TEMPLATE_PAGE: {
            return {
                ...state,
                page: action.page,
            };
        }
        case GET_OBJECTS: {
            return {
                ...state,
                objects: action.objects,
            };
        }
        case GET_OBJECT_META: {
            return {
                ...state,
                objectMeta: action.objectMeta,
            };
        }
        default:
            return state;
    }
}