import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import theme from '../../theme';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Paper, Typography, Box, Select, MenuItem, InputBase, IconButton } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import EnhancedTableHeader from '../enhanced_table_header';
import { convertMonthString, formatDateToLocal } from '../../util';
import { clearMessages, getSMSMonths, getNextPage, changeRowPerPage } from '../../actions/sms';
import { useLocation } from 'react-router';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },

}))(InputBase);

const useStyles = makeStyles({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px'
    },
    table: {
        minWidth: 650,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    },
    usage: {
        padding: "10px",
    },
    menuItem: {
        justifyContent: "center"
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const headCells = [
    { id: 'sn', numeric: true, label: 'No.', width: 50 },
    { id: 'date', numeric: false, label: 'Date' },
    { id: 'from', numeric: false, label: 'From' },
    { id: 'to', numeric: false, label: 'To' },
    { id: 'message', numeric: false, label: 'Messge' },
    { id: 'amount', numeric: true, label: 'Amount (USD)' },
    { id: 'status', numeric: false, label: 'Status' },
];


function SmsList(props) {
    const { dispatch, history } = props;
    const classes = useStyles();
    const messages = useSelector(state => state.sms.messages);
    const months = useSelector(state => state.sms.months);
    const isLogin = useSelector(state => state.app.isLogin);
    const noMoreToLoad = useSelector(state => state.sms.noMoreToLoad);
    const rowsPerPage = useSelector(state => state.sms.rowsPerPage);
    const [selectedMonth, setSelectedMonth] = React.useState();
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);

    const location = useLocation();


    useEffect(() => {
        if (isLogin) {
            dispatch(getSMSMonths());
        }
    }, [dispatch, isLogin])

    useEffect(() => {
        var now = new Date();
        var currentMonth = now.getFullYear() + "" + (now.getMonth() + 1);
        months.forEach((m) => {
            if (m.id === currentMonth) {
                dispatch(clearMessages());
                setSelectedMonth(m);
                setCount(m.count);
                setPage(0);
                dispatch(getNextPage(m.id));
            }
        });
    }, [location, months, dispatch]);

    const handleRequestSort = (event, property) => {
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (!noMoreToLoad && (newPage + 1) * rowsPerPage >= messages.length) {
            dispatch(getNextPage(selectedMonth.id));
        }
    };

    const handleChangeRowsPerPage = (event) => {
        var rowPerPage = parseInt(event.target.value, 10);
        dispatch(changeRowPerPage(rowPerPage));

        dispatch(clearMessages());
        setPage(0);
        dispatch(getNextPage(selectedMonth.id));
    };

    const handleChangeSelectMonth = (e, v) => {
        dispatch(clearMessages());
        var month = e.target.value;
        setSelectedMonth(e.target.value);
        setCount(month.count);
        setPage(0);
        dispatch(getNextPage(month.id));
    }

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className={classes.root}>
            <Box display="flex" >
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>

                    {/* <Typography className={classes.usage} style={{ color: theme.palette.primary.main, textAlign: 'center', paddingTop: '8px', fontSize: '16px' }}> Oct 2021</Typography> */}
                    <Select
                        style={{ width: '200px' }}
                        value={selectedMonth ? selectedMonth : '' || ''}
                        onChange={(e, v) => handleChangeSelectMonth(e, v)}
                        input={<BootstrapInput />}>
                        {months.map((m) => {
                            return <MenuItem className={classes.menuItem} key={m.id} value={m} name={m} >{convertMonthString(m.id)}</MenuItem>;
                        })}
                    </Select>
                </Box>
                <Box>
                    <Typography className={classes.usage} style={{ color: theme.palette.primary.main, textAlign: 'center', paddingTop: '8px', fontSize: '16px' }}> Usage:${selectedMonth ? selectedMonth.amount : 0}</Typography>
                </Box>
            </Box>
            <Paper>
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <EnhancedTableHeader
                            classes={classes}
                            order={"asc"}
                            orderBy={""}
                            onRequestSort={handleRequestSort}
                            rowCount={count}
                            headCells={headCells}
                        />
                        <TableBody>
                            {messages ? messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <StyledTableRow key={row.id}>
                                    {headCells.map((h, i) => {
                                        if (h.id === 'sn') {
                                            return (<TableCell key={h.id} style={{ width: h.width }} align="right">{(page * rowsPerPage) + (++index)}</TableCell>);
                                        }
                                        else if (h.id === 'date') {
                                            return (<TableCell style={{ width: h.width }} key={h.id} align={'left'} >{formatDateToLocal((row[h.id]).toDate())}</TableCell>);
                                        } else {
                                            return (<TableCell key={h.id} style={{ width: h.width }} align={h.numeric ? 'right' : 'left'}>{row[h.id]}</TableCell>);
                                        }
                                    })}
                                </StyledTableRow>
                            )) : <TableRow />}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[2, 4, 6, 10]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`}
                />
            </Paper>
        </div>
    );
}

SmsList.propTypes = {
    history: PropTypes.object,
};


export default connect()(SmsList);