import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getPackageDetail } from '../../actions/explore';
import theme from '../../theme';
import { formatDateToLocal } from '../../util';
import BackIcon from '@material-ui/icons/ArrowBack';
import ShowPhotoDialog from '../show_photo_dialog';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        paddingLeft: '10px'
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));


function getReceivedDate(status) {
    var convertDate = '';
    if (status) {
        status.forEach((value, index) => {
            if (value['status'] === 'received') {
                var date = new Date(value['date'].toDate());
                convertDate = formatDateToLocal(date);
            }
        });
    }
    return convertDate;
}

const ExplorePackageDetail = (props) => {
    const { dispatch, history } = props;
    const classes = useStyles();
    const statePackageDetail = useSelector(state => state.explore.packageDetail);
    const [packageDetail, setPackage] = React.useState(statePackageDetail);
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedPhoto, setSelectedPhoto] = React.useState(packageDetail['photo_urls'] ? packageDetail['photo_urls'][0] : "#");

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var packageId = pathArr[2];

    useEffect(() => {
        setPackage(statePackageDetail);
    }, [statePackageDetail]);

    useEffect(() => {
        dispatch(getPackageDetail(packageId));
    }, [packageId, dispatch]);

    useEffect(() => {
        if (packageId !== packageDetail.id) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [packageId, packageDetail]);

    const handleBack = () => {
        history.goBack();
    }


    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{"Package Detail"}</Typography></Box>
            </Box>
            <Grid item xs={12} sm={6} style={{ paddingLeft: '10px' }}>
                <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Tracking number</Typography>
                    <Typography style={{ marginLeft: 0 }}> {isLoading ? 'Loading...' : packageDetail['tracking_id'] ? packageDetail['tracking_id'] : "Not found"}</Typography>
                </div>
                <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Received date</Typography>
                    <Typography style={packageDetail['remark'] === 'Not found' ? { color: "red", marginLeft: 0 } : { marginLeft: 0 }}> {getReceivedDate(packageDetail['all_status'])}</Typography>
                </div>
                <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Remark</Typography>
                    <Typography
                        style={packageDetail['remark'] === 'Not found' ? { color: "red", marginLeft: 0 } : { marginLeft: 0 }}>
                        {packageDetail['remark']}
                    </Typography>
                </div>
                <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Status</Typography>
                    <Typography
                        style={packageDetail['remark'] === 'Not found' ? { color: "red", marginLeft: 0 } : { marginLeft: 0 }}>
                        {packageDetail['status']}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingLeft: '10px' }}>
                <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Photos</Typography>

                    <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>

                        {
                            packageDetail['photo_urls'] ? packageDetail['photo_urls'].map((i, index) =>
                                <Box key={index} style={{ marginRight: '5px', marginBottom: "5px" }}>
                                    <img id="output" alt={i.name}
                                        style={{ padding: '10px', height: "150px", width: "150px", border: "1px solid lightgrey" }}
                                        src={i}
                                        onClick={(e) => {
                                            setSelectedPhoto(i);
                                            setOpen(true);
                                        }
                                        } /></Box>
                            )
                                : <span />}
                    </div>
                </div>
            </Grid>
            <ShowPhotoDialog
                open={open}
                selectedPhoto={selectedPhoto}
                photoUrls={packageDetail.photo_urls}
                onClose={(v) => { setOpen(v) }}
                onChangeSelectedPhoto={(v) => { setSelectedPhoto(v) }}
            ></ShowPhotoDialog>
        </div>

    );
};

ExplorePackageDetail.propTypes = {
    pDetail: PropTypes.object
};

export default withRouter(connect()(ExplorePackageDetail));
